import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TrackingService } from '../../../../shared/services/tracking.service';
import { PlaylistsStereoSpacesService } from 'sostereo-services';
import { ToastrService } from 'ngx-toastr';
import { DownloadService } from '../../../../shared/services/download.service';
import { CommonService } from '../../../../shared/services/common.service';
import { finalize } from 'rxjs/operators';
import { clone } from 'lodash-es';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/browser';

@Component({
  selector: 'app-download-playlist-confirmation-modal',
  templateUrl: './download-playlist-confirmation-modal.component.html',
  styleUrls: ['./download-playlist-confirmation-modal.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.5s ease-out', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class DownloadPlaylistConfirmationModalComponent implements OnInit {
  @ViewChild('downloadPlaylistConfirmationModal', { static: true }) modalTemplate: TemplateRef<any>;
  @Output() processFinished = new EventEmitter();
  private modalRef: BsModalRef;
  private isDownloading = false;
  public playlistData;
  public isPitch = this.router.url.includes('pitch/');

  constructor(
    private modalService: BsModalService,
    private trackingService: TrackingService,
    private playlistsService: PlaylistsStereoSpacesService,
    private toastr: ToastrService,
    private downloadService: DownloadService,
    private commonService: CommonService,
    private router: Router,
  ) {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {}

  public showModal(data) {
    this.playlistData = data;
    this.modalRef = this.modalService.show(this.modalTemplate, {
      class: 'modal-sm',
      ignoreBackdropClick: true,
      keyboard: false,
    });
    this.trackingService.track(
      'Download Playlist Confirmation Modal Open',
      {
        action: 'Modal Open',
        kind: 'Download Playlist Confirmation Modal',
      },
      {
        event_action: 'Download playlist confirmation modal opened',
        event_type: 'Modal Opened',
      },
    );
  }

  private downloadPlaylist() {
    const trackingData = clone(this.playlistData);
    trackingData.targetId = trackingData.playlistId;
    this.trackingService.track('Playlist zip start downloading', trackingData, {
      event_action: 'Playlist zip download started',
      event_type: 'Playlist Download',
      element_type: 'Playlist',
      element_value: trackingData?.playlistName,
      element_id: trackingData?.playlistId,
    });
    this.isDownloading = true;
    this.toastr.info('Downloading your playlist', '', {
      tapToDismiss: true,
      disableTimeOut: true,
      positionClass: 'download-playlist-toastr',
    });
    this.hideModal();
    this.downloadService.addLeaveWarning();
    this.playlistsService
      .downloadZip(this.playlistData.playlistId)
      .pipe(
        finalize(() => {
          this.downloadService.killLeaveWarning();
          this.trackingService.trackDownloadView();
          this.isDownloading = false;
          this.processFinished.emit();
        }),
      )
      .subscribe(
        (res) => {
          this.toastr.clear();
          this.trackingService.track(
            'Playlist zip finnished downloading',
            {
              playlistId: this.playlistData?.playlistId,
              playlistName: this.playlistData?.playlistName,
              targetId: trackingData?.targetId,
            },
            {
              event_action: 'Playlist zip download finished',
              event_type: 'Playlist Download',
              element_type: 'Playlist',
              element_value: trackingData?.playlistName,
              element_id: trackingData?.playlistId,
            },
          );
          this.downloadService.saveFile(
            res,
            `${
              this.commonService.tenantId.charAt(0).toUpperCase() +
              this.commonService.tenantId.slice(1)
            } - ${this.playlistData.playlistName}.zip`,
          );
        },
        (err) => {
          Sentry.captureException(
            new Error(
              `There was an error downloading the zip file. API error: status ${err.status} - ${
                err?.error?.message || err.message
              }`,
            ),
          );
          this.toastr.error(
            err?.error?.message,
            (err.status === 403 ? 'Unauthorized - ' : '') +
              'There was an error downloading the zip file',
          );
          this.trackingService.track('Error with Playlist download', {
            playlistId: this.playlistData?.playlistId,
            playlistName: this.playlistData?.playlistName,
            targetId: trackingData?.targetId,
            errors: err,
          });
          setTimeout(() => {
            this.toastr.clear();
          }, 2000);
        },
      );
  }

  public hideModal() {
    if (!this.isDownloading) {
      this.processFinished.emit();
    }
    this.modalRef.hide();
    this.trackingService.track(
      'Download Playlist Confirmation Modal Hide',
      {
        action: 'Modal Hide',
        kind: 'Download Playlist Confirmation Modal',
      },
      {
        event_action: 'Download playlist confirmation modal hidden',
        event_type: 'Modal Hidden',
      },
    );
  }

  public cancel() {
    if (!this.isDownloading) {
      this.processFinished.emit();
    }
    this.modalRef.hide();
    this.trackingService.track(
      'Download Playlist Confirmation Modal Cancel',
      {
        action: 'Modal Cancel',
        kind: 'Download Playlist Confirmation Modal',
      },
      {
        event_action: 'Download playlist confirmation modal closed',
        event_type: 'Modal Closed',
      },
    );
  }
}
