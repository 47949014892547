import { Component, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UserService } from 'sostereo-services';
import { TrackingService } from '../../../../shared/services/tracking.service';
import { transition, trigger, useAnimation } from '@angular/animations';
import { inOutAnimation } from '../../../../shared/animations/in-out.animation';
import { finalize } from 'rxjs/operators';
import { AlertComponent } from 'ngx-bootstrap/alert';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-reset-password-modal',
  templateUrl: './reset-password-modal.component.html',
  animations: [trigger('inOutAnimation', [transition(':enter', useAnimation(inOutAnimation))])],
})
export class ResetPasswordModalComponent implements OnDestroy {
  @ViewChild('resetPasswordModal', { static: true }) modalTemplate: TemplateRef<any>;
  public modalRef: MatDialogRef<any, any>;
  @Input() public token;

  public passwordMatch = false;
  public loadingRequest: boolean;
  public requestSuccess = false;
  public alerts: any[] = [];
  public resetPasswordForm: FormGroup;
  private onHide$: Subscription;

  constructor(
    private modalService: MatDialog,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private trackingService: TrackingService,
    private router: Router,
  ) {
    this.createForm();
  }

  ngOnDestroy(): void {
    this.onHide$?.unsubscribe();
  }

  private createForm() {
    this.resetPasswordForm = this.formBuilder.group({
      newPassword: new FormControl(''),
      repeatPassword: new FormControl('', { updateOn: 'change' }),
    });
  }

  public showModal() {
    this.modalRef = this.modalService.open(this.modalTemplate, {
      panelClass: ['modal', 'material'],
      position: { top: '10vh' },
      disableClose: true,
      maxWidth: 'min(90vw, 600px)',
      role: 'dialog',
      closeOnNavigation: true,
      ariaModal: true,
    });

    this.trackingService.track('Reset Password Modal Open', {
      action: 'Modal Open',
      kind: 'Reset Password modal',
    });
  }

  public validatePasswords() {
    const newPass = this.resetPasswordForm.get('newPassword').value;
    const repPass = this.resetPasswordForm.get('repeatPassword').value;
    this.passwordMatch = newPass === repPass;
  }

  public hideModal() {
    this.resetPasswordForm.reset();
    this.modalRef.close('closed');
    this.trackingService.track('Reset Password Modal Closed', {
      action: 'Modal Closed',
      kind: 'Reset Password modal',
    });
    this.router.navigateByUrl('/discover');
  }

  private addAlert(alert: any): void {
    this.alerts.push({
      type: alert.type,
      message: alert.message,
      timeout: 5000,
    });
  }

  private onAlertClosed(dismissedAlert: AlertComponent): void {
    this.alerts = this.alerts.filter((alert) => alert !== dismissedAlert);
  }

  public resetPassword() {
    this.loadingRequest = true;
    this.userService
      .resetPassword(this.token, this.resetPasswordForm.get('newPassword').value)
      .pipe(finalize(() => (this.loadingRequest = false)))
      .subscribe(
        (res) => {
          this.requestSuccess = true;
          this.trackingService.track(
            'Reset Password Success',
            {
              userEmail: res?.data?.email,
            },
            {
              event_action: 'Reset password success',
              event_type: 'Authentication',
              event_user_email: res?.data?.email,
            },
          );
        },
        (err) => {
          this.addAlert({ type: 'danger', message: 'There was an error changing your password.' });
          this.trackingService.track('Reset Password Error', {
            err: err,
          });
        },
      );
  }
}
