import { LabelType } from 'ngx-slider-v2';
interface IRange {
  value: Date[];
  label: string;
}

const ranges: IRange[] = [
  {
    value: [new Date(new Date().setFullYear(new Date().getFullYear() - 2)), new Date()],
    label: 'Last 2 years',
  },
  {
    value: [new Date(new Date().setFullYear(new Date().getFullYear() - 1)), new Date()],
    label: 'Last year',
  },
  {
    value: [new Date(new Date().setMonth(new Date().getMonth() - 6)), new Date()],
    label: 'Last 6 months',
  },
  {
    value: [new Date(new Date().setMonth(new Date().getMonth() - 3)), new Date()],
    label: 'Last 3 months',
  },
  {
    value: [new Date(new Date().setMonth(new Date().getMonth() - 1)), new Date()],
    label: 'Last month',
  },
];
const getModifierOptions = () => {
  return [
    { value: 'none' },
    { value: 'log1p' },
    { value: 'log2p' },
    { value: 'ln1p' },
    { value: 'ln2p' },
    { value: 'square' },
    { value: 'sqrt' },
    { value: 'reciprocal' },
  ];
};

export function searchBarSection() {
  return {
    defaultTagsQueryLimit: 50,
    tabs: [
      {
        name: 'Genres',
        slug: 'genres',
        active: true,
        tagIndexLimit: 40,
        countUpdated: false,
        tags: [],
      },
      {
        name: 'Moods',
        slug: 'moods',
        active: false,
        tagIndexLimit: 40,
        countUpdated: false,
        tags: [],
      },
      {
        name: 'Vocal & themes',
        slug: 'vocal',
        active: false,
        tagIndexLimit: 30,
        preTags: true,
        countUpdated: false,
        tags: [],
      },
      {
        name: 'Rhythm',
        slug: 'rhythm',
        active: false,
        tagIndexLimit: 30,
        preTags: true,
        countUpdated: false,
        tags: [],
      },
      {
        name: 'Harmony',
        slug: 'harmony',
        active: false,
        tagIndexLimit: 30,
        preTags: true,
        countUpdated: false,
        tags: [],
      },
      {
        name: 'Instrumentation',
        slug: 'instrumentation',
        active: false,
        tagIndexLimit: 30,
        countUpdated: false,
        tags: [],
      },
      {
        name: 'References',
        slug: 'references',
        active: false,
        description: {
          title: 'SoStereo AI reference search',
          text: 'You can use these inputs together with any other filters tags:',
        },
        preTags: true,
        tags: [
          { slug: 'refYoutube', label: 'YouTube' },
          { slug: 'refSpotify', label: 'Spotify' },
          { slug: 'refUpload', label: 'Upload Mp3' },
        ],
      },
      { name: 'Budget', slug: 'budget', active: false, tooltip: '' },
      { name: 'Terms', slug: 'agreement', active: false },
      { name: 'Advanced', slug: 'advanced-search', active: false },
    ],
    settingsList: [
      {
        category: 'advancedSettings',
        items: [
          {
            value: null,
            model: null,
            label: 'Artist location',
            slug: 'artistLocation',
            type: 'options',
            filter: 'artistLocation',
            collapse: true,
            description: 'Where the artist is based or located',
            resultArray: [],
            options: [
              {
                label: 'Location',
                slug: 'country',
                allowed: ['*'],
                country: null,
                state: null,
                city: null,
                showCityInput: true,
              },
              {
                label: 'Source',
                slug: 'source',
                allowed: ['*'],
                types: [
                  { label: 'User', slug: 'user', value: null },
                  { label: 'Crawler', slug: 'crawler', value: null },
                ],
              },
              {
                label: 'Verified',
                slug: 'verified',
                allowed: ['*'],
                types: [
                  { label: 'Verified', slug: true, value: null },
                  { label: 'Not verified', slug: false, value: null },
                ],
              },
            ],
          },
          {
            value: null,
            model: null,
            label: 'Recording location',
            slug: 'recordingLocation',
            type: 'options',
            filter: 'recordingLocation',
            collapse: true,
            description: 'The location where the artist recorded the song',
            resultArray: [{ key: 'fields', value: 'recordingLocation' }],
            options: [
              {
                label: 'Location',
                slug: 'country',
                country: null,
                state: null,
                city: null,
              },
              {
                label: 'Source',
                slug: 'source',
                allowed: ['*'],
                types: [
                  { label: 'Artist location', slug: 'artist-location', value: null },
                  { label: 'User', slug: 'user', value: null },
                  { label: 'Crawler', slug: 'crawler', value: null },
                ],
              },
              {
                label: 'Verified',
                slug: 'verified',
                allowed: ['*'],
                types: [
                  { label: 'Verified', slug: true, value: null },
                  { label: 'Not verified', slug: false, value: null },
                ],
              },
            ],
          },
          {
            value: null,
            model: null,
            label: 'Release date',
            slug: 'releaseDateBefore',
            rangeArray: ranges,
            type: 'date',
            filter: 'releaseDate',
            placeholder: 'Select release date',
            description: 'The date on which the artist released the song publicly',
          },
          {
            value: null,
            model: null,
            resultArray: [],
            label: 'Release Status',
            allowed: ['*'],
            slug: 'releaseStatus',
            type: 'multiselect',
            filter: 'releaseStatus',
            dropdown: 'releaseStatusDropdown',
            placeholder: 'Select a release status',
            description: 'Song release status',
          },
          {
            value: null,
            model: null,
            label: 'Creation date',
            slug: 'createdBefore',
            allowed: ['*'],
            rangeArray: ranges,
            type: 'date',
            filter: 'created',
            placeholder: 'Select creation date',
            description: 'The date the song was uploaded to SoStereo',
          },
          {
            value: null,
            model: null,
            label: 'Publication date',
            slug: 'publishedBefore',
            allowed: ['*'],
            rangeArray: ranges,
            type: 'date',
            filter: 'published',
            placeholder: 'Select publication date',
            description: 'The date the song went live on SoStereo',
          },
          {
            value: null,
            model: null,
            resultArray: [],
            allowed: ['*'],
            label: 'Contract owner email',
            slug: 'labels',
            type: 'multiselect',
            filter: 'labels',
            dropdown: 'contractOwnersDropdown',
            placeholder: 'Select a contract owner',
            description: 'The copyright owner who signed up to SoStereo',
          },
          {
            value: null,
            model: null,
            resultArray: [],
            allowed: ['*'],
            label: 'Labels',
            slug: 'contract.name',
            type: 'text',
            placeholder: 'Type a label name',
            description: 'The copyright owner who signed up to SoStereo',
          },
        ],
      },
      {
        category: 'Curators',
        items: [
          {
            slug: 'curatorsModifier',
            id: 'basicModifier',
            label: 'Curators Modifier',
            value: 'log1p',
            defaultValue: 'log1p',
            format: '',
            allowedScopes: ['ListTiers'],
            sysAdmin: false,
            options: [
              { value: 'log1p', label: 'More added to playlists ' },
              { value: 'reciprocal', label: 'Less added to playlists ' },
            ],
            type: 'select',
          },
          {
            slug: 'curatorsModifier',
            id: 'advancedModifier',
            label: 'Curators Modifier',
            value: 'log1p',
            defaultValue: 'log1p',
            format: '',
            sysAdmin: true,
            options: getModifierOptions(),
            type: 'select',
          },
          {
            slug: 'curatorsFactor',
            label: 'Curators Factor',
            value: 1,
            defaultValue: 1,
            format: '',
            sysAdmin: true,
            options: {
              id: 'curatorsFactor',
              floor: 0,
              ceil: 5,
              animate: false,
              step: 0.1,
              precision: 1,
            },
          },
          {
            slug: 'curatorsWeight',
            label: 'Order results by the most listened to music first',
            value: 1.8,
            defaultValue: 1.8,
            format: '',
            options: {
              id: 'curatorsWeight',
              floor: 0,
              ceil: 5,
              animate: false,
              step: 0.1,
              precision: 1,
              translate: (value: number, label: LabelType): string => {
                if (label === LabelType.Floor) {
                  return 'Less';
                }
                if (label === LabelType.Ceil) {
                  return 'More';
                }
                return `${value}`;
              },
            },
          },
        ],
      },
      {
        category: 'Recent Content',
        items: [
          {
            slug: 'recentWeight',
            label: 'Order results by most recently added songs',
            category: 'Recent Content',
            value: 2,
            defaultValue: 2,
            format: '',
            options: {
              id: 'recentWeight',
              floor: 0,
              ceil: 5,
              animate: false,
              enforceStep: false,
              step: 0.1,
              precision: 1,
              translate: (value: number, label: LabelType): string => {
                if (label === LabelType.Floor) {
                  return 'All Time';
                }
                if (label === LabelType.Ceil) {
                  return 'Just Added';
                }
                return `${value}`;
              },
            },
          },
          {
            slug: 'recentScale',
            label: 'Recent Scale',
            category: 'Recent Content',
            value: 200,
            defaultValue: 200,
            format: 'd',
            sysAdmin: true,
            options: {
              id: 'recentScale',
              floor: 0,
              ceil: 500,
              animate: false,
            },
          },
          {
            slug: 'offset',
            label: 'Offset',
            category: 'Recent Content',
            value: 15,
            defaultValue: 15,
            format: 'd',
            sysAdmin: true,
            options: {
              id: 'offset',
              floor: 0,
              ceil: 500,
              animate: false,
            },
          },
          {
            slug: 'decay',
            label: 'Decay ',
            category: 'Recent Content',
            value: 0.5,
            defaultValue: 0.5,
            format: '',
            sysAdmin: true,
            options: {
              id: 'decay',
              floor: 0,
              ceil: 1,
              animate: false,
              enforceStep: false,
              step: 0.1,
              precision: 1,
            },
          },
        ],
      },
      {
        category: 'Random',
        items: [
          {
            slug: 'randomWeight',
            label: 'Select level of randomization in results',
            category: 'Random',
            value: 1.2,
            defaultValue: 1.2,
            format: '',
            options: {
              id: 'randomWeight',
              floor: 0,
              ceil: 5,
              enforceStep: false,
              step: 0.1,
              precision: 1,
              animate: false,
              translate: (value: number, label: LabelType): string => {
                if (label === LabelType.Floor) {
                  return 'Less';
                }
                if (label === LabelType.Ceil) {
                  return 'More';
                }
                return `${value}`;
              },
            },
          },
          {
            slug: 'maxBoost',
            label: 'Max Boost',
            category: 'Random',
            value: 10,
            defaultValue: 10,
            format: '',
            sysAdmin: true,
            options: {
              id: 'maxBoost',
              floor: 0,
              enforceStep: false,
              ceil: 50,
              animate: false,
            },
          },
          {
            slug: 'minMatch',
            label: 'Minimum Tag Match Percentage',
            category: 'Random',
            value: 0,
            avoidProfile: true,
            defaultValue: 0,
            userInput: false,
            format: '%',
            options: {
              id: 'minMatch',
              floor: 0,
              enforceStep: false,
              ceil: 100,
              animate: false,
              translate: (value: number, label: LabelType): string => {
                return (label === LabelType.Low && value === 0) || label === LabelType.Floor
                  ? 'Auto'
                  : `${value}`;
              },
            },
          },
        ],
      },
      {
        category: 'Quality of Performance',
        items: [
          {
            slug: 'performanceModifier',
            label: 'Performance Modifier',
            value: 'log1p',
            defaultValue: 'log1p',
            format: '',
            sysAdmin: true,
            options: getModifierOptions(),
            type: 'select',
          },
          {
            slug: 'performanceFactor',
            label: 'Performance Factor',
            value: 1,
            defaultValue: 1,
            format: '',
            sysAdmin: true,
            options: {
              id: 'performanceFactor',
              floor: 0,
              ceil: 5,
              animate: false,
              step: 0.1,
              precision: 1,
            },
          },
          {
            slug: 'performanceMissing',
            label: 'Performance Missing',
            value: 0,
            defaultValue: 0,
            format: '',
            sysAdmin: true,
            options: {
              id: 'performanceMissing',
              floor: 0,
              ceil: 5,
              animate: false,
              step: 0.1,
              precision: 1,
            },
          },
          {
            slug: 'performanceWeight',
            label: 'Performance Weight',
            value: 0,
            defaultValue: 0,
            format: '',
            allowedScopes: ['ListTiers'],
            options: {
              id: 'performanceWeight',
              floor: 0,
              ceil: 5,
              animate: false,
              step: 0.1,
              precision: 1,
              translate: (value: number, label: LabelType): string => {
                if (label === LabelType.Floor) {
                  return 'Less';
                }
                if (label === LabelType.Ceil) {
                  return 'More';
                }
                return `${value}`;
              },
            },
          },
        ],
      },
      {
        category: 'Quality of Recording',
        items: [
          {
            slug: 'recordingModifier',
            label: 'Recording Modifier',
            value: 'log1p',
            defaultValue: 'log1p',
            format: '',
            sysAdmin: true,
            options: getModifierOptions(),
            type: 'select',
          },
          {
            slug: 'recordingFactor',
            label: 'Recording Factor',
            value: 1,
            defaultValue: 1,
            format: '',
            sysAdmin: true,
            options: {
              id: 'recordingFactor',
              floor: 0,
              ceil: 5,
              animate: false,
              step: 0.1,
              precision: 1,
            },
          },
          {
            slug: 'recordingMissing',
            label: 'Recording Missing',
            value: 0,
            defaultValue: 0,
            format: '',
            sysAdmin: true,
            options: {
              id: 'recordingMissing',
              floor: 0,
              ceil: 5,
              animate: false,
              step: 0.1,
              precision: 1,
            },
          },
          {
            slug: 'recordingWeight',
            label: 'Recording Weight',
            value: 0.5,
            defaultValue: 0.5,
            format: '',
            allowedScopes: ['ListTiers'],
            options: {
              id: 'recordingWeight',
              floor: 0,
              ceil: 5,
              animate: false,
              step: 0.1,
              precision: 1,
              translate: (value: number, label: LabelType): string => {
                if (label === LabelType.Floor) {
                  return 'Less';
                }
                if (label === LabelType.Ceil) {
                  return 'More';
                }
                return `${value}`;
              },
            },
          },
        ],
      },
      {
        category: 'Quality of Songwriting/Composition',
        items: [
          {
            slug: 'qualityModifier',
            label: 'Quality Modifier',
            value: 'log1p',
            defaultValue: 'log1p',
            format: '',
            sysAdmin: true,
            options: getModifierOptions(),
            type: 'select',
          },
          {
            slug: 'qualityFactor',
            label: 'Quality Factor',
            value: 1,
            defaultValue: 1,
            format: '',
            sysAdmin: true,
            options: {
              id: 'qualityFactor',
              floor: 0,
              ceil: 5,
              animate: false,
              step: 0.1,
              precision: 1,
            },
          },
          {
            slug: 'qualityMissing',
            label: 'Quality Missing',
            value: 0,
            defaultValue: 0,
            format: '',
            sysAdmin: true,
            options: {
              id: 'qualityMissing',
              floor: 0,
              ceil: 5,
              animate: false,
              step: 0.1,
              precision: 1,
            },
          },
          {
            slug: 'qualityWeight',
            label: 'Quality Weight',
            value: 0.5,
            defaultValue: 0.5,
            format: '',
            allowedScopes: ['ListTiers'],
            options: {
              id: 'qualityWeight',
              floor: 0,
              ceil: 5,
              animate: false,
              step: 0.1,
              precision: 1,
              translate: (value: number, label: LabelType): string => {
                if (label === LabelType.Floor) {
                  return 'Less';
                }
                if (label === LabelType.Ceil) {
                  return 'More';
                }
                return `${value}`;
              },
            },
          },
        ],
      },
      {
        category: 'Popularity',
        items: [
          {
            slug: 'popularityModifier',
            label: 'Popularity Modifier',
            value: 'log1p',
            defaultValue: 'log1p',
            format: '',
            sysAdmin: true,
            options: getModifierOptions(),
            type: 'select',
          },
          {
            slug: 'popularityFactor',
            label: 'Popularity Factor',
            value: 1,
            defaultValue: 1,
            format: '',
            sysAdmin: true,
            options: {
              id: 'popularityFactor',
              floor: 0,
              ceil: 5,
              animate: false,
              step: 0.1,
              precision: 1,
            },
          },
          {
            slug: 'popularityMissing',
            label: 'Popularity Missing',
            value: 0,
            defaultValue: 0,
            format: '',
            sysAdmin: true,
            options: {
              id: 'performanceMissing',
              floor: 0,
              ceil: 5,
              animate: false,
              step: 0.1,
              precision: 1,
            },
          },
          {
            slug: 'popularityWeight',
            label: 'Popularity Weight',
            value: 0.5,
            defaultValue: 0.5,
            format: '',
            options: {
              id: 'popularityWeight',
              floor: 0,
              ceil: 5,
              animate: false,
              step: 0.1,
              precision: 1,
              translate: (value: number, label: LabelType): string => {
                if (label === LabelType.Floor) {
                  return 'Less';
                }
                if (label === LabelType.Ceil) {
                  return 'More';
                }
                return `${value}`;
              },
            },
          },
        ],
      },
    ],
    budgetSettings: {
      dropDownOptions: [
        {
          id: 'ads',
          label: 'Ads',
          model: [],
          dropdownSettings: {
            singleSelection: true,
            text: 'Commercials & Ads',
            enableSearchFilter: false,
            disabled: false,
          },
          options: [
            {
              itemName: 'Social Media',
              id: 'ads.socialMedia',
            },
            {
              itemName: 'Radio',
              id: 'ads.radio',
            },
            {
              itemName: 'Online Videos',
              id: 'ads.onlineVideos',
            },
            {
              itemName: 'TV Broadcast',
              id: 'ads.tvBroadcast',
            },
            {
              itemName: 'All Media',
              id: 'ads.allMedia',
            },
          ],
        },
        {
          id: 'tv',
          label: 'TV',
          model: [],
          dropdownSettings: {
            singleSelection: true,
            text: 'Tv Shows',
            enableSearchFilter: false,
            disabled: false,
          },
          options: [
            {
              itemName: 'Scene Or Intro',
              id: 'tv.scene',
            },
            {
              itemName: 'Promo/Trailer',
              id: 'tv.trailer',
            },
            {
              itemName: 'Credits',
              id: 'tv.credits',
            },
            {
              itemName: 'Theme',
              id: 'tv.theme',
            },
          ],
        },
        {
          id: 'film',
          label: 'Film',
          model: [],
          dropdownSettings: {
            singleSelection: true,
            text: 'Film',
            enableSearchFilter: false,
            disabled: false,
          },
          options: [
            {
              itemName: 'Scene',
              id: 'film.scene',
            },
            {
              itemName: 'Promo/Trailer',
              id: 'film.trailer',
            },
            {
              itemName: 'Credits',
              id: 'film.credits',
            },
          ],
        },
        {
          id: 'internal',
          label: 'Internal',
          model: [],
          dropdownSettings: {
            singleSelection: true,
            text: 'Internal/Industrial',
            enableSearchFilter: false,
            disabled: false,
          },
          options: [
            {
              itemName: 'Internal/Industrial',
              id: 'internal',
            },
          ],
        },
      ],
      amount: 15000,
    },
    advancedSearchSettings: {
      minBpm: {
        slug: 'min.bpm',
        value: 0,
        defaultValue: 0,
      },
      maxBpm: {
        slug: 'max.bpm',
        value: 180,
        defaultValue: 180,
      },
      statsOptions: [
        {
          itemName: 'Followers',
          id: 'totalFollowers',
          minQueryProp: 'min.followers',
          maxQueryProp: 'max.followers',
        },
        {
          itemName: 'Monthly Listeners',
          id: 'totalMonthlyListeners',
          minQueryProp: 'min.monthlyListeners',
          maxQueryProp: 'max.monthlyListeners',
        },
        {
          itemName: 'Instagram Followers',
          id: 'instagramFollowers',
          minQueryProp: 'minInstagram.followers',
          maxQueryProp: 'maxInstagram.followers',
        },
        {
          itemName: 'Spotify Followers',
          id: 'spotifyFollowers',
          minQueryProp: 'minSpotify.followers',
          maxQueryProp: 'maxSpotify.followers',
        },
        {
          itemName: 'Spotify Monthly Listeners',
          id: 'spotifyMonthlyListeners',
          minQueryProp: 'minSpotify.monthlyListeners',
          maxQueryProp: 'maxSpotify.monthlyListeners',
        },
        {
          itemName: 'Deezer Followers',
          id: 'deezerFollowers',
          minQueryProp: 'minDeezer.followers',
          maxQueryProp: 'maxDeezer.followers',
        },
        {
          itemName: 'Deezer Monthly Listeners',
          id: 'deezerMonthlyListeners',
          minQueryProp: 'minDeezer.monthlyListeners',
          maxQueryProp: 'maxDeezer.monthlyListeners',
        },
        {
          itemName: 'Apple Music Followers',
          id: 'appleMusicFollowers',
          minQueryProp: 'minAppleMusic.followers',
          maxQueryProp: 'maxAppleMusic.followers',
        },
        {
          itemName: 'Apple Music Monthly Listeners',
          id: 'appleMusicMonthlyListeners',
          minQueryProp: 'minAppleMusic.monthlyListeners',
          maxQueryProp: 'maxAppleMusic.monthlyListeners',
        },
      ],
      statsSource: [
        {
          itemName: 'Monthly Listeners',
          id: 'totalMonthlyListeners',
          minQueryProp: 'min.monthlyListeners',
          maxQueryProp: 'max.monthlyListeners',
        },
      ],
      minStats: {
        value: 20000,
        defaultValue: 20000,
      },
      maxStats: {
        value: 10000000,
        defaultValue: 10000000,
      },
    },
  } as any;
}
