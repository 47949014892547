<ng-template #accountRequestModal>
  <div class="modal-body overflow-auto" *ngIf="!requestSuccess" [@inOutAnimation]
       style="border-bottom: solid 1px rgb(219, 219, 219);">
    <div class="row">
      <div class="col-md-12">
        <button type="button" class="close" (click)="hideModal()"><span class="sr-only">Close</span><span
            class="ss-icon-close"></span></button>
            <ng-container *ngIf="!showMusicForm">
              <h3 class="modal-title mb15" id="myModalLabel">Thank you for your interest in SoStereo.</h3>
              <p>Right now we're not able to accept any new music submissions until 2025.</p>
              <p>We thank you for your interest in us.</p>
              <p class="mt10">Best regards from the Music Team</p>
            </ng-container>
        <ng-container *ngIf="showMusicForm">
          <h4 class="modal-title" id="myModalLabel">Submit your Music</h4>
          <p class="mt10 mb5" style="font-style: italic;font-size: 12px;">
            (Only if you don't already have an account manager, if you do, please submit directly to them)
          </p>
          <p>Please fill in the form below and we'll get back to you once we review internally.</p>
        </ng-container>
      </div>
    </div>
    <form [formGroup]="accountRequestForm" class="mt0" *ngIf="showMusicForm">
      <div class="row text-left mt30">
        <div class="col-md-6">
          <div class="form-group">
            <label for="inputFirstName">First Name: *</label>
            <input data-hj-allow type="text" class="form-control" id="inputFirstName" formControlName="firstName">
            <app-inline-errors
                *ngIf="accountRequestForm.get('firstName').errors && accountRequestForm.get('firstName').touched"
                [errors]="['This field is required.']"></app-inline-errors>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="inputLastName">Last Name: *</label>
            <input data-hj-allow type="text" class="form-control" id="inputLastName" formControlName="lastName">
            <app-inline-errors
                *ngIf="accountRequestForm.get('lastName').errors && accountRequestForm.get('lastName').touched"
                [errors]="['This field is required.']"></app-inline-errors>
          </div>
        </div>
      </div>
      <div class="row text-left ">
        <div class="col-md-12">
          <div class="form-group">
            <label for="bandOrArtist">Artist/Label Name: *</label>
            <input data-hj-allow type="text" class="form-control" id="bandOrArtist" formControlName="artistName">
            <app-inline-errors
                *ngIf="accountRequestForm.get('artistName').errors && accountRequestForm.get('artistName').touched"
                [errors]="['This field is required.']"></app-inline-errors>
          </div>
          <div class="form-group">
            <label for="inputEmail1">Email: *</label>
            <input data-hj-allow type="email" class="form-control" id="inputEmail1" formControlName="email">
            <app-inline-errors *ngIf="accountRequestForm.get('email').errors && accountRequestForm.get('email').touched"
                               [errors]="['this field is required, must be a valid email']"></app-inline-errors>
          </div>
          <div class="form-group">
            <label for="inputMusicGenre">Music Genre/Style: *</label>
            <input data-hj-allow type="text" class="form-control" id="inputMusicGenre" formControlName="musicGenre">
            <app-inline-errors
                *ngIf="accountRequestForm.get('musicGenre').errors && accountRequestForm.get('musicGenre').touched"
                [errors]="['This field is required.']"></app-inline-errors>
          </div>
          <div class="form-group">
            <label for="inputMusicLink">Music link (Spotify, SoundCloud, etc.): *</label>
            <input data-hj-allow type="text" class="form-control" id="inputMusicLink" formControlName="musicLink">
            <app-inline-errors
                *ngIf="accountRequestForm.get('musicLink').errors && accountRequestForm.get('musicLink').touched"
                [errors]="['This field is required.']"></app-inline-errors>
          </div>
          <div class="form-group">
            <label>
              <input style="margin:3px 5px; float: left" type="checkbox"
                     formControlName="labelAgreement">
              Do you have a current agreement with a record label?
            </label>
          </div>
          <div class="form-group">
            <label>
              <input style="margin:3px 5px; float: left" type="checkbox"
                     formControlName="proMember">
              Are you a member of a PRO/collecting society?
            </label>
          </div>
          <div class="form-group">
            <label>
              <input style="margin:3px 5px; float: left" type="checkbox"
                     formControlName="companyAgreement">
              Do you have a current agreement with a publishing company?
            </label>
          </div>
        </div>
      </div>
      <div class="row text-left">
        <div class="col-md-12">
          <div class="form-group">
            <label for="typeBusiness">Role</label>
            <select class="form-control" id="typeBusiness" formControlName="role">
              <option value="" class="">Choose your Role</option>
              <option value="manager" id="musicManagerOpt">Manager</option>
              <option value="artist" id="artistOpt">Artist</option>
              <option value="publisher" id="publisherOpt">Publisher</option>
              <option value="songwriter" id="songwriterOpt">Songwriter</option>
              <option value="labelManager" id="labelManagerOpt">Label Manager</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row text-left">
        <div class="col-md-12">
          <div class="form-group">
            <label for="referrer">How did you hear about us? *</label>
            <textarea type="text" class="form-control" id="referrer" rows="3"
                      formControlName="referrer">
            </textarea>
            <app-inline-errors
                *ngIf="accountRequestForm.get('referrer').errors && accountRequestForm.get('referrer').touched"
                [errors]="['This field is required.']"></app-inline-errors>
          </div>
          <div class="form-group">
            <label for="inputMessage">Any further information you'd like to share? (optional):</label>
            <textarea type="text" class="form-control" id="inputMessage" rows="3"
                      formControlName="message">
            </textarea>
          </div>
        </div>
      </div>
    </form>
    <div class="row mt20" *ngIf="showMusicForm">
      <div class="col-md-12 text-center">
        <button id="requestAccountBtn" type="submit" class="btn primary-btn mb0" (click)="sendForm()"
                [disabled]="loadingRequest || accountRequestForm.untouched || accountRequestForm.invalid">Submit
          <i class="spin medium loader-margin" *ngIf="loadingRequest"></i>
        </button>
      </div>
    </div>
    <div class="mt15" *ngFor="let alert of alerts">
      <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout"
             (onClosed)="onAlertClosed(alert)">{{ alert.message }}</alert>
    </div>
  </div>
  <div class="modal-body" *ngIf="requestSuccess" [@inOutAnimation]>
    <div class="row text-center">
      <div class="col-md-12">
        <h4 class="modal-title text-center">
          <span style="font-size: 70px;" class="glyphicon glyphicon-ok-circle font-20x"></span>
        </h4>
        <h2>Thank you for submitting your music!</h2>
      </div>
    </div>
    <div class="row text-center">
      <div class="col-md-12">
        <p>Our A&R team will review. If we hear something we like and works well for use in Ads/TV/Film we'll get back
          to you.</p>
        <br/><br/>
      </div>
    </div>
    <div class="row text-center">
      <div class="col-md-12">
        <button id="discoverBtn" type="submit"
                class="btn primary-btn mt20 mb20"
                (click)="hideModal()">
          Ok
        </button>
      </div>
    </div>
  </div>
</ng-template>

