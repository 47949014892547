<ng-template #invitePeopleModal>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <button type="button" class="close" (click)="hideModal()"><span class="sr-only">Close</span><span
            class="ss-icon-close"></span></button>
        <h2 class="modal-title" id="loginModal">{{title || 'Add User'}}</h2>
        <p>{{message}}</p>
      </div>
    </div>
    <div class="row text-left mt30">
      <div class="col-md-12">
        <form [formGroup]="userForm">
          <div class="form-group" [ngClass]="{'col-md-10 col-sm-10': projectInvitation}">
            <label for="userFormEmail">Email *</label>
            <input data-hj-allow  type="text" formControlName="email" class="form-control" id="userFormEmail"
                   placeholder="Enter email">
            <app-inline-errors *ngIf="userForm.controls['email'].errors && userForm.controls['email'].touched "
                               [errors]="['This field is required.']"></app-inline-errors>
          </div>
          <div *ngIf="projectInvitation" class="btn-group" dropdown>
            <button id="button-basic" dropdownToggle type="button" class="btn primary-btn dropdown-toggle"
                    aria-controls="dropdown-basic">
              <span class="mr5 fa" [ngClass]="userForm?.value?.permissionType?.icon"></span>
              <span class="caret"></span>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu default"
                role="menu" aria-labelledby="button-basic">
              <li *ngFor="let permission of projectPermissionTypes" role="menuitem">
                <a class="dropdown-item pl10" (click)="projectType(permission)">
                <span *ngIf="userForm?.value?.permissionType?.id === permission.id" class="fa fa-check mr5"></span>
                <span [ngClass]="{'ml15': userForm?.value?.permissionType?.id !== permission.id}">{{permission.label}}</span>
                </a>
              </li>
            </ul>
          </div>
          <div class="form-group" *ngIf="!simpleInvitation">
            <label for="userFormName">First Name *</label>
            <input data-hj-allow  type="text" formControlName="firstName" class="form-control" id="userFormName"
                   placeholder="Enter first name">
            <app-inline-errors *ngIf="userForm.controls['firstName'].errors && userForm.controls['firstName'].touched "
                               [errors]="['This field is required.']"></app-inline-errors>
          </div>
          <div class="form-group" *ngIf="!simpleInvitation">
            <label for="userFormLastName">Last Name *</label>
            <input data-hj-allow  type="text" formControlName="lastName" class="form-control" id="userFormLastName"
                   placeholder="Enter ingestion name">
            <app-inline-errors *ngIf="userForm.controls['lastName'].errors && userForm.controls['lastName'].touched "
                               [errors]="['This field is required.']"></app-inline-errors>
          </div>
          <p *ngIf="!simpleInvitation" [hidden]="invitationType && invitationType === 'inviteArtist'">srn: {{policies[0].srn}}</p>
          <div class="row mt20">
            <p class="error-color mb20 pl15">{{serverError}}</p>
            <div class="col-md-12 text-center">
              <button id="signUpBtn" type="submit" (click)="!invitationType ? inviteUser() : dynamicInvitation()"
                      class="btn primary-btn mb0"
                      [disabled]="userForm.invalid ||
                      userForm.untouched ||
                      loading">
                {{loading ? 'Submitting' : 'Submit'}}
                <i class="spin medium loader-margin" *ngIf="loading"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
