<ng-template #contactUsModal>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <button type="button" class="close" (click)="hideModal()"><span class="sr-only">Close</span><span
            class="ss-icon-close"></span></button>
        <div class="clearfix mb20 text-center">
          <h2>Get in touch</h2>
          <p>Call us at "+1 (305) 702-0180" or simply fill in the form below and we'll get back to you within 24
            hrs.</p>
        </div>
      </div>
    </div>
    <form [formGroup]="contactUsForm">
      <div class="form-group">
        <label for="inputFullName">Full Name*</label>
        <input data-hj-allow  type="text" formControlName="fullName" class="form-control" id="inputFullName">
        <app-inline-errors [errors]="['this field is required']"
          *ngIf="contactUsForm.controls['fullName'].errors && !contactUsForm.controls['fullName'].pristine"></app-inline-errors>
      </div>
      <div class="form-group">
        <label for="inputArtistCompanyName">Artist Or Company Name*</label>
        <input data-hj-allow  type="text" formControlName="artistCompanyName" class="form-control" id="inputArtistCompanyName">
        <app-inline-errors [errors]="['this field is required']"
          *ngIf="contactUsForm.controls['artistCompanyName'].errors && !contactUsForm.controls['artistCompanyName'].pristine">
        </app-inline-errors>
      </div>
      <div class="form-group">
        <label for="inputContactEmail">Contact Email*</label>
        <input data-hj-allow  type="email" formControlName="contactEmail" class="form-control" id="inputContactEmail">
        <app-inline-errors [errors]="['this field is required, must be a valid email']"
           *ngIf="contactUsForm.controls['contactEmail'].errors && !contactUsForm.controls['contactEmail'].pristine"></app-inline-errors>
      </div>
      <div class="form-group">
        <label for="inputSubject">Subject*</label>
        <input data-hj-allow  type="text" formControlName="subject" class="form-control" id="inputSubject">
        <app-inline-errors [errors]="['this field is required']"
          *ngIf="contactUsForm.controls['subject'].errors && !contactUsForm.controls['subject'].pristine"></app-inline-errors>
      </div>
      <div class="form-group">
        <label for="inputMessage">Message*</label>
        <input data-hj-allow  type="text" formControlName="messageContact" class="form-control" id="inputMessage">
        <app-inline-errors [errors]="['this field is required']"
          *ngIf="contactUsForm.controls['messageContact'].errors && !contactUsForm.controls['messageContact'].pristine"></app-inline-errors>
      </div>
    </form>
    <div>
      <p style="font-weight: bold;">For more information contact:</p>
      <p>Salo Sterental</p>
      <p>+1 (305) 702-0180 ext 701</p>
      <a href="mailto:salo@sostereomusic.com"
      target="_top">salo&#64;sostereomusic.com</a>
    </div>
    <div class="clearfix">
      <div class="form-group col-sm-12">
        <label class="mt30" *ngIf="disableBtn">Sending your request....</label>
        <button type="submit" class="btn primary-btn col-sm-4 mt20 pull-right" (click)="sendEmail()"
                [disabled]="disableBtn || contactUsForm.invalid">Submit
        </button>
      </div>
      <div class="form-group mt15 pl0 pr0 col-sm-12 col-xs-12" style="display: inline-block;">
        <div class="alert alert-success alert-dismissable" [hidden]="!requestSuccess">
          <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
          <strong>Your message has been sent!</strong>
        </div>
        <div class="alert alert-danger" [hidden]="!requestError">
          <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
          <strong>We couln't send your message, please try again!</strong>
        </div>
      </div>
    </div>
  </div>
</ng-template>
