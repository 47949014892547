import { Inject, Injectable } from '@angular/core';
import { AuthenticationService, FileService } from 'sostereo-services';
import { TrackingService } from './tracking.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import * as Sentry from '@sentry/browser';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { cloneDeep } from 'lodash-es';
import { CommonService } from './common.service';

declare var InstallTrigger, safari, window;

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  public showWarning;
  public beforeunload;

  constructor(
    private fileService: FileService,
    private trackingService: TrackingService,
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private commonService: CommonService,
    @Inject('environment') private environment,
  ) {}

  public saveFile(blobParts, fileName) {
    const isFirefox = typeof InstallTrigger !== 'undefined';
    const isSafari =
      /constructor/i.test(window.HTMLElement) ||
      ((p) => p.toString() === '[object SafariRemoteNotification]')(
        !window.safari || safari.pushNotification,
      );
    const file = new Blob([blobParts], { type: 'application/zip' });
    const fileURL = URL.createObjectURL(file);
    const a = document.createElement('a');
    if (isFirefox) {
      document.body.appendChild(a);
    }
    if (isSafari) {
      window.onbeforeunload = null;
    }
    a.href = fileURL;
    a.download = fileName;
    a.click();
  }

  public addLeaveWarning() {
    this.showWarning = true;
    this.beforeunload = window.addEventListener('beforeunload', (event) => {
      if (this.showWarning) {
        event.returnValue = `Are you sure you want to leave this page`;
      } else {
        window.onbeforeunload = null;
        this.killEvent();
      }
    });
  }

  killEvent() {
    this.beforeunload.cancel();
  }

  public killLeaveWarning() {
    this.showWarning = false;
  }

  public downloadSingleSong(song, songUrl, version, artists?, kind?) {
    return new Promise((resolve) => {
      // tslint:disable-next-line:max-line-length
      let songRequest = `${this.environment.apiHost}/${this.environment.corePath}/${
        this.environment.apiVersion
      }/songs/${song.trackId || song._id || song.id}/streaming?type=${kind ? kind : 'streaming'}`;
      const hasUri = song.uri ? true : !!(song.soId && (song._id || song.id));

      if (!hasUri) {
        songRequest = songUrl;
      }
      const extension = !kind ? 'mp3' : songUrl.split('.').pop();
      const tenantId = this.authenticationService.getCurrentUser()
        ? this.authenticationService.getCurrentUser().tenantId
        : 'SoStereo';
      const songName = song.songName || song.name || song.fileName;
      const artistData = artists
        ? ' by' + artists
        : song.artistName
        ? ' by ' + song.artistName
        : '';
      const versionType = version
        ? ` - (${version.charAt(0).toUpperCase() + version.slice(1)})` +
          (song.hasOwnProperty('vocals') && !song.vocals && version.toLowerCase() !== 'instrumental'
            ? ' (Instrumental)'
            : '') +
          (song.hasOwnProperty('explicit') && song.explicit ? ' (Explicit)' : '') +
          ` [${tenantId}]`
        : song.senderCompany
        ? ` [${song.senderCompany}]`
        : '';
      let queryParams: any = cloneDeep(this.commonService.getQueryParamsFromUrl());
      queryParams.download = 'song';
      queryParams.identity = this.trackingService.getUserRole(
        this.authenticationService.getCurrentUser().scopes,
      );

      this.trackingService.trackViewPage(queryParams);
      this.http
        .get(songRequest, { responseType: 'blob', observe: 'response' })
        .pipe(
          finalize(() => {
            this.toastr.clear();
            resolve({});
          }),
        )
        .subscribe({
          next: (res) => {
            var binaryData = [];
            binaryData.push(res.body);
            const url = window.URL.createObjectURL(new Blob(binaryData));
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.target = '_blank';
            a.href = url;
            const nameByDefault = songName + artistData + versionType + `.${extension}`;
            const contentDisposition = res.headers.get('content-disposition');

            let fileName = 'download';
            if (contentDisposition) {
              const match = contentDisposition.match(/filename\*=UTF-8''(.+)/);
              if (match) {
                fileName = decodeURIComponent(match[1] || nameByDefault);
              } else {
                fileName =
                  this.removeQuotesFromString(contentDisposition.split('filename=')[1]) ||
                  nameByDefault;
              }
            }

            a.download = fileName;
            a.click();
            a.remove();
            let trackData: any = {
              fileName: fileName,
              fileUrl: songUrl,
              trackId: song.trackId || song._id,
              action: 'PlayerWidget',
            };
            if (song.hasOwnProperty('playlistId')) {
              trackData.targetId = song.trackId || song._id;
              trackData = { ...trackData, ...song };
            }
            this.trackingService.track('Download song success', trackData);
          },
          error: (err) => {
            const fileName = songName + artistData + versionType;
            this.downloadFileErrorResponse(err, song, fileName, songUrl);
          },
        });
    });
  }

  downloadFileErrorResponse(err, song, fileName, songUrl) {
    console.log(err);
    this.toastr.error(err?.error?.message, 'There was an error Trying to download the song');
    this.trackingService.track(
      'Download song error',
      {
        fileName: fileName,
        fileUrl: songUrl,
        trackId: song?.trackId || song?._id,
        error: err,
        action: 'PlayerWidget',
      },
      {
        event_action: 'Download song success',
        event_type: 'Download Song Success',
        event_value: songUrl,
        element_type: 'File',
        element_name: fileName,
        element_id: song?.trackId || song?._id,
      },
    );
    Sentry.withScope((scope) => {
      scope.setExtra('SongData', song);
      Sentry.captureException(
        new Error(
          `There was an error downloading a song ${fileName}. API error: status ${'err.status'} - ${'message'}`,
        ),
      );
    });
  }

  removeQuotesFromString(str) {
    if (str && str.startsWith('"') && str.endsWith('"')) {
      return str.slice(1, -1);
    }
    return str;
  }
}
