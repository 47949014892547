import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { isString } from 'lodash-es';
import { TrackingService } from 'src/app/angular/shared/services/tracking.service';

@Component({
  selector: 'app-song-internal-notes-modal',
  templateUrl: './song-internal-notes-modal.component.html',
  styleUrls: ['./song-internal-notes-modal.component.scss'],
})
export class SongInternalNotesModalComponent implements OnInit {
  public modalRef: BsModalRef;
  @ViewChild('songInternalNotesModal', { static: true }) modalsTemplate: TemplateRef<any>;
  public song: any = {};
  public isString = isString;

  constructor(private modalService: BsModalService, private trackingService: TrackingService) {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {}

  public showModal(data) {
    this.song = data;
    this.modalRef = this.modalService.show(this.modalsTemplate, { class: 'modal-md' });
    this.trackingService.track(
      'Song internal notes modal opened',
      {
        action: 'Song internal notes modal opened',
        kind: 'Modal Open',
        songData: data,
      },
      {
        event_action: 'Song internal notes modal opened',
        event_type: 'Modal Opened',
        event_value: data?.name,
      },
    );
  }

  public hideModal() {
    this.modalRef.hide();
  }
}
