<ng-template #magiclinkModal>
  <div class="modal-content pl20 pr20">
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <button type="button" class="close" (click)="hideModal()">
            <span class="sr-only">Close</span>
            <span class="ss-icon-close"></span>
          </button>
          <h4 class="modal-title">Magic Link</h4>
        </div>
      </div>
      <div class="row text-left mt0">
        <div class="col-md-12">
          <form [formGroup]="magicLinkForm">
            <div class="form-group mt20 col-sm-9 pl0">
              <label for="inputEmailML">Email:</label>
              <input
                type="email"
                class="form-control"
                formControlName="email"
                id="inputEmailML"
                (keydown)="onKeydown($event)"
              />
            </div>
            <div class="col-sm-3 pl0 pr0 mt20 d-flex justify-content-end">
              <div class="d-flex flex-column">
                <label class="title-switch">It's Reusable</label>
                <label class="switch">
                  <input type="checkbox" formControlName="isMagicTokenReusable" />
                  <span class="slider"></span>
                </label>
              </div>
            </div>
            <div class="col-md-12 pl0 mb10">
              <label>Set Token Expiration:</label>
            </div>
            <div class="col-md-12 pl0 pr0">
              <div class="form-group col-md-6 pl0">
                <select class="form-control" formControlName="unit" id="inputUnitML">
                  <option value="seconds">Seconds</option>
                  <option value="minutes">Minutes</option>
                  <option value="hours">Hours</option>
                  <option value="days">Days</option>
                </select>
              </div>
              <div class="form-group col-md-6 pr0">
                <input
                  type="number"
                  min="1"
                  class="form-control"
                  formControlName="amount"
                  id="inputAmountML"
                  (keydown)="onKeydown($event)"
                />
                <p
                  *ngIf="
                    magicLinkForm.controls['amount'].touched &&
                    magicLinkForm.controls['amount'].errors
                  "
                  class="error-color font-size-small"
                >
                  The amount is required and must be greater than 0
                </p>
              </div>
            </div>
            <div class="col-sm-12 row mt20">
              <div class="col-md-12 text-center">
                <button
                  [disabled]="loading"
                  id="createPlaylistBtn"
                  class="btn primary-btn mb0"
                  (click)="getMagicToken()"
                >
                  Get Magic Link
                  <i class="spin medium loader-margin" *ngIf="loading"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div *ngIf="urlMagicLink" class="pt30 pb30 mt30" style="border-top: 1px solid #d6d3d3">
        <p>Share this link to login instantly!!</p>
        <div class="input-group">
          <input
            data-hj-allow
            type="text"
            class="form-control"
            id="basic-url"
            [(ngModel)]="urlMagicLink"
            disabled
          />
          <span class="input-group-addon" id="linkUrlCopyBtn" (click)="copyUrlToClipboard()">{{
            copied ? 'Copied' : 'Copy'
          }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
