<ng-template #songInternalNotesModal>
  <div class="modal-content">
    <div class="modal-body mb30">
      <div class="row">
        <div class="col-md-12">
          <button type="button" class="close" (click)="hideModal()">
            <span class="sr-only">Close</span>
            <span class="ss-icon-close"></span>
          </button>
        </div>
      </div>
      <div>
        <div class="ml20">
          <h2>Internal notes</h2>
          <h5>
            {{ song.name || song.fileName }}
            {{
              song?.artists?.length > 0
                ? ' by ' + (song.artists[0].artistName || song.artists[0].name)
                : ''
            }}
          </h5>
          <br />
        </div>
      </div>
      <div *ngIf="song.stemsAvailable" class="pt15 ml20 mr20">
        <span
          class="material-icons color-primary pull-left mr5"
          style="position: relative; top: 5px"
          [attr.data-icon]="'water'"
        ></span>
        <h6>Stems available:</h6>
        <p>{{ song.stemsAvailable }}</p>
      </div>
      <div *ngIf="song.customizable" class="pt15 ml20 mr20">
        <span
          class="material-icons color-primary pull-left mr5"
          style="position: relative; top: 5px"
          [attr.data-icon]="'tune'"
        ></span>
        <h6>Customizable:</h6>
        <p>{{ song.customizable }}</p>
      </div>
      <div *ngIf="song.internalManager" class="pt15 ml20 mr20">
        <span
          class="material-icons color-primary pull-left mr5"
          style="position: relative; top: 5px"
          [attr.data-icon]="'support_agent'"
        ></span>
        <h6>Internal manager:</h6>
        <p>
          {{
            song.internalManager?.email ||
              (isString(song.internalManager) ? song.internalManager : song.internalManager?.name)
          }}
        </p>
      </div>
      <div *ngIf="song.notes" class="pt15 ml20 mr20">
        <span
          class="material-icons color-primary pull-left mr5"
          style="position: relative; top: 5px"
          [attr.data-icon]="'description'"
        ></span>
        <h6>Notes:</h6>
        <p>{{ song.notes }}</p>
      </div>
      <div *ngIf="song.restriction" class="pt15 ml20 mr20">
        <span
          class="material-icons color-primary pull-left mr5"
          style="position: relative; top: 5px"
          [attr.data-icon]="'remove_circle_outline'"
        ></span>
        <h6>Restrictions:</h6>
        <p>{{ song.restriction }}</p>
      </div>
    </div>
  </div>
</ng-template>
