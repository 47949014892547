<ng-template #songStatusModal>
  <div class="modal-body">
    <div class="row ml0 mr0">
      <div class="col-md-12">
        <h3 class="modal-title">SONG STATUS CHECK</h3>
      </div>
    </div>
    <div class="row ml0 mr0 mt30">
      <div class="col-md-12">
        <p style="font-size: 16px">
          <strong
            >Please check the song status before you continue with the license or playlist
            process</strong
          >
        </p>
      </div>
    </div>
    <div class="container-songs">
      <ng-container *ngFor="let song of elementsStatus; let artistsIndex = index">
        <div
          class="mt15"
          [ngClass]="{
            'multiple-song': elementsStatus.length > 1,
            'single-song': elementsStatus.length === 1
          }"
        >
          <h5 class="row ml0 mr0 pl15 pr15">{{ tracks[artistsIndex].name }}</h5>
          <div
            class="row ml0 mr0 mt15"
            [ngStyle]="{ display: tracks[artistsIndex]?.restrictions?.length > 8 ? 'block' : 'flex' }"
            style="flex-wrap: wrap"
          >
            <ng-container *ngFor="let element of song">
              <div
                *ngIf="element.show"
                class="col-xs-12 d-flex align-items-center mb20"
                [ngClass]="{
                  'col-md-6': elementsStatus.length === 1,
                  'col-md-4': elementsStatus.length > 1
                }"
              >
                <span
                  class="material-icons-outlined mr10"
                  [attr.data-icon]="element.googleIcon"
                ></span>
                <div class="col-xs-12 pr0">
                  <h4
                    class="item-title"
                    [ngStyle]="{
                      'text-transform': element.textTransform ? element.textTransform : 'capitalize'
                    }"
                  >
                    {{ element.title }}
                  </h4>
                  <ng-container *ngIf="element.field">
                    <div *ngIf="element.field === 'notes' && tracks[artistsIndex]?.notes">{{ tracks[artistsIndex]?.notes }}</div>
                    <ng-container *ngIf="element.field === 'restrictions'">
                      <div *ngIf="tracks[artistsIndex]?.restriction && !(tracks[artistsIndex]?.restrictions?.length > 0)">
                        {{ tracks[artistsIndex]?.restriction }}
                      </div>
                      <ul
                        *ngIf="tracks[artistsIndex]?.restrictions && tracks[artistsIndex]?.restrictions?.length > 0"
                        class="pl15"
                      >
                        <li *ngFor="let restriction of tracks[artistsIndex].restrictions">
                          <p class="fw500">{{ restriction.name }}</p>
                          <p *ngIf="restriction.expirationDate">
                            Restricted until: {{ restriction.expirationDate | truncate : [10, ''] }}
                          </p>
                          <p *ngIf="restriction.details">Details: {{ restriction.details }}</p>
                        </li>
                      </ul>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="row ml0 mr0">
      <div class="col-md-12">
        <button
          type="button"
          class="btn primary-btn mt10 mb10 ml15 pull-right agree-btn"
          (click)="agreeRestrictions()"
        >
          Agree
        </button>
        <button type="button" class="btn cancel-btn mt10 mb10 pull-right" (click)="hideModal()">
          Cancel
        </button>
      </div>
    </div>
  </div>
</ng-template>
