import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, Injectable, NgModule } from '@angular/core';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
const _c0 = ["*"];
function ProgressbarComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function ProgressbarComponent_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "bar", 3);
    i0.ɵɵprojection(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("type", ctx_r1.type)("value", ctx_r1._value)("max", ctx_r1.max)("animate", ctx_r1.animate)("striped", ctx_r1.striped);
  }
}
function ProgressbarComponent_ng_template_3_bar_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "bar", 3);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r6 = ctx.$implicit;
    const ctx_r5 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("type", item_r6.type)("value", item_r6.value)("max", item_r6.max || ctx_r5.max)("animate", ctx_r5.animate)("striped", ctx_r5.striped);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(item_r6.label);
  }
}
function ProgressbarComponent_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, ProgressbarComponent_ng_template_3_bar_0_Template, 2, 6, "bar", 4);
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngForOf", ctx_r3._values);
  }
}
class BarComponent {
  constructor(el, renderer) {
    this.el = el;
    this.renderer = renderer;
    /** maximum total value of progress element */
    this.max = 100;
    /** current value of progress bar */
    this.value = 0;
    /** if `true` changing value of progress bar will be animated */
    this.animate = false;
    /** If `true`, striped classes are applied */
    this.striped = false;
    /** provide one of the four supported contextual classes: `success`, `info`, `warning`, `danger` */
    this.type = 'info';
    this.percent = 100;
  }
  ngOnChanges(changes) {
    if (changes["value"] || changes["max"]) {
      this.percent = 100 * (Number(changes["value"]?.currentValue || this.value) / Number(changes["max"]?.currentValue || this.max || 100));
    }
    if (changes["type"]) {
      this.applyTypeClasses();
    }
  }
  applyTypeClasses() {
    if (this._prevType) {
      const barTypeClass = `progress-bar-${this._prevType}`;
      const bgClass = `bg-${this._prevType}`;
      this.renderer.removeClass(this.el.nativeElement, barTypeClass);
      this.renderer.removeClass(this.el.nativeElement, bgClass);
      this._prevType = void 0;
    }
    if (this.type) {
      const barTypeClass = `progress-bar-${this.type}`;
      const bgClass = `bg-${this.type}`;
      this.renderer.addClass(this.el.nativeElement, barTypeClass);
      this.renderer.addClass(this.el.nativeElement, bgClass);
      this._prevType = this.type;
    }
  }
  static {
    this.ɵfac = function BarComponent_Factory(t) {
      return new (t || BarComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: BarComponent,
      selectors: [["bar"]],
      hostAttrs: ["role", "progressbar", "aria-valuemin", "0"],
      hostVars: 13,
      hostBindings: function BarComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("aria-valuenow", ctx.value)("aria-valuetext", ctx.percent ? ctx.percent.toFixed(0) + "%" : "")("aria-valuemax", ctx.max);
          i0.ɵɵstyleProp("height", "100", "%")("width", ctx.percent, "%");
          i0.ɵɵclassProp("progress-bar", true)("progress-bar-animated", ctx.animate)("progress-bar-striped", ctx.striped);
        }
      },
      inputs: {
        max: "max",
        value: "value",
        animate: "animate",
        striped: "striped",
        type: "type"
      },
      features: [i0.ɵɵNgOnChangesFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function BarComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BarComponent, [{
    type: Component,
    args: [{
      selector: 'bar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        role: 'progressbar',
        'aria-valuemin': '0',
        '[class.progress-bar]': 'true',
        '[class.progress-bar-animated]': 'animate',
        '[class.progress-bar-striped]': 'striped',
        '[attr.aria-valuenow]': 'value',
        '[attr.aria-valuetext]': 'percent ? percent.toFixed(0) + "%" : ""',
        '[attr.aria-valuemax]': 'max',
        '[style.height.%]': '"100"',
        '[style.width.%]': 'percent'
      },
      template: "<ng-content></ng-content>\n"
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, {
    max: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    animate: [{
      type: Input
    }],
    striped: [{
      type: Input
    }],
    type: [{
      type: Input
    }]
  });
})();
class ProgressbarConfig {
  constructor() {
    /** if `true` changing value of progress bar will be animated */
    this.animate = false;
    /** maximum total value of progress element */
    this.max = 100;
  }
  static {
    this.ɵfac = function ProgressbarConfig_Factory(t) {
      return new (t || ProgressbarConfig)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ProgressbarConfig,
      factory: ProgressbarConfig.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProgressbarConfig, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class ProgressbarComponent {
  /** current value of progress bar. Could be a number or array of objects
   * like {"value":15,"type":"info","label":"15 %"}
   */
  set value(value) {
    this.isStacked = Array.isArray(value);
    if (typeof value === 'number') {
      this._value = value;
      this._values = void 0;
    } else {
      this._value = void 0;
      this._values = value;
    }
  }
  constructor(config) {
    /** maximum total value of progress element */
    this.max = 100;
    /** if `true` changing value of progress bar will be animated */
    this.animate = false;
    /** If `true`, striped classes are applied */
    this.striped = false;
    this.isStacked = false;
    this._value = 0;
    Object.assign(this, config);
  }
  static {
    this.ɵfac = function ProgressbarComponent_Factory(t) {
      return new (t || ProgressbarComponent)(i0.ɵɵdirectiveInject(ProgressbarConfig));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ProgressbarComponent,
      selectors: [["progressbar"]],
      hostVars: 3,
      hostBindings: function ProgressbarComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("max", ctx.max);
          i0.ɵɵclassProp("progress", true);
        }
      },
      inputs: {
        max: "max",
        animate: "animate",
        striped: "striped",
        type: "type",
        value: "value"
      },
      ngContentSelectors: _c0,
      decls: 5,
      vars: 3,
      consts: [[4, "ngIf", "ngIfThen", "ngIfElse"], ["NotStacked", ""], ["Stacked", ""], [3, "type", "value", "max", "animate", "striped"], [3, "type", "value", "max", "animate", "striped", 4, "ngFor", "ngForOf"]],
      template: function ProgressbarComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵtemplate(0, ProgressbarComponent_ng_container_0_Template, 1, 0, "ng-container", 0)(1, ProgressbarComponent_ng_template_1_Template, 2, 5, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor)(3, ProgressbarComponent_ng_template_3_Template, 1, 1, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor);
        }
        if (rf & 2) {
          const _r2 = i0.ɵɵreference(2);
          const _r4 = i0.ɵɵreference(4);
          i0.ɵɵproperty("ngIf", !ctx.isStacked)("ngIfThen", _r2)("ngIfElse", _r4);
        }
      },
      dependencies: [i2.NgForOf, i2.NgIf, BarComponent],
      styles: ["[_nghost-%COMP%]{width:100%;display:flex}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProgressbarComponent, [{
    type: Component,
    args: [{
      selector: 'progressbar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        '[class.progress]': 'true',
        '[attr.max]': 'max'
      },
      template: "<ng-container *ngIf=\"!isStacked then NotStacked else Stacked\"></ng-container>\n\n<ng-template #NotStacked>\n  <bar [type]=\"type\" [value]=\"_value\" [max]=\"max\" [animate]=\"animate\" [striped]=\"striped\">\n    <ng-content></ng-content>\n  </bar>\n</ng-template>\n\n<ng-template #Stacked>\n  <bar *ngFor=\"let item of _values\"\n       [type]=\"item.type\" [value]=\"item.value\" [max]=\"item.max || max\" [animate]=\"animate\" [striped]=\"striped\">{{ item.label }}</bar>\n</ng-template>\n",
      styles: [":host{width:100%;display:flex}\n"]
    }]
  }], function () {
    return [{
      type: ProgressbarConfig
    }];
  }, {
    max: [{
      type: Input
    }],
    animate: [{
      type: Input
    }],
    striped: [{
      type: Input
    }],
    type: [{
      type: Input
    }],
    value: [{
      type: Input
    }]
  });
})();
class ProgressbarModule {
  static forRoot() {
    return {
      ngModule: ProgressbarModule,
      providers: []
    };
  }
  static {
    this.ɵfac = function ProgressbarModule_Factory(t) {
      return new (t || ProgressbarModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ProgressbarModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProgressbarModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [BarComponent, ProgressbarComponent],
      exports: [BarComponent, ProgressbarComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { BarComponent, ProgressbarComponent, ProgressbarConfig, ProgressbarModule };
