<ng-template #songRestrictionModal>
  <div class="modal-body">
    <div class="row ml0 mr0">
      <div class="col-md-12">
        <h4 class="modal-title">Song restrictions</h4>
      </div>
    </div>
    <div class="row ml0 mr0 mt30">
      <div class="col-md-12">
        <p><strong *ngIf="isLicenseProcess">Please check the song restrictions before continue with the licensing
            process<br><br></strong>
          <strong>The song <span class="fw500">{{track?.name || track?.fileName}}</span> has the following
            restrictions:</strong>
        </p>
        <br>
        <div *ngIf="track?.restriction">{{track?.restriction}}</div>
        <ul *ngIf="track?.restrictions">
          <li *ngFor="let restriction of track.restrictions">
            <p class="fw500">{{restriction.name}}</p>
            <p *ngIf="restriction.expirationDate">Restricted until: {{restriction.expirationDate | truncate: [10, '']}}
            </p>
            <p *ngIf="restriction.details">Details: {{restriction.details}}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="row ml0 mr0">
      <div class="col-md-12">
        <button type="button" class="btn primary-btn mt10 mb10 ml15 pull-right agree-btn" (click)="agreeRestrictions()">
          {{isLicenseProcess?'Agree':'Accept'}}
        </button>
        <button *ngIf="isLicenseProcess" type="button" class="btn cancel-btn mt10 mb10 pull-right"
          (click)="hideModal()">
          Cancel
        </button>
      </div>
    </div>
  </div>
</ng-template>