<ng-template #briefSubmissionModal>
  <div class="brief-submission-modal modal-body bg-modal">
    <div class="row ml0 mr0">
      <div class="col-md-6 info-container mobile-hidden hidden-for-tablet pl50 pr50">
        <h1 class="color-dark text-center mt0 pb10">Discover the SoStereo <br />Advantage</h1>
        <div class="team-pics text-center pb20">
          <img src="./assets/images/about_us/team-pics/beto_azout.webp" alt="Beto Azut" />
          <img
            class="pic2 flip-img-h"
            src="./assets/images/about_us/team-pics/lindsey_camerota.webp"
            alt="Lindsey Camerota"
          />
          <img
            class="pic3"
            src="./assets/images/about_us/team-pics/martin_gutierrez.webp"
            alt="Martin Gutierrez"
          />
        </div>
        <p class="color-dark">
          SoStereo is known for our dependable service and dedication to excellence in providing
          music solution for brands, agencies, films and more.
          <br />
          <br />
          We work with award-winning record-label artists to find just the right sonic fit for your
          marketing.
          <br />
          <br />
          From thousands of pre-cleared songs to custom music to scalable enterprise solutions, real
          artists can do it all.
        </p>
        <h3 class="color-dark mt30 mb0 fw600">A preferred minority-owned vendor for:</h3>
        <div class="logos-list mt10">
          <img src="./assets/images/license_logos/wpp.webp" alt="WPP" />
          <img src="./assets/images/license_logos/publicis-small.webp" alt="Publicis" />
          <img src="./assets/images/license_logos/omnicom-small.webp" alt="Omnicom" />
          <img src="./assets/images/license_logos/ipg.webp" alt="IPG" />
          <img
            src="./assets/images/license_logos/stagwell-logo-small.webp"
            alt="Stagwell Partners"
            style="padding: 12px"
          />
        </div>
      </div>
      <div class="col-md-6 pl50 pr50">
        <button type="button" class="close" style="position: absolute; right: 0" (click)="cancel()">
          <span class="sr-only">Close</span><span class="ss-icon-close"></span>
        </button>
        <div class="row ml0 mr0">
          <div class="col-md-12 pl0 pr0">
            <div class="clearfix mb30 text-center">
              <h2 class="mb0 mt0">Submit a brief and</h2>
              <h2 class="mt0 mb30">
                {{
                  environment.name !== 'sostereo'
                    ? 'we will send you a Playlist'
                    : 'hear from us within an hour'
                }}
              </h2>
              <p
                [innerHTML]="modalDescription"
                class="hidden-for-mobile hidden-for-tablet"
                style="text-align: left"
              ></p>
              <div class="row mt30 ml0 mr0 show-for-mobile" *ngIf="environment.name === 'sostereo'">
                <div
                  class="col-md-5 col-sm-5 col-xs-12 pr0 pl0 team-pics"
                  *ngIf="environment.name === 'sostereo'"
                >
                  <img src="./assets/images/about_us/team-pics/beto_azout.webp" alt="Beto Azout" />
                  <img
                    class="pic2 flip-img-h"
                    src="./assets/images/about_us/team-pics/lindsey_camerota.webp"
                    alt="Lindsey Camerota"
                  />
                  <img
                    class="pic3"
                    src="./assets/images/about_us/team-pics/martin_gutierrez.webp"
                    alt="Martin Gutierrez"
                  />
                </div>
                <div class="col-md-7 col-sm-7 col-xs-12 pl0" style="text-align: left">
                  <p [innerHTML]="modalDescription"></p>
                </div>
              </div>
              <div class="row ml0 mr0 mt40 show-for-mobile" *ngIf="environment.name !== 'sostereo'">
                <div class="col-md-12 col-sm-12 col-xs-12 pl0" style="text-align: left">
                  <p [innerHTML]="modalDescription"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form [formGroup]="briefSubmissionForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label for="email">Email *</label>
            <input
              data-hj-allow
              type="text"
              formControlName="email"
              class="form-control"
              id="email"
            />
            <div
              *ngIf="
                briefSubmissionForm.controls['email'].touched &&
                briefSubmissionForm.controls['email'].errors
              "
            >
              <app-inline-errors
                [errors]="['this field is required, must be a valid email']"
              ></app-inline-errors>
            </div>
          </div>
          <div class="form-group">
            <label for="brief">Tell us about your project</label>
            <textarea
              type="text"
              formControlName="brief"
              class="form-control"
              id="brief"
              rows="4"
              placeholder="Please include details of the spot including: brand/terms/budget/timeline and moods/genres!"
            ></textarea>
            <div
              *ngIf="
                briefSubmissionForm.controls['brief'].touched &&
                briefSubmissionForm.controls['brief'].errors
              "
            >
              <app-inline-errors [errors]="['this field is required']"></app-inline-errors>
            </div>
          </div>
          <div class="clearfix">
            <div class="form-group col-sm-12 text-center">
              <button
                [disabled]="loadingRequest || briefSubmissionForm.invalid"
                type="submit"
                class="btn primary-btn mt20"
                id="submitBriefForm"
              >
                Send
                <i class="spin medium" *ngIf="loadingRequest"></i>
              </button>
            </div>
          </div>
          <div *ngFor="let alert of alerts">
            <alert
              [type]="alert.type"
              [dismissOnTimeout]="alert.timeout"
              (onClosed)="onAlertClosed(alert)"
              >{{ alert.message }}</alert
            >
          </div>
        </form>
        <div class="text-center">
          <p>Are you an Artist or represent one?</p>
          <a (click)="hideModal(); accountRequestModal.showModal()">Submit Your Music</a>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<app-account-request-modal #accountRequestModal></app-account-request-modal>
