<ng-template #editPlaylistModal>
  <div class="modal-content" id="addToPlaylistModal">
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <button type="button" class="close" (click)="hideModal()">
            <span class="sr-only">Close</span>
            <span class="ss-icon-close"></span>
          </button>
          <h4 class="modal-title">Edit playlist details</h4>
        </div>
      </div>
      <div class="row text-left mt0 forms-bottom-line">
        <form [formGroup]="editPlaylistForm">
          <div class="col-xs-12 pl0 pr0 d-flex mt20">
            <div
              class="col-xs-5 col-lg-3 pr0 mr15 container-image-edit"
              (mouseenter)="hover = true && createImagePermission"
              (mouseleave)="hover = false"
              ngfDrop
              multiple="1"
              selectable="1"
              accept=".jpg,jpeg"
              (click)="$event.target.value = null"
              (fileChange)="onFileChange($event)"
              [fileDropDisabled]="!createImagePermission || imageLoading"
            >
              <img
                *ngIf="thumbUrl"
                [src]="thumbUrl"
                alt="playlist"
                class="background-card img-preview"
              />
              <div
                *ngIf="!thumbUrl || hover"
                class="background-card"
                [ngClass]="{ 'hover-image': thumbUrl, 'default-image': !thumbUrl }"
              >
                <div
                  class="d-flex flex-column align-items-center center-icon"
                  style="
                    position: absolute;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    color: #cecece;
                    gap: 5px;
                    width: calc(100% - 15px);
                  "
                >
                  <span
                    class="material-icons"
                    [attr.data-icon]="'image_search'"
                    style="font-size: 50px"
                  >
                  </span>
                  <span style="font-weight: 600"
                    >{{ createImagePermission ? 'Upload' : 'Select' }} Image</span
                  >
                </div>
              </div>
              <i
                *ngIf="imageLoading"
                style="position: absolute; top: 70%; left: 33%"
                class="spin blue-spin large loader-margin"
              ></i>
            </div>
            <div class="col-xs-7 col-lg-9 d-flex flex-column justify-content-center pl0">
              <div class="d-flex align-items-end justify-content-space-between">
                <label style="text-transform: none">Select a cover image</label>
              </div>

              <div
                style="
                  display: grid;
                  grid-template-rows: repeat(2, 70px);
                  grid-template-columns: repeat(auto-fit, 70px);
                  grid-gap: 1px;
                  overflow: hidden;
                  height: 142px;
                "
              >
                <div *ngFor="let artist of artistImages">
                  <img
                    [src]="artist.profileImages?.thumbUrl"
                    [alt]="artist.name"
                    style="width: 70px; height: auto"
                    (click)="thumbUrl = artist.profileImages?.thumbUrl"
                    (error)="handleMissingImage($event)"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-xs-12 mb20 mt20">
            <div
              class="form-group"
              [ngClass]="{ notEmpty: editPlaylistForm.controls['name'].value }"
            >
              <label for="name">Name</label>
              <input
                id="name"
                name="name"
                type="text"
                formControlName="name"
                class="form-control"
                placeholder="Name (Required)"
              />
              <div
                *ngIf="
                  editPlaylistForm.controls['name'].touched &&
                  editPlaylistForm.controls['name'].errors
                "
              >
                <app-inline-errors [backgroundWhite]="true" [errors]="['this field is required']">
                </app-inline-errors>
              </div>
            </div>
            <div
              class="form-group"
              [ngClass]="{ notEmpty: editPlaylistForm.controls['description'].value }"
            >
              <label for="description">Description </label>
              <textarea
                id="description"
                name="description"
                type="text"
                formControlName="description"
                class="form-control"
                placeholder="Description "
              ></textarea>
            </div>
            <div
              *ngIf="isAllowedPrivacy && isMyPlaylist"
              class="form-group"
              [ngClass]="{ notEmpty: editPlaylistForm.controls['privacy'].value }"
            >
              <label for="privacy">Privacy </label>
              <select id="privacy" name="privacy" formControlName="privacy" class="form-control">
                <option value="OnlyMe">Only me</option>
                <option value="TeamView">Team View (Read-Only)</option>
                <option value="TeamEdit">Team Edit (Edit by Team)</option>
              </select>
            </div>
            <div
              class="form-group"
              [ngClass]="{ notEmpty: editPlaylistForm.controls['status'].value }"
            >
              <label for="status">Status </label>
              <select id="status" name="status" formControlName="status" class="form-control">
                <option value="unpublished">Unpublished</option>
                <option value="published">Published</option>
              </select>
            </div>
            <div
              *ngIf="isAllowEditorial"
              class="form-group"
              [ngClass]="{ notEmpty: editPlaylistForm.controls['editorial'].value }"
            >
              <label for="editorial">Editorial </label>
              <select
                id="editorial"
                name="editorial"
                formControlName="editorial"
                class="form-control"
              >
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div class="d-flex justify-content-end" style="gap: 10px">
              <button type="button" class="btn cancel-btn mt30" (click)="hideModal()">
                Cancel
              </button>
              <button
                class="btn primary-btn mt30"
                [disabled]="loadingForm || editPlaylistForm.invalid"
                (click)="submitEditPlaylist()"
              >
                Save
                <i class="spin medium loader-margin" *ngIf="loadingForm"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
