import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';
import {
  AuthenticationService,
  FileService,
  PlaylistsStereoSpacesService,
} from 'sostereo-services';
import { CommonService } from 'src/app/angular/shared/services/common.service';
import { TrackingService } from 'src/app/angular/shared/services/tracking.service';
import { isEmpty } from 'lodash-es';

@Component({
  selector: 'app-edit-playlist-modal',
  templateUrl: './edit-playlist-modal.component.html',
  styleUrls: ['./edit-playlist-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditPlaylistModalComponent {
  @ViewChild('editPlaylistModal', { static: true })
  modalTemplate: TemplateRef<any>;

  public modalRef: BsModalRef;
  @Input() playlistDetails: any;
  public editPlaylistForm: FormGroup;
  public loadingForm = false;
  public isAllowEditorial = this.commonService.isAllowed(['UpdatePlaylistEditorial']);
  public createImagePermission = this.commonService.isAllowed(['CreateImage']);
  public createUploadTokenPermission = this.commonService.isAllowed(['CreateUploadtoken']);
  public isAllowedPrivacy = this.authenticationService.getCurrentUser()?.groups?.length > 0;
  public isMyPlaylist = true;
  public artistImages: any[] = [];
  public thumbUrl: string;
  public hover = false;
  private uploadToken;
  public imageLoading = false;

  constructor(
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private playlistsService: PlaylistsStereoSpacesService,
    private authenticationService: AuthenticationService,
    private commonService: CommonService,
    private toastr: ToastrService,
    private trackingService: TrackingService,
    private fileService: FileService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  showModal() {
    this.isMyPlaylist =
      this.playlistDetails?.ownerUid === this.authenticationService.getCurrentUser()?.uid;
    this.createForm();
    if (this.createUploadTokenPermission) {
      this.getToken();
    } else {
      this.createImagePermission = false;
    }
    this.modalRef = this.modalService.show(this.modalTemplate, {
      class: 'modal-lg',
    });
  }

  private getToken() {
    this.fileService
      .uploadToken({ moduleName: 'playlists', resourceType: 'Image' }, { publicFile: true })
      .subscribe({
        next: (res) => {
          this.uploadToken = res.data.uploadToken;
        },
        error: (err) => {
          this.trackingService.track('Upload token error', {
            errorStatus: err?.statusText + ' - ' + err?.status,
            errorMessage: err?.error?.message,
          });
        },
      });
  }

  public hideModal() {
    this.modalRef?.hide();
  }

  createForm() {
    this.thumbUrl = this.playlistDetails?.thumbUrl;
    this.artistImages = Object.values(this.playlistDetails?.artists || {});
    const privacy = this.playlistDetails?.publicAccess
      ? this.playlistDetails.allowTrackAdditions === 'team'
        ? 'TeamEdit'
        : 'TeamView'
      : 'OnlyMe';
    const editorial: string = this.playlistDetails?.editorial ? 'yes' : 'no';
    this.editPlaylistForm = this.formBuilder.group({
      name: new FormControl(
        { value: this.playlistDetails?.name, disabled: !this.isMyPlaylist },
        {
          validators: [Validators.required, this.noWhitespaceValidator],
          updateOn: 'change',
        },
      ),
      description: new FormControl(this.playlistDetails?.description),
      status: new FormControl(this.playlistDetails?.status, { validators: [Validators.required] }),
    });

    if (this.isAllowedPrivacy) {
      this.editPlaylistForm.addControl(
        'privacy',
        new FormControl(privacy, { validators: [Validators.required] }),
      );
    }
    if (this.isAllowEditorial) {
      this.editPlaylistForm.addControl(
        'editorial',
        new FormControl(editorial, { validators: [Validators.required] }),
      );
    }
  }

  public noWhitespaceValidator(control: FormControl) {
    return (control.value || '').trim().length ? null : { whitespace: true };
  }

  getNewData() {
    const { name, description, privacy, editorial, status } = this.editPlaylistForm.controls;
    const editionData: any = {
      name: name.value,
      description: description.value,
      status: status.value,
    };
    if (this.isAllowedPrivacy) {
      editionData.groups =
        privacy?.value === 'OnlyMe' ? [] : this.authenticationService.getCurrentUser().groups || [];
      editionData.allowTrackAdditions = privacy?.value === 'TeamEdit' ? 'team' : 'only-me';
    }
    if (this.isAllowEditorial) {
      editionData.editorial = editorial?.value === 'yes';
    }
    if (this.thumbUrl) {
      editionData.thumbUrl = this.thumbUrl;
    }

    let dataToUpdate: any = {};
    for (var key in editionData) {
      if (editionData[key] !== this.playlistDetails[key]) {
        dataToUpdate[key] = editionData[key];
        if (key === 'name') {
          dataToUpdate.nickname = this.authenticationService.getCurrentUser()?.nickname;
        }
      }
    }
    return dataToUpdate;
  }

  submitEditPlaylist() {
    this.editPlaylistForm.markAllAsTouched();
    if (!this.thumbUrl && this.editPlaylistForm.value.editorial === 'yes') {
      this.toastr.warning(`Don't forget to choose a cover image for the playlist`);
      return;
    }
    if (this.editPlaylistForm.valid) {
      this.loadingForm = true;
      const editionData: any = this.getNewData();
      this.sendUpdatePlaylist(editionData);
    }
  }

  sendUpdatePlaylist(editionData: any, closeModal = true) {
    if (!isEmpty(editionData)) {
      this.playlistsService
        .update(this.playlistDetails._id, editionData)
        .pipe(
          finalize(() => {
            this.loadingForm = false;
            if (closeModal) {
              this.hideModal();
            }
          }),
        )
        .subscribe({
          next: () => {
            this.toastr.success(`Playlist modified successfully`, '');
            this.playlistsService.updatePlaylistEvent.next({
              ...editionData,
              _id: this.playlistDetails._id,
            });
            this.trackingService.track(
              'Playlist Edited',
              {
                playlistEditions: editionData,
              },
              {
                event_action: 'Playlist Edited',
                event_type: 'Playlist Edition',
                event_value: this.playlistDetails._id,
              },
            );
          },
          error: (err) => {
            this.toastr.error(err?.error?.message, 'Error');
            this.trackingService.track('Update Playlist Error', { error: err.error });
          },
        });
    } else {
      this.toastr.warning(`You haven't modified any field`, '');
      this.loadingForm = false;
    }
  }

  handleMissingImage(event: Event) {
    (event.target as HTMLImageElement).parentElement.remove();
  }

  public onFileChange(file) {
    if (file?.size <= 1000000) {
      this.toastr.info('', 'We are uploading your image');
      this.imageLoading = true;
      this.createFile(file);
    } else {
      this.toastr.warning('Max size 1Mb. Try to compress or export it for web', 'Image too large');
    }
  }

  public createFile(file) {
    const upload: any = {
      name: file.name,
      uploadToken: this.uploadToken,
      // key: this.playlistDetails._id,
    };
    this.fileService.createFile(upload).subscribe({
      next: (res) => {
        this.uploadFileAWSS3(res.data, file);
      },
      error: (err) => {
        this.imageLoading = false;
        this.toastr.error(`Error uploading ${file.name}. ${err?.error?.message}`, 'Error');
        this.trackingService.track('Create file error', {
          errorStatus: err?.statusText + ' - ' + err?.status,
          errorMessage: err?.message,
          fileData: file,
          error: err?.error,
        });
      },
    });
  }

  public uploadFileAWSS3(createdFile, file) {
    this.fileService
      .uploadFileAWSS3(createdFile.url, file, createdFile.fields)
      .pipe(finalize(() => this.changeDetectorRef.detectChanges()))
      .subscribe({
        next: (uploadRes: any) => {
          if (uploadRes.ok) {
            this.processImage(createdFile.fileUrl, file);
            console.log('artistService.uploadImage >>>', uploadRes, createdFile);
          }
        },
        error: (err) => {
          this.imageLoading = false;
          this.trackingService.track('uploadfileAWSS3 error', {
            errorStatus: err?.statusText + ' - ' + err?.status,
            errorMessage: err?.message,
            fileData: file,
            ...err,
          });
          this.toastr.error(err?.error?.message, `Error uploading ${file.name}`);
          console.error('Error uploading image >>>> ', err);
        },
      });
  }

  private processImage(url, file) {
    this.fileService
      .imageProcessing({ url: url, invocationType: 'RequestResponse' })
      .pipe(
        finalize(() => {
          this.imageLoading = false;
          this.changeDetectorRef.detectChanges();
        }),
      )
      .subscribe({
        next: (res) => {
          console.log('>>>>res', res);
          this.thumbUrl = `${res.data.url.replace('@xlarge', '@profile')}`;
        },
        error: (err) => {
          this.toastr.error(
            err?.error?.message || err?.message,
            `Error uploading ${file.name}. ${err.message}`,
          );
          this.trackingService.track('Image processing error', {
            errorStatus: err.statusText + ' - ' + err.status,
            errorMessage: err.message,
            awsUrl: url,
          });
        },
      });
  }
}
