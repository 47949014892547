<ng-template #sharePlaylistModal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <button type="button" class="close" (click)="cancel()">
            <span class="sr-only">Close</span>
            <span class="ss-icon-close"></span>
          </button>
          <h4 class="modal-title">Share This Playlist</h4>
        </div>
      </div>
      <div class="row text-left mt0">
        <div class="col-md-12 mt10">
          <div class="col-xs-12 col-md-6 pl0">
            <div class="d-flex align-items-start mt10 mb10">
              <input
                type="checkbox"
                [(ngModel)]="params[0].show"
                style="cursor: pointer"
                (click)="modifyUrl('showLyrics')"
              />
              <span class="ml5" (click)="modifyUrl('showLyrics')" style="cursor: pointer"
                >Display lyrics</span
              >
              <i
                aria-hidden="true"
                style="font-size: 18px"
                class="material-icons-outlined table-material-icon ml10"
                [attr.data-icon]="'error_outline'"
                tooltip="Check the box if you want the lyrics to be open when sharing the playlist"
              ></i>
            </div>
          </div>
          <div *ngIf="showCatalog" class="col-xs-12 col-md-6 pl0">
            <div class="d-flex align-items-start mt10 mb10">
              <input
                type="checkbox"
                [(ngModel)]="params[1].show"
                (click)="modifyUrl('catalog')"
                style="cursor: pointer"
              />
              <span class="ml5" (click)="modifyUrl('catalog')" style="cursor: pointer"
                >Ignore brand agreements</span
              >
              <i
                aria-hidden="true"
                style="font-size: 18px"
                class="material-icons-outlined table-material-icon ml10"
                [attr.data-icon]="'error_outline'"
                tooltip="Clients will see all playlist songs independently of the brand agreement they have"
              ></i>
            </div>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="input-group mt10">
            <input type="text" class="form-control" id="basic-url" [(ngModel)]="url" disabled />
            <span
              class="input-group-addon"
              id="playlistUrlCopyBtn"
              (click)="copyUrlToClipboard()"
              >{{ copied ? 'Copied' : 'Copy' }}</span
            >
          </div>
        </div>
        <div
          class="col-md-12"
          align="center"
          [ngStyle]="{
            'margin-top':
              deviceDetectorService.isMobile() || deviceDetectorService.isTablet()
                ? '150px'
                : '30px'
          }"
        >
          <button id="sharePlaylistBtn" class="btn primary-btn mb0" (click)="cancel()">Ok</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
