<ng-template #completeAccountModal>
  <div class="modal-body" [ngClass]="{'pitch-style': isPitch}">
    <div class="row">
      <div class="col-md-12">
        <button *ngIf="downloadPlaylist && !isPitch" type="button" class="close" (click)="cancel()"><span class="sr-only">Close</span><span
            class="ss-icon-close"></span></button>
        <button type="button" class="close-btn" (click)="hideModal()" *ngIf="isPitch && downloadPlaylist"
        style="right: 10px; bottom: 10px;">
          &times;
        </button>
        <h4 class="modal-title" id="completeAccountModal" style="text-transform: uppercase;">Thank you!</h4>
        <p class="">{{downloadPlaylist ? 'Would you like to create an account?' :
          'Would you like to finish creating an account with the fields & Submit?'}}</p>
      </div>
      <div class="row col-md-12 text-left ml0 mr0" [ngClass]="{'mt30': downloadPlaylist}">
        <div class="col-md-12 col-lg-12 mt30 pl0 pr0" align="left" [ngClass]="{'col-lg-5': downloadPlaylist}">
          <form [formGroup]="completeAccountForm" class="mt-0">
            <div class="form-group">
              <label for="completeAccountFirstName">First Name:</label>
              <input data-hj-allow type="text" formControlName="firstName" maxlength="30" class="form-control"
                id="completeAccountFirstName"
                     placeholder="Enter your first name">
              <div
                  *ngIf="completeAccountForm.controls['firstName'].touched && completeAccountForm.controls['firstName'].errors">
                <app-inline-errors [errors]="['this field is required']"></app-inline-errors>
              </div>
            </div>
            <div class="form-group">
              <label for="completeAccountLastName">Last Name:</label>
              <input data-hj-allow  type="text" formControlName="lastName" maxlength="30" class="form-control" id="completeAccountLastName"
                     autocomplete="off"
                     placeholder="Enter your last name">
              <div
                  *ngIf="completeAccountForm.controls['lastName'].touched && completeAccountForm.controls['lastName'].errors">
                <app-inline-errors [errors]="['this field is required']"></app-inline-errors>
              </div>
            </div>
            <div class="form-group">
              <label for="msUpdateFormPwd">Password</label>
              <input type="password" formControlName="password" class="form-control" id="msUpdateFormPwd"
                     autocomplete="new-password"
                     placeholder="Password">
              <div
                  *ngIf="completeAccountForm.controls['password'].touched && completeAccountForm.controls['password'].errors">
                <app-inline-errors [errors]="['this field is required']"></app-inline-errors>
              </div>
            </div>
          </form>
        </div>
        <div *ngIf="downloadPlaylist" class="col-xs-2 col-sm-2 col-md-2 general-signup-divider">
          <div class="signup-divider" style="height: 230px"></div>
        </div>
        <div *ngIf="downloadPlaylist" class="col-md-12 col-lg-5 mt30 pl0 pr0" align="center">
          <div class="row mt30" align="center" *ngIf="isDownloading">
            <div class="col-md-12">
              <div class="loader" align="center">
                <div class="lds-ripple">
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
            <div class="col-md-12 mt20">
              <p>Downloading, this may take a minute.</p>
            </div>
          </div>
          <div class="row mt30" align="center" *ngIf="!isDownloading">
            <div class="col-md-12 mt20">
              <p>Zip Successfully downloaded!</p>
              <br>
              <br>
              <p>Go to your downloads to check the music zip folder</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt10 mb10">
        <div class="col-md-12 text-center">
          <p class="error-color centered-text pb20">{{generalError}}</p>
          <button id="msUpdateBtn" type="submit" [ngClass]="{'mt20': generalError === ''}"
                  [disabled]="completeAccountForm.invalid || loadingForm"
                  class="btn primary-btn" (click)="onCompleteAccountSubmit()">Submit
            <i *ngIf="loadingForm" class="spin medium"></i>
          </button>
          <p class="mt30">
            <a (click)="inviteMs()">No thanks, I’ll do it later</a>
          </p>
        </div>
      </div>
      <img *ngIf="pixelRequest" height="1" width="1" style="border-style:none;" alt=""
        src="//insight.adsrvr.org/track/pxl/?adv=875l4a9&ct=0:g2o7ljt&fmt=3" />
    </div>
  </div>
</ng-template>
