<div class="camii"></div>
<ng-template #customLicenseRequestModal>
  <button type="button" class="close license-close-icon" (click)="closeModal()">
    <span class="sr-only">Close</span>
    <span class="ss-icon-close"></span>
  </button>
  <div class="row custom-license">
    <div
      class="col-md-6 info-container mobile-hidden"
      [ngClass]="domainName === 'sostereo' ? 'col-md-6' : 'section-hidden'"
    >
      <h1 class="color-dark text-center mt0 pb10">Discover the SoStereo <br />advantage</h1>
      <div class="team-pics text-center pb20">
        <img src="./assets/images/about_us/team-pics/beto_azout.webp" alt="Beto Azut" />
        <img
          class="pic2 flip-img-h"
          src="./assets/images/about_us/team-pics/lindsey_camerota.webp"
          alt="Lindsey Camerota"
        />
        <img
          class="pic3"
          src="./assets/images/about_us/team-pics/martin_gutierrez.webp"
          alt="Martin Gutierrez"
        />
      </div>
      <p class="color-dark">
        Working with the top agencies and Fortune500 Brands for almost 10-years!
        <br />
        <br />
        Submit your license request and our Executive Producers will contact you within hours to
        help you with your needs.
        <br />
        <br />
        All of our music is pre-cleared and one-stop for fast, seamless, and affordable licensing.
      </p>
      <h3 class="color-dark mt30 mb0 fw600">A preferred Minority-Owned Vendor for:</h3>
      <div class="logos-list mt10">
        <img src="./assets/images/license_logos/wpp.webp" alt="WPP" />
        <img src="./assets/images/license_logos/publicis-small.webp" alt="Publicis" />
        <img src="./assets/images/license_logos/omnicom-small.webp" alt="Omnicom" />
        <img src="./assets/images/license_logos/ipg.webp" alt="IPG" />
        <img
          src="./assets/images/license_logos/stagwell-logo-small.webp"
          alt="Stagwell Partners"
          style="padding: 12px"
        />
      </div>
    </div>
    <div [ngClass]="domainName === 'sostereo' ? 'col-md-6' : 'col-md-12'">
      <div
        class="modal-body"
        data-cy="custom-modal-license"
        *ngIf="!requestSuccess"
        [@inOutAnimation]
      >
        <div class="row ml0 mr0">
          <div class="col-md-12">
            <h2 class="modal-title" style="display: inline">Licensing Terms</h2>
            <div
              *ngIf="
                isAllowed(['*', 'ListTiers'], []) &&
                !licenseAssignation?.initOwnerUid &&
                domainName === 'sostereo' &&
                !transactionComplete
              "
              class="ml20"
              style="display: inline; position: relative; bottom: 3px"
            >
              <a (click)="chooseUserUID()" class="assignation-link">Admin License assignation</a>
              <!-- <button class="btn primary-btn sm-btn" (click)="chooseUserUID()">Admin License assignation</button> -->
            </div>
            <div *ngIf="licenseAssignation?.initOwnerUid" class="mt20 info-alert">
              <p style="font-size: 16px">
                This license will be assigned to
                <span class="fw500">{{ licenseAssignation.ownerName }}</span>
                <span *ngIf="licenseAssignation?.initGroups?.length > 0">
                  in the company <span class="fw500">{{ licenseAssignation.initGroups[0] }}</span>
                </span>
              </p>
            </div>
            <div *ngIf="track" class="mt10 mb20">
              <b>Song: </b> {{ track.name }} ({{ track.songVersion || track.version }}) by
              {{ track?.artists[0]?.name || track?.artists[0]?.artistName }}
              <br />
              <span *ngIf="track.restriction && track.restriction !== ''">
                <br />
                <b>Artist's restrictions: </b> {{ track.restriction }}
              </span>
            </div>
            <div *ngIf="!track" class="mt10 mb20">
              <b>Project: </b> {{ transactionComplete?.project }} <br />
              <b>Company: </b> {{ transactionComplete?.company }}
              <br />
            </div>
            <p class="mt10 mb20" *ngIf="!transactionInputFactors">
              Please complete the form to request a license for this song.
            </p>
          </div>
        </div>
        <div class="row ml0 mr0">
          <div class="col-md-12">
            <form
              [formGroup]="customLicenseRequestForm"
              class="mt-0"
              (keydown.enter)="$event.preventDefault()"
            >
              <div class="row ml0 mr0">
                <div class="form-group col-md-12 pl0 pr0">
                  <label for="customLicenseRequestFormCategory">Category *</label>
                  <i
                    class="ss-icon-info ml5"
                    placement="right"
                    tooltip="What type of project are you working on? Examples include: Advertisement, TV Show, Major Studio Film, etc."
                  ></i>
                  <app-tags-input-dropdown
                    [options]="categories"
                    [currentSelections]="draftFields['category'] || null"
                    [placeholder]="'e.g. TV Show'"
                    [uniqueOption]="true"
                    [preventValues]="[';']"
                    (tagAdded)="addTag($event, 'category')"
                    (tagRemoved)="removeTag($event, 'category')"
                  ></app-tags-input-dropdown>
                  <div
                    *ngIf="
                      customLicenseRequestForm.controls['category'].touched &&
                      customLicenseRequestForm.controls['category'].errors
                    "
                  >
                    <app-inline-errors [errors]="['This field is required']"></app-inline-errors>
                  </div>
                </div>
              </div>
              <div class="row ml0 mr0">
                <div class="form-group col-md-8 pl0 pr0">
                  <label for="customLicenseRequestFormMedia">Media *</label>
                  <i
                    class="ss-icon-info ml5"
                    placement="right"
                    tooltip="On what medium will this project be publicized? Examples include:
                  Internet, Social Media, Broadcast TV, Radio, Film, etc."
                  ></i>
                  <app-tags-input-dropdown
                    [options]="medias"
                    [currentSelections]="draftFields['media'] || null"
                    [placeholder]="'e.g. Online digital + social'"
                    [preventValues]="[';']"
                    (tagAdded)="addTag($event, 'media')"
                    (tagRemoved)="removeTag($event, 'media')"
                  ></app-tags-input-dropdown>
                  <div
                    *ngIf="
                      customLicenseRequestForm.controls['media'].touched &&
                      customLicenseRequestForm.controls['media'].errors
                    "
                  >
                    <app-inline-errors [errors]="['This field is required']"></app-inline-errors>
                  </div>
                </div>
                <div class="col-md-offset-1 col-md-3 pl0 pr0 pb15" style="text-align: right">
                  <label class="mb5 pr10 col-xs-12 pl0">Paid Media</label>
                  <label class="switch">
                    <input type="checkbox" formControlName="paidMedia" />
                    <span class="slider"></span>
                  </label>
                </div>
              </div>
              <div class="row ml0 mr0" *ngIf="mediaOptions.length > 0">
                <div class="form-group col-md-12 pl0 pr0 mb20">
                  <label for="customLicenseRequestFormTerm">Term *</label>
                  <i
                    class="ss-icon-info ml5"
                    placement="right"
                    tooltip="How long will this project be live to the public?"
                  ></i>
                  <div
                    class="row mt5 mb5"
                    *ngFor="let media of mediaOptions; let i = index"
                    style="display: flex"
                  >
                    <div class="col-md-4">
                      <div class="vertical-center">{{ media.name }}</div>
                    </div>
                    <div class="col-md-8">
                      <app-tags-input-dropdown
                        [options]="terms"
                        [currentSelections]="
                          draftFields['term']?.length > 0 ? [draftFields['term'][i]] : null
                        "
                        [placeholder]="'e.g. 3 months'"
                        [uniqueOption]="true"
                        [preventValues]="[';']"
                        (tagAdded)="addTag($event, 'term', i)"
                        (tagRemoved)="removeTag($event, 'term', i)"
                      ></app-tags-input-dropdown>
                    </div>
                  </div>
                  <div
                    *ngIf="
                      customLicenseRequestForm.controls['term'].touched &&
                      customLicenseRequestForm.controls['term'].errors
                    "
                  >
                    <app-inline-errors
                      [errors]="['Term is required. Please ensure all fields are filled']"
                    ></app-inline-errors>
                  </div>
                </div>
              </div>
              <div class="row ml0 mr0 mb20" *ngIf="mediaOptions.length > 0">
                <div class="form-group col-md-12 pl0 pr0">
                  <label for="customLicenseRequestFormTerritory">Territory *</label>
                  <i
                    class="ss-icon-info ml5"
                    placement="right"
                    tooltip="In what territories will this project be publicized."
                  ></i>
                </div>
                <div
                  class="row pt5 pb5"
                  *ngFor="let media of mediaOptions; let i = index"
                  style="display: flex"
                >
                  <div class="col-md-4">
                    <div class="vertical-center">
                      {{ media.name }}
                    </div>
                  </div>
                  <div class="col-md-8">
                    <app-tags-input-dropdown
                      [options]="territories"
                      [currentSelections]="
                        draftFields['territory']?.length > 0 ? [draftFields['territory'][i]] : null
                      "
                      [placeholder]="'e.g. USA'"
                      [uniqueOption]="true"
                      [preventValues]="[';']"
                      (tagAdded)="addTag($event, 'territory', i)"
                      (tagRemoved)="removeTag($event, 'territory', i)"
                    ></app-tags-input-dropdown>
                  </div>
                </div>
                <div
                  *ngIf="
                    customLicenseRequestForm.controls['territory'].touched &&
                    customLicenseRequestForm.controls['territory'].errors
                  "
                >
                  <app-inline-errors
                    [errors]="['Territory is required. Please ensure all fields are filled']"
                  ></app-inline-errors>
                </div>
              </div>
              <div class="row ml0 mr0">
                <div class="form-group col-md-6 pl0 pr0">
                  <label for="customLicenseRequestFormDuration">Duration *</label>
                  <i
                    class="ss-icon-info ml5"
                    placement="right"
                    tooltip="How long will the song be playing in the project?"
                  ></i>
                  <input
                    data-hj-allow
                    type="text"
                    formControlName="duration"
                    class="form-control"
                    id="customLicenseRequestFormDuration"
                    placeholder="e.g. 30s, 15s"
                  />
                  <div
                    *ngIf="
                      customLicenseRequestForm.controls['duration'].touched &&
                      customLicenseRequestForm.controls['duration'].errors
                    "
                  >
                    <app-inline-errors [errors]="['this field is required']"></app-inline-errors>
                  </div>
                </div>
                <div class="form-group col-md-offset-1 col-md-5 pl0 pr0">
                  <label for="customLicenseRequestFormSpots"># of unique Spots *</label>
                  <input
                    data-hj-allow
                    type="text"
                    formControlName="spots"
                    class="form-control"
                    id="customLicenseRequestFormSpots"
                    placeholder="Enter spots"
                  />
                  <div
                    *ngIf="
                      customLicenseRequestForm.controls['spots'].touched &&
                      customLicenseRequestForm.controls['spots'].errors
                    "
                  >
                    <app-inline-errors [errors]="['this field is required']"></app-inline-errors>
                  </div>
                </div>
              </div>
              <div
                class="row ml0 mr0"
                *ngIf="
                  (draftFields?.category?.length > 0 &&
                    (draftFields?.category[0]?.toLowerCase()?.includes('film') ||
                      draftFields?.category[0]?.toLowerCase()?.includes('game'))) ||
                  customLicenseRequestForm
                    ?.get('category')
                    ?.value?.name?.toLowerCase()
                    ?.includes('film') ||
                  customLicenseRequestForm
                    ?.get('category')
                    ?.value?.name?.toLowerCase()
                    ?.includes('game')
                "
              >
                <div class="form-group col-md-12 pl0 pr0">
                  <label for="customLicenseRequestFormUseType">Use Type (Optional)</label>
                  <i
                    class="ss-icon-info ml5"
                    placement="right"
                    tooltip="How will the song be used in this project? Examples include:
                     Background Vocal, Background Instrumental, Visual, Vocal, Theme, Closing Credits, etc."
                  ></i>
                  <input
                    data-hj-allow
                    type="text"
                    formControlName="typeOfUse"
                    class="form-control"
                    id="customLicenseRequestFormUseType"
                    placeholder="Enter the use type"
                  />
                  <div
                    *ngIf="
                      customLicenseRequestForm.controls['typeOfUse'].touched &&
                      customLicenseRequestForm.controls['typeOfUse'].errors
                    "
                  >
                    <app-inline-errors [errors]="['this field is required']"></app-inline-errors>
                  </div>
                </div>
              </div>

              <div *ngFor="let alert of alerts">
                <alert
                  [type]="alert.type"
                  [dismissOnTimeout]="alert.timeout"
                  (onClosed)="onAlertClosed(alert)"
                  >{{ alert.message }}</alert
                >
              </div>
              <div class="row mt10 mb10 mr0" [ngClass]="{ 'pull-right': transactionInputFactors }">
                <div class="col-xs-12 mb10">
                  <!-- <a
                    *ngIf="!transactionInputFactors"
                    class="btn secondary-link mb0 pull-left mt5 pl0"
                    (click)="openRegularProcess()"
                  >
                    Show Normal Process
                  </a> -->
                  <button
                    class="mr10 mb0 btn outline-primary-btn mb15 md-btn"
                    *ngIf="!track"
                    (click)="updateData()"
                    [disabled]="loadingRequest || invalidPrice"
                  >
                    Save changes
                    <i class="spin medium" *ngIf="editSpinActive"></i>
                  </button>
                </div>
                <div class="col-xs-12 pl0 pr0" style="text-align: right" *ngIf="!editOnly">
                  <button
                    (click)="onCustomLicenseRequestSubmit(false)"
                    class="btn outline-primary-btn md-btn mb0 mr10 xs-mb10"
                    *ngIf="track"
                  >
                    Add
                  </button>
                  <button
                    type="submit"
                    class="btn primary-btn mb0 md-btn"
                    (click)="onCustomLicenseRequestSubmit(true)"
                  >
                    {{ transactionAction ? transactionAction.label : 'Add & Continue to Request' }}
                    <i class="spin medium" *ngIf="submitSpinActive"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="modal-body" *ngIf="false" [@inOutAnimation]>
        <div class="row text-center">
          <div class="col-md-12">
            <h4 class="modal-title text-center">
              <span style="font-size: 70px" class="glyphicon glyphicon-ok-circle font-20x"></span>
            </h4>
            <h2>Licence Request Submitted</h2>
          </div>
        </div>
        <div class="row text-center">
          <div class="col-md-12">
            <p>
              An employee will contact you as soon as possible to define the custom contract for you
              license request.
            </p>
            <br /><br />
          </div>
        </div>
        <div class="row text-center">
          <div class="col-md-12">
            <button
              id="discoverBtn"
              type="submit"
              class="btn primary-btn mt20 mb20 md-btn"
              (click)="closeModal()"
            >
              Continue Searching
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<app-license-assignation-modal
  (sendAssignationData)="getAssignationData($event)"
  #licenseAssignationModalComponent
>
</app-license-assignation-modal>
