<ng-template #songRightholdersInfoModal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <button type="button" class="close" (click)="hideModal()">
            <span class="sr-only">Close</span>
            <span class="ss-icon-close"></span>
          </button>
        </div>
      </div>
      <div>
        <div class="artist-img-container" *ngIf="dataInfo.artistImg">
          <img
            class="img-circle ml20 mr20"
            src="{{ dataInfo.artistImg }}"
            onerror="this.src='./assets/images/placeholder.jpg';"
            alt="Image Profile"
          />
        </div>
        <div>
          <h2 class="mb0">
            {{ dataInfo.artist?.name || dataInfo.artist?.artistName || dataInfo.artist }}
          </h2>
          <p>{{ dataInfo.locationString }}</p>
          <br />
        </div>
      </div>
      <div
        *ngIf="dataInfo.songCopyright && dataInfo.songCopyright !== ''"
        class="mt40 mb20 ml20 mr20"
      >
        <h6>Copyright:</h6>
        <p>{{ dataInfo.songCopyright }}</p>
      </div>
      <div
        *ngIf="
          dataInfo.copyrightWarning?.length > 0 &&
          (dataInfo.acrCloudPayload.coverSongs.length > 0 ||
            dataInfo.acrCloudPayload.music.length > 0)
        "
        class="pt5 ml20 mr20 mb20"
      >
        <span
          class="material-icons color-primary pull-left mr5"
          style="position: relative; top: 5px"
          [attr.data-icon]="'warning'"
        ></span>
        <h6>Copyright Warnings:</h6>
        @for (warning of dataInfo.copyrightWarning; let i = $index; track i) {
        <h6 class="mt20">{{ warning === 'cover' ? 'Possible Cover' : 'Major Label' }}:</h6>
        @for (song of warning === 'cover' ? dataInfo.acrCloudPayload.coverSongs :
        dataInfo.acrCloudPayload.music; track $index) {
        <div class="songs-wrapper mb10">
          <p *ngIf="song?.title"><span>Song: </span>{{ song.title }}</p>
          <p *ngIf="song?.label"><span>Label: </span> {{ song.label }}</p>
          <p *ngIf="song?.artists"><span>Artists: </span> {{ song.artists }}</p>
          <p *ngIf="song?.duration && song?.beginOffset && song?.endOffset" class="time-fields">
            <span>Match: </span>
            {{
              (song.beginOffset | msToTimeFormat) +
                ' - ' +
                (song.endOffset | msToTimeFormat) +
                ' (' +
                (song.duration | msToTimeFormat) +
                ' mins)'
            }}
          </p>
        </div>
        } }
      </div>
      <div
        *ngIf="
          dataInfo?.songRestrictions?.length > 0 ||
          (dataInfo.songRestriction && dataInfo.songRestriction !== '')
        "
        class="pt5 mb20 ml20 mr20"
      >
        <h6>Song Restrictions:</h6>
        <ul class="pl20" *ngIf="dataInfo.songRestrictions?.length > 0">
          <li *ngFor="let restriction of dataInfo.songRestrictions">
            <p class="fw500">{{ restriction.name }}</p>
            <p *ngIf="restriction.expirationDate">
              Restricted until: {{ restriction.expirationDate | truncate : [10, ''] }}
            </p>
            <p *ngIf="restriction.details">Details: {{ restriction.details }}</p>
          </li>
        </ul>
        <p *ngIf="dataInfo.songRestriction && dataInfo.songRestriction !== ''">
          {{ dataInfo.songRestriction }}
        </p>
      </div>
      <div *ngIf="dataInfo.internalManager" class="pt5 mb20 ml20 mr20">
        <span
          class="material-icons color-primary pull-left mr5"
          style="position: relative; top: 5px"
          [attr.data-icon]="'support_agent'"
        ></span>
        <h6>Internal manager:</h6>
        <p>{{ dataInfo.internalManager.email || dataInfo.internalManager }}</p>
      </div>
    </div>
  </div>
</ng-template>
