import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  Output,
  EventEmitter,
  Renderer2,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ClipboardService } from 'ngx-clipboard';
import { finalize } from 'rxjs/operators';
import { AuthenticationService } from 'sostereo-services';

@Component({
  selector: 'app-magic-link-modal',
  templateUrl: './magic-link-modal.component.html',
  styleUrls: ['./magic-link-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MagicLinkModalComponent implements OnInit {
  @ViewChild('magiclinkModal', { static: true }) modalTemplate: TemplateRef<any>;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() success = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() error = new EventEmitter();
  public modalRef: BsModalRef;
  magicLinkForm: FormGroup;
  public loading: boolean = false;
  public urlMagicLink: string = null;
  public copied = false;

  constructor(
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private clipBoardService: ClipboardService,
    private renderer: Renderer2,
  ) {
    this.createForm();
  }

  private createForm() {
    this.magicLinkForm = this.formBuilder.group({
      email: new FormControl({ value: '', disabled: true }, Validators.required),
      amount: new FormControl(30, [Validators.min(1), Validators.required]),
      unit: new FormControl('minutes', Validators.required),
      isMagicTokenReusable: new FormControl(true),
    });
  }

  ngOnInit() {}

  getMagicToken() {
    this.magicLinkForm.markAllAsTouched();
    if (this.magicLinkForm.valid) {
      this.loading = true;
      const token = {
        email: this.magicLinkForm.controls.email.value,
        amount: this.magicLinkForm.controls.amount.value,
        unit: this.magicLinkForm.controls.unit.value,
        isMagicTokenReusable: this.magicLinkForm.controls.isMagicTokenReusable.value,
      };
      this.authenticationService
        .getMagicToken(token)
        .pipe(finalize(() => (this.loading = false)))
        .subscribe(
          (success) => {
            this.urlMagicLink = `${window.location.host}/?magicToken=${success.data?.magicToken}`;
          },
          (httpError) => {
            console.error(httpError.error.message);
          },
        );
    }
  }

  clearForm() {
    this.urlMagicLink = null;
    this.magicLinkForm.setValue({
      email: '',
      amount: 30,
      unit: 'minutes',
      isMagicTokenReusable: true,
    });
  }

  public showModal(user: any) {
    this.clearForm();
    this.magicLinkForm.controls.email.setValue(user?.email);
    this.modalRef = this.modalService.show(this.modalTemplate, {
      class: 'modal-md',
      ignoreBackdropClick: true,
    });
  }

  public hideModal() {
    this.modalRef.hide();
  }

  public onKeydown(event) {
    event.stopPropagation();
  }

  public copyUrlToClipboard() {
    this.copied = true;
    const btn = document.getElementById('linkUrlCopyBtn');
    this.highlightButton(btn);
    setTimeout(() => {
      this.copied = false;
    }, 3000);
    this.clipBoardService.copy(this.urlMagicLink);
  }

  private highlightButton(btn) {
    this.renderer.addClass(btn, 'highlight');
    setTimeout(() => {
      this.renderer.addClass(btn, 'highlight-fade');
    }, 2000);
    setTimeout(() => {
      this.renderer.removeClass(btn, 'highlight');
      this.renderer.removeClass(btn, 'highlight-fade');
    }, 4000);
  }
}
