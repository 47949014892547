// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --qa` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import * as packageJson from 'package.json';
import { getWindow } from 'ssr-window';
const window: any = getWindow();
const version: string = packageJson?.version;
const buildNumber: string = packageJson?.buildNumber;
let subdomain =
  window.location.hostname === '0.0.0.0' ? null : window.location.hostname.split('.')[0];

export const environment = {
  production: false,
  env: 'dev-sostereo',
  name: 'sostereo',
  apiHost: 'https://api-qa.sostereo.com',
  apiPath: 'api/v1',
  corePath: 'core',
  apiVersion: 'v1',
  appUrl: `https://${subdomain && subdomain !== 'dev' ? subdomain + '.' : ''}dev.sostereo.com`,
  redirectAppUrl: `https://dev.sostereo.com`,
  waveService: 'waves-sostereo.sostereo.com',
  // cubeConfig: { // QA
  //  token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MzQwNzAyNjh9.7-quHpX6lKtcGMLf6bk6XNPB_aZE4ijJSdi-hEMNuOw',
  //  options: { apiUrl: 'https://analytics-stg.stereospaces.com/cubejs-api/v1' }
  // },
  // PRODUCTION
  cubeConfig: {
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MzQwNzA4MjB9.ayFY-8wyo5fIo4OZMGDfmH0pmcQkS_vEMqpA1iv7g-M',
    options: { apiUrl: 'https://analytics.stereospaces.com/cubejs-api/v1' },
  },
  collectRequest: 'https://ekg98w31fh.execute-api.us-west-2.amazonaws.com/stg',
  websocketConfig: {
    url: 'https://api-qa.sostereo.com',
    options: {
      path: '/stereospaces/v1/sockets',
      transports: ['websocket'],
    },
  },
  //recaptchaSiteKey: '6LcS_M4aAAAAALUP-QhMDXwayexBRQrMJmFl8THI', // sostereo local v2 checkbox
  //recaptchaInvisibleSiteKey: '6Lfx94QeAAAAAIQZjAoTbEnxEBGbZ1KbzIxQzJj7',
  recaptchaSiteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI', // sostereo local v2 checkbox
  recaptchaInvisibleSiteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  subdomain: subdomain,
  buildNumber: (buildNumber !== '' ? buildNumber + '-' : '') + 'dev',
  appVersion: version,
  sentryDsn: 'https://cd748108fa804657a2f241dcaf6121f5@o80225.ingest.sentry.io/6293960',
  youtubeRef: 'https://sostereomusic--sostereo-external-references-stg-fastapi-app.modal.run/',
  googleAdsId: 'AW-11071716590',
};
