<ng-template #licenseAssignationModalComponent>
  <div class="modal-content">
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <button type="button" class="close" (click)="hideModal()">
            <span class="sr-only">Close</span>
            <span class="ss-icon-close"></span>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-offset-1 col-md-8">
          <h4 class="modal-title" id="myModalLabel">License assignation</h4>
        </div>
      </div>
      <br/>
      <div class="row">
        <div class="col-md-offset-1 col-md-10">
          <p class="mb20">
            Select the user you wish to assign the license to and specify the desired status for the assignment
          </p>
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div>
              <label for="owner">License assigned to:</label>
              <input
                data-hj-allow
                class="form-control"
                placeholder="Search user"
                [typeahead]="suggestions$"
                [typeaheadAsync]="true"
                (typeaheadOnSelect)="onUserSelected($event)"
                (keyup)="onUserTyped($event.target.value)"
                formControlName="owner"
                [typeaheadOptionField]="'email'"
                [typeaheadMinLength]="0"
                [typeaheadWaitMs]="300"
                [typeaheadOptionsLimit]="4"
              />
              <div *ngIf="form.get('owner').invalid && form.get('owner').touched">
                <p style="color: red;">User is required</p>
              </div>
            </div>
            <div>
              <p class="mt10">
                If the user doesn't exist create one <a [routerLink]="'/iam/list'" target="_blank">here</a>
              </p>
            </div>
            <div class="mt20">
              <label for="status">Select status:</label>
              <br>
              <select formControlName="status" id="status">
                <option value="" disabled>Select an option</option>
                <option *ngFor="let option of licenseStatus" [value]="option.slug">{{ option.name }}</option>
              </select>
              <div *ngIf="form.get('status').invalid && form.get('status').touched">
                <p style="color: red;">Status is required</p>
              </div>
            </div>
            <div class="mt20">
              <button [disabled]="loadingForm || form.invalid" type="submit" class="btn primary-btn">Accept
                <i *ngIf="loadingForm" class="spin medium"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>
