<ng-template #customServicesModal>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <button type="button" class="close" (click)="hideModal()"><span class="sr-only">Close</span><span
            class="ss-icon-close"></span></button>
        <div class="clearfix mb40 text-center">
          <h2>Custom Music Service</h2>
          <p>Tell us about your project and how you'd like us to help! From music strategy, to original music and
            clearances, we've got your back.</p>
        </div>
      </div>
    </div>
    <form [formGroup]="customServicesForm">
      <div class="form-group">
        <label for="inputName">Name*</label>
        <input data-hj-allow  type="text" formControlName="name" class="form-control" id="inputName">
        <app-inline-errors [errors]="['this field is required']"
                           *ngIf="customServicesForm.controls['name'].errors && !customServicesForm.controls['name'].pristine">
                          </app-inline-errors>
      </div>
      <div class="form-group">
        <label for="inputCompanyName">Company*</label>
        <input data-hj-allow  type="text" formControlName="companyName" class="form-control" id="inputCompanyName">
        <app-inline-errors [errors]="['this field is required']"
                           *ngIf="customServicesForm.controls['companyName'].errors &&
                            !customServicesForm.controls['companyName'].pristine"></app-inline-errors>
      </div>
      <div class="form-group">
        <label for="inputPhone">Phone*</label>
        <input data-hj-allow  type="text" formControlName="phone" class="form-control" id="inputPhone">
        <app-inline-errors [errors]="['this field is required']"
                           *ngIf="customServicesForm.controls['phone'].errors && !customServicesForm.controls['phone'].pristine">
                          </app-inline-errors>
      </div>
      <div class="form-group">
        <label for="inputEmail">Email*</label>
        <input data-hj-allow  type="email" formControlName="email" class="form-control" id="inputEmail">
        <app-inline-errors [errors]="['this field is required, must be a valid email']"
                           *ngIf="customServicesForm.controls['email'].errors && !customServicesForm.controls['email'].pristine">
                          </app-inline-errors>
      </div>
      <div class="form-group">
        <label for="inputDescription">Tell us about your project</label>
        <textarea type="text" formControlName="description" class="form-control" id="inputDescription"
                  rows="4"></textarea>
        <app-inline-errors [errors]="['this field is required']"
                           *ngIf="customServicesForm.controls['description'].errors &&
                            !customServicesForm.controls['description'].pristine"></app-inline-errors>
      </div>
    </form>
    <div class="clearfix">
      <div class="form-group col-sm-12">
        <label class="mt30" *ngIf="disableBtn">Sending your request....</label>
        <button type="submit" class="btn primary-btn col-sm-4 mt20 pull-right" (click)="sendEmail()"
                [disabled]="disableBtn || customServicesForm.invalid">Submit
        </button>
      </div>
      <div class="form-group mt15 pl0 pr0 col-sm-12 col-xs-12" style="display: inline-block;">
        <div class="alert alert-success alert-dismissable" [hidden]="!requestSuccess">
          <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
          <strong>Your request has been sent!</strong>
        </div>
        <div class="alert alert-danger" [hidden]="!requestError">
          <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
          <strong>We couln't send your request, please try again!</strong>
        </div>
      </div>
    </div>
  </div>
</ng-template>
