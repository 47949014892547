<ng-template #addToPlaylistModal>
  <div class="modal-content" id="addToPlaylistModal">
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <button type="button" class="close" (click)="hideModal()">
            <span class="sr-only">Close</span>
            <span class="ss-icon-close"></span>
          </button>
          <h4 class="modal-title">Add to Playlist</h4>
        </div>
      </div>
      <div class="row text-left mt0">
        <div class="col-md-12">
          <div *ngIf="!playlistDropDown">
            <br />
            <p>You have no playlists at the moment. Create one</p>
            <br />
            <br />
            <a
              class="btn secondary-link pull-left add-to-pl-btn btn-image mb0 text-left pl0"
              (click)="showCreatePlaylistModal()"
              [appAllowedTo]="'CreatePlaylist'"
            >
              <span class="material-icons-outlined" [attr.data-icon]="'add'"></span>New Playlist
            </a>
          </div>
          <div *ngIf="playlistDropDown">
            <div class="form-group mt20">
              <b
                >{{ selectedPlaylist?.itemName ? 'Selected Playlist:' : 'Select a playlist' }}
                <span class="secondary-color">{{ selectedPlaylist?.itemName }}</span></b
              >
              <br />
              <br />
              <label>Your Playlists:</label>
              <input
                data-hj-allow
                class="form-control"
                type="text"
                placeholder="Search playlist"
                (keyup)="onSearch($event)"
                [(ngModel)]="playlistTyped"
                (click)="
                  dropDownOpen = playerService.isSmallSize ? !dropDownOpen : true;
                  openOptions($event)
                "
                (clickOutside)="clickOutside($event)"
              />
              <div *ngIf="dropDownOpen" class="playlist-wrapper">
                <p *ngIf="dropdownLoading" class="info-text">
                  Loading data <i class="spin medium loader-margin ml5"></i>
                </p>
                <p *ngIf="noResults && !dropdownLoading" class="info-text">
                  {{
                    emptyPlaylist
                      ? "You don't have any playlists yet, please create one"
                      : 'There are no results'
                  }}
                </p>
                <ng-container *ngFor="let category of playlistDropDown | keyvalue">
                  <div
                    *ngIf="category.value.items && category.value.items.length > 0"
                    id="playlistsOptionsContainer"
                  >
                    <p class="category-title">
                      {{ category.value.name }}
                    </p>
                    <div>
                      <p
                        *ngFor="let item of category.value.items"
                        (click)="
                          selectedPlaylist = item;
                          autoSelectedPlaylist = false;
                          dropDownOpen = false
                        "
                        class="mb0"
                        [ngClass]="{
                          'secondary-color': item.id === 'addPage',
                          fw600: item.id === 'addPage',
                          'selected-playlist': item.id === selectedPlaylist?.id
                        }"
                      >
                        {{ item.itemName }}
                      </p>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="row mt20">
              <div class="col-md-12 text-right">
                <a
                  class="btn secondary-link pull-left add-to-pl-btn btn-image mb0 text-left pl0 pt0"
                  (click)="showCreatePlaylistModal()"
                  [appAllowedTo]="'CreatePlaylist'"
                >
                  <span class="material-icons-outlined" [attr.data-icon]="'add'"></span>New Playlist
                </a>
                <button
                  id="addSongToPlaylistBtn"
                  class="btn primary-btn pull-right mb0"
                  (click)="addSongToPlaylist()"
                  [disabled]="!selectedPlaylist?.id || loadingRequest"
                >
                  Add
                  <i class="spin medium loader-margin" *ngIf="loadingRequest"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<app-create-playlist-modal
  #createPlaylistModal
  (success)="onCreatePlaylistSuccess($event)"
  (error)="onCreatePlaylistError($event)"
></app-create-playlist-modal>
<app-song-status-modal
  #songStatusModalComponent
  [tracks]="this.tracks"
  (restrictionsAgreed)="showModalPlaylist()"
>
</app-song-status-modal>
