import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthenticationService } from 'sostereo-services';
declare var PubNub;

@Injectable({
  providedIn: 'root',
})
export class PubnubGeneralService {
  isBrowser: boolean;
  private channels = [];
  private authKeys = [];
  private pubNub;
  public pubnubEvent = new Subject<any>();

  constructor(private authenticationService: AuthenticationService) {}

  initPubNub(initConfig) {
    this.channels = [];
    this.authKeys = [];
    const currentUser = this.authenticationService.getCurrentUser()?.uid;
    this.pubNub = new PubNub({
      publishKey: initConfig.pubKey,
      subscribeKey: initConfig.subKey,
      restore: true,
      userId: currentUser || 'SoStereo',
    });
    this.pubNub.addListener({
      message: (message) => {
        this.pubnubEvent.next(message);
      },
    });
  }

  addChannel(channelId: string, authKey: string) {
    this.pubNub.setAuthKey(authKey);
    this.channels.push(channelId);
    this.authKeys.push(authKey);
    this.pubNub.subscribe({
      channels: this.channels,
      authKeys: this.authKeys,
    });
  }

  unsubscribeToChannel(channel) {
    this.pubNub.unsubscribe({
      channels: [channel],
    });
  }

  removeAllListeners() {
    this.pubNub.removeAllListeners();
    this.pubNub.unsubscribeAll();
  }
}
