import { Injectable } from '@angular/core';
import { SearchTagsService } from 'src/app/angular/shared/services/search-tags.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PlaylistService {
  public tagOptions = [
    'genre-',
    'mood-',
    'rhythm-influence-',
    'rhythm-texture-',
    'harmony-influence-',
    'harmony-texture-',
    'bpm-',
    'productionstyle-',
    'instrumentation-',
  ];

  public plTypingOptions = [
    { label: 'All', id: 'all', selected: false, query: ['name^3', 'tagsText', 'description'] },
    { label: 'Name', id: 'name', selected: true, query: ['name^3'] },
    { label: 'Tags', id: 'tags', selected: false, query: ['tagsText'] },
    { label: 'Description', id: 'description', selected: false, query: ['description'] },
  ];

  public editorialPLNumberUpdate = new BehaviorSubject<number>(-1);
  public updatePlaylist = new BehaviorSubject<object>({});
  public playlistMenuContainer = new BehaviorSubject<object>({});
  public playlistMenuCollapsed = new BehaviorSubject<object>({});
  public songsSelected = new BehaviorSubject<any[]>([]);
  private LAST_PLAYLIST = 'SOSTEREO.lastPlaylist';

  constructor(public searchTagsService: SearchTagsService) {}

  getParams(
    page: number,
    editorial,
    currentUser,
    ignoreTags?,
    inputValue?,
    displayTeamPlaylist?,
    filterSong?,
    plTypingType?,
  ) {
    let params: any = {
      q: `${inputValue || '*'}~`,
      selection: {},
      tags: ignoreTags ? '' : this.getTags(),
      qLyrics: [],
      index: 0,
      limit: 30,
      types: ['playlists'],
      editorial: editorial,
      page,
      fields: ['createdAt', 'slug', 'thumbUrl', 'trackSoIds'],
      collaborative: false,
      playlistMatchFields: plTypingType || ['name^3', 'tagsText', 'description'],
    };

    if (plTypingType) {
      params = { ...params, playlistMatchFields: plTypingType };
    }

    if (filterSong) {
      params.trackSoIds = filterSong ? [filterSong.songFeaturedIn] : [];
    }

    if (!editorial && !displayTeamPlaylist) {
      params.ownerUid = currentUser.uid;
    }
    return params;
  }

  public getTags() {
    let tags = this.searchTagsService.selectedTags
      .filter(
        (t) =>
          t.type !== 'lyric' && this.tagOptions.some((tagOption) => t.slug?.includes(tagOption)),
      )
      .map((t) => (t.negative ? '! ' : '') + t.slug);
    return tags?.toString() || '';
  }

  getLastPlaylist() {
    const playlist = localStorage.getItem(this.LAST_PLAYLIST);
    return playlist ? JSON.parse(playlist) : null;
  }

  setLastPlaylist(playlist) {
    localStorage.setItem(this.LAST_PLAYLIST, JSON.stringify(playlist));
  }
}
