import { Component, ViewChild, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TrackingService } from 'src/app/angular/shared/services/tracking.service';
import { CommonService } from '../../../../shared/services/common.service';
import { SongsService } from 'sostereo-services';

@Component({
  selector: 'app-song-rightholders-info-modal',
  templateUrl: './song-rightholders-info-modal.component.html',
  styleUrls: ['./song-rightholders-info-modal.component.scss'],
})
export class SongRightholdersInfoModalComponent {
  public modalRef: BsModalRef;
  @ViewChild('songRightholdersInfoModal', { static: true }) modalsTemplate: TemplateRef<any>;
  public dataInfo: any = {};

  constructor(
    private modalService: BsModalService,
    private commonService: CommonService,
    private trackingService: TrackingService,
    private songsService: SongsService,
  ) {}

  public showModal(data) {
    this.songsService.get(data.songId, { fields: 'acrCloudPayload' }).subscribe({
      next: (res) => {
        this.dataInfo.copyrightWarning = res.data.copyrightWarning;
        this.dataInfo.acrCloudPayload = res.data.acrCloudPayload;
      },
    });
    this.trackingService.track(
      'Song rightholders modal opened',
      {
        action: 'Song rightholders modal opened',
        kind: 'Modal Open',
        artistData: data,
      },
      {
        event_action: 'Song rightholders modal opened',
        event_type: 'Modal Opened',
        event_value: data?.name,
      },
    );
    this.dataInfo = data;
    this.dataInfo.songRestrictions = this.commonService.mapRestrictionsToArray(
      this.dataInfo.songRestrictions,
    );
    this.dataInfo.locationString =
      this.dataInfo.artist !== undefined &&
      this.dataInfo.artist.hasOwnProperty('location') &&
      this.dataInfo.artist.location &&
      typeof this.dataInfo.artist.location === 'object'
        ? this.getLocationString(this.dataInfo.artist.location)
        : '';
    this.dataInfo.artistImg =
      this.dataInfo.artist !== undefined ? this.getArtistImage(this.dataInfo.artist) : '';
    this.modalRef = this.modalService.show(this.modalsTemplate, { class: 'modal-md' });
  }

  public hideModal() {
    this.modalRef.hide();
  }

  getLocationString(artistLocation) {
    if (
      artistLocation.hasOwnProperty('city') &&
      artistLocation?.city &&
      artistLocation.city !== ''
    ) {
      return (
        artistLocation.city +
        (artistLocation.hasOwnProperty('state') &&
        artistLocation.state &&
        artistLocation.state !== ''
          ? ', ' + artistLocation.state
          : artistLocation.hasOwnProperty('country') &&
            artistLocation.country &&
            artistLocation.country !== ''
          ? ', ' + artistLocation.country
          : '')
      );
    } else if (
      artistLocation.hasOwnProperty('state') &&
      artistLocation?.city &&
      artistLocation.city !== ''
    ) {
      return (
        artistLocation.city +
        (artistLocation.hasOwnProperty('country') &&
        artistLocation.country &&
        artistLocation.country !== ''
          ? ', ' + artistLocation.country
          : '')
      );
    } else if (
      artistLocation.hasOwnProperty('country') &&
      artistLocation?.country &&
      artistLocation.country !== ''
    ) {
      return artistLocation.country;
    } else {
      return '';
    }
  }

  // It first validates if the artist has a thumbUrl to use it if not validates if it
  // has backgroundImages if not it displays an image by default
  getArtistImage(artist) {
    return artist.profileImages &&
      artist.profileImages.thumbUrl &&
      artist.profileImages.thumbUrl !== '' &&
      artist.profileImages.thumbUrl !== '/images/placeholder.jpg'
      ? artist.profileImages.thumbUrl
      : artist.backgroundImages && artist.backgroundImages.length > 0
      ? artist.backgroundImages[Math.ceil(Math.random() * artist.backgroundImages.length) - 1]
      : './assets/images/placeholder.jpg';
  }
}
