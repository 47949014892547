import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TrackingService } from '../../../../shared/services/tracking.service';
import {
  AuthenticationService,
  IdentityService,
  PolicyService,
  UserService,
  GroupsService,
} from 'sostereo-services';
import { PoliciesService } from '../../../../shared/services/policies/policies.service';
import { CommonService } from '../../../../shared/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { reduce, remove, clone } from 'lodash-es';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-create-identity-modal',
  templateUrl: './create-identity-modal.component.html',
  styleUrls: ['./create-identity-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateIdentityModalComponent implements OnInit {
  /*
    This is used to make the query of the company to get the group 'policies' and validate
    if the new user can be granted with certain permissions
  */
  @Input() group;
  /*
    This is used when I don't need to know the group policies but only the current user scopes
  */
  @Input() groupSlug;
  @Output() identitySucess = new EventEmitter();
  @ViewChild('createIdentityModal', { static: true }) modalTemplate: TemplateRef<any>;

  public modalRef: BsModalRef;
  public identityForm: FormGroup;
  public loading = false;
  public serverError = '';
  public settings = {
    singleSelection: true,
    text: 'Select',
    enableSearchFilter: true,
    searchBy: ['itemName'],
    enableFilterSelectAll: false,
    searchPlaceholderText: 'Search',
    badgeShowLimit: 0,
    maxHeight: 250,
  };

  public permissionsSettings = {
    singleSelection: false,
    text: 'Select',
    enableSearchFilter: true,
    searchBy: ['itemName'],
    enableFilterSelectAll: false,
    searchPlaceholderText: 'Search',
    maxHeight: 250,
  };

  public companiesList = [];
  public currentUser = this.authenticationService.getCurrentUser();
  public permissionsList = this.policiesService.getPermissions();
  public warning = false;
  private labelGroupPolicy: any = [];

  constructor(
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private identityService: IdentityService,
    private toastr: ToastrService,
    private policyService: PolicyService,
    private trackingService: TrackingService,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private groupsService: GroupsService,
    private policiesService: PoliciesService,
    private commonService: CommonService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {}

  public showModal() {
    this.createForm();
    this.loading = false;
    this.modalRef = this.modalService.show(this.modalTemplate, {
      class: 'modal-md',
      ignoreBackdropClick: true,
    });
  }

  private createForm() {
    this.identityForm = this.formBuilder.group({
      email: new FormControl(null, [
        Validators.required,
        Validators.pattern(this.commonService.emailRegex),
      ]),
      firstName: new FormControl(null, Validators.required),
      lastName: new FormControl(null, Validators.required),
      groups: new FormControl(null),
      policies: new FormControl(null, Validators.required),
    });
    if (this.group) {
      this.identityForm.controls.groups.setValue([
        { id: this.group.slug, itemName: this.group.name, data: this.group },
      ]);
      this.onCompanySelect({ id: this.group.slug, itemName: this.group.name, data: this.group });
    } else if (this.groupSlug) {
      this.identityForm.controls.groups.setValue([
        { id: this.groupSlug, itemName: this.groupSlug, data: this.groupSlug },
      ]);
      this.permissionsList = this.policiesService.getPermissions(this.groupSlug);
    }
  }

  public onCompanySelect(group) {
    console.log('Company Select >>> ', group);
    this.permissionsList = this.policiesService.getPermissions(group.id);
    this.changeDetectorRef.detectChanges();
    this.warning = false;
    this.identityForm.controls.policies.setValue([]); // So if it changes of company the other policies that this company may not have don't remain in the user
    if (
      this.identityForm.value.groups[0].data.policies &&
      this.identityForm.value.groups[0].data.policies.length > 0
    ) {
      this.filterPermissionsByGroupPolicy(group.data);
    } else {
      this.warning = true;
    }
  }

  public onCompanyDeselect(group) {
    console.log(
      'Company Deselect >>> ',
      this.identityForm.value,
      group,
      this.identityForm.controls.policies.value,
    );
    this.warning = false;
    // Remove selected policies related to group if company is removed
    remove(this.identityForm.value.policies, (p: any) => Array.isArray(p.groups));
    this.identityForm.controls.policies.setValue(this.identityForm.value.policies);
    this.permissionsList = this.policiesService.getPermissions();
    this.identityForm.controls.groups.setValue(null);
  }

  private filterPermissionsByGroupPolicy(group) {
    this.permissionsList = this.policiesService.getPermissions(group.slug, null, group.policies);
    if (this.labelGroupPolicy.length === 0) {
      // This is to know if the group has a label/contractowner policy to later on add it the '-resources' group
      this.labelGroupPolicy = group.policies.filter((p) => {
        if (p.srn.includes(`policies:${group.slug}`) && !p.srn.includes('spaces')) {
          return p;
        }
      });
    }
  }

  public onPermissionSelect(permission) {
    if (permission.id === 'owner') {
      const bindedToOwnerFilter = this.permissionsList.filter((perm) => perm.bindedToOwner);
      bindedToOwnerFilter.push(permission);
      this.identityForm.controls.policies.setValue(bindedToOwnerFilter);
    }
  }

  public onPermissionDeselect(permission) {
    console.log(permission);
  }

  public onCompanyInputChange(input) {
    this.loadCompanies(new RegExp(input).toString());
  }

  loadCompanies(query?: string) {
    this.companiesList = [];
    const queryParams: any = {};
    if (query) {
      queryParams.name = query;
    }
    this.groupsService.query(queryParams).subscribe(
      (res) => {
        console.log('GET RES Company >>> ', res);
        this.companiesList = reduce(
          res.data,
          (acc, d) => {
            acc.push({
              id: d.slug,
              itemName: d.name,
              data: d,
            });
            return acc;
          },
          [],
        );
        this.changeDetectorRef.detectChanges();
      },
      (err) => {
        console.log('GET ERR >>> ', err);
        this.toastr.error(err?.error?.message, 'There was a problem retrieving the company');
        this.trackingService.track('Error getting Company', {
          error: err,
          user: this.authenticationService.getCurrentUser(),
        });
      },
    );
  }

  public createIdentity() {
    this.loading = true;
    this.serverError = '';
    const identity = clone(this.identityForm.value);
    const tenantId =
      identity.groups && identity.groups.length > 0 && identity.groups[0].data.tenantId
        ? identity.groups[0].data.tenantId.replace('Group-', '')
        : this.currentUser.tenantId;
    const identityPermissions = this.policiesService.getIdentityPermissions(
      identity.policies,
      tenantId,
      identity.groups ? identity.groups.map((gr) => gr.id) : [],
      this.labelGroupPolicy.length > 0,
    );
    identity.groups = identityPermissions.groups;
    identity.policies = identityPermissions.policies;
    console.log('Identity to sent ', identity);
    this.identityService.query({ email: identity.email }).subscribe(
      (data) => {
        if (data.paging.totalItems === 0) {
          this.userService
            .post(identity)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe(
              () => {
                this.identitySucess.emit();
                this.hideModal();
                this.toastr.success(
                  'The user ' + identity.firstName + ' ' + identity.lastName + ' has been created',
                  'Success',
                );
              },
              (userErr) => {
                console.log('User post Error >>> ', userErr);
                this.serverError = userErr.error ? userErr.error.message : userErr.message;
                this.trackingService.track('Error creating identity', {
                  email: identity?.email,
                  serverError: userErr?.error ? userErr?.error?.message : userErr?.message,
                  errorStatus: userErr?.statusText + ' - ' + userErr?.status,
                  groups: identity?.groups,
                  policies: identity?.policies,
                });
              },
            );
        } else {
          this.serverError = 'Email already exists';
          this.loading = false;
        }
      },
      (err) => {
        console.error('Error to create user >>>> ', err);
        this.loading = false;
        this.serverError = err.error ? err.error.message : err.message;
        this.trackingService.track('Query identity error', {
          email: identity?.email,
          serverError: err?.error ? err?.error?.message : err?.message,
          errorStatus: err?.statusText + ' - ' + err?.status,
          groups: identity?.groups,
          policies: identity?.policies,
        });
      },
    );
  }

  public hideModal() {
    this.identityForm.reset();
    this.modalRef.hide();
  }
}
