export function licenseRequestConfig() {
  return {
    types: [
      {
        id: 'ads',
        label: 'Commercials/Advertisement',
        selected: false,
        steps: [
          {
            slug: 'media',
            title: 'Media',
            description: 'In which type of media you will reproduce this song?',
            progress: 18,
            options: [
              {
                label: 'Social media videos (Unlimited edit & cutdowns)',
                selected: false,
              },
              {
                label: 'Internet/OLV commercials (Unlimited edit & cutdowns)',
                selected: false,
              },
              {
                label: 'Radio broadcast (Unlimited edit & cutdowns)',
                selected: false,
              },
              {
                label: 'Local TV or spanish language channels (Unlimited edit & cutdowns)',
                selected: false,
              },
              {
                label: 'All broadcast TV (Unlimited edit & cutdowns)',
                selected: false,
              },
              {
                label: 'All media (TVC, OLV, Radio, Industrial) (Unlimited edit & cutdowns)',
                selected: false,
              },
            ],
          },
          {
            slug: 'territory',
            title: 'Territory',
            description: 'Where will you broadcast this project?',
            progress: 36,
            options: [
              {
                label: 'Regional/Local USA',
                selected: false,
              },
              {
                label: 'Single country (Ex-USA/Canada)',
                selected: false,
              },
              {
                label: 'National USA (Including PR and Canada)',
                selected: false,
              },
              {
                label: 'Single continent (Outside of USA & North America)',
                selected: false,
              },
              {
                label: 'Worldwide',
                selected: false,
              },
            ],
          },
          {
            slug: 'term',
            title: 'Term',
            description: 'How long will this project be broadcast to the public?',
            progress: 54,
            options: [
              {
                label: '3 Months',
                selected: false,
              },
              {
                label: '6 Months',
                selected: false,
              },
              {
                label: '1 Year',
                selected: false,
              },
              {
                label: '2 Years',
                selected: false,
              },
              {
                label: 'Perpetuity',
                selected: false,
              },
            ],
          },
          {
            slug: 'duration',
            title: 'Duration',
            description: 'How long will the song be playing in the project?',
            progress: 72,
            options: [
              {
                label: '10 Seconds',
                selected: false,
              },
              {
                label: '15 Seconds',
                selected: false,
              },
              {
                label: '30 Seconds',
                selected: false,
              },
              {
                label: '1 Minute',
                selected: false,
              },
              {
                label: 'Entire song',
                selected: false,
              },
            ],
          },
          {
            slug: 'spots',
            title: 'Number of Spots',
            description: 'How many spots will the song have in the project?',
            type: 'text',
            progress: 90,
            options: [
              {
                label: '1',
                selected: false,
              },
              {
                label: '2',
                selected: false,
              },
              {
                label: '3',
                selected: false,
              },
              {
                label: '4',
                selected: false,
              },
              {
                label: 'More than 4',
                selected: false,
              },
            ],
          },
        ],
      },
      {
        id: 'tv',
        label: 'TV shows',
        selected: false,
        steps: [
          {
            slug: 'media',
            title: 'Media',
            description: 'Which type of medium will this project be broadcast in?',
            progress: 18,
            options: [
              {
                label: 'Online streaming',
                selected: false,
              },
              {
                label: 'Traditional TV',
                selected: false,
              },
              {
                label: 'All media',
                selected: false,
              },
            ],
          },
          {
            slug: 'typeOfUse',
            title: 'Type Of Use',
            description: 'Which type of use will you reproduce this song in?',
            progress: 36,
            options: [
              {
                label: 'In-scene',
                selected: false,
              },
              {
                label: 'Promo/Trailer',
                selected: false,
              },
              {
                label: 'Credits',
                selected: false,
              },
              {
                label: 'Theme song',
                selected: false,
              },
            ],
          },
          {
            slug: 'territory',
            title: 'Territory',
            description: 'Where will you broadcast this project?',
            progress: 54,
            options: [
              {
                label: 'Latam',
                selected: false,
              },
              {
                label: 'USA',
                selected: false,
              },
              {
                label: 'Worldwide',
                value: 1,
                selected: false,
              },
            ],
          },
          {
            slug: 'term',
            title: 'Term',
            description: 'How long will this project be broadcast to the public?',
            label: 'Term',
            progress: 72,
            options: [
              {
                label: '3 Months',
                selected: false,
              },
              {
                label: '6 Months',
                selected: false,
              },
              {
                label: '1 Year',
                selected: false,
              },
              {
                label: 'Up to 5 Years',
                selected: false,
              },
              {
                label: 'Perpetuity',
                selected: false,
              },
            ],
          },
          {
            slug: 'duration',
            title: 'Duration',
            description: 'How long will the song be playing in the project?',
            progress: 90,
            options: [
              {
                label: '15 Seconds',
                selected: false,
              },
              {
                label: '30 Seconds',
                selected: false,
              },
              {
                label: '1 Minute',
                selected: false,
              },
              {
                label: '2 Minutes',
                selected: false,
              },
              {
                label: 'Entire song',
                selected: false,
              },
            ],
          },
        ],
      },
      {
        id: 'films',
        label: 'Films',
        selected: false,
        steps: [
          {
            slug: 'typeOfUse',
            title: 'Type Of Use',
            description: 'Which type of use will you reproduce this song in?',
            progress: 15,
            options: [
              {
                label: 'In-scene',
                selected: false,
              },
              {
                label: 'Promo/Trailer',
                selected: false,
              },
              {
                label: 'Credits',
                selected: false,
              },
            ],
          },
          {
            slug: 'typeOfFilm',
            title: 'Type Of Film',
            description: 'What type of film is the project?',
            progress: 30,
            options: [
              {
                label: 'Student film',
                selected: false,
              },
              {
                label: 'Short film or independent film',
                selected: false,
              },
              {
                label: 'Made for TV film',
                selected: false,
              },
              {
                label: 'Major studio picture',
                selected: false,
              },
            ],
          },
          {
            slug: 'term',
            title: 'Term',
            description: 'How long will this project be broadcast to the public?',
            progress: 45,
            options: [
              {
                label: '3 Months',
                selected: false,
              },
              {
                label: '6 Months',
                selected: false,
              },
              {
                label: '1 Year',
                selected: false,
              },
              {
                label: 'Up to 5 Years',
                selected: false,
              },
              {
                label: 'Perpetuity',
                selected: false,
              },
            ],
          },
          {
            slug: 'duration',
            title: 'Duration',
            description: 'How long will the song be playing in the project?',
            progress: 60,
            options: [
              {
                label: '15 Seconds',
                selected: false,
              },
              {
                label: '30 Seconds',
                selected: false,
              },
              {
                label: '1 Minute',
                selected: false,
              },
              {
                label: '2 Minutes',
                selected: false,
              },
              {
                label: 'Entire song',
                selected: false,
              },
            ],
          },
          {
            slug: 'media',
            title: 'Media',
            description: 'Which type of medium you will reproduce this project?',
            progress: 75,
            options: [
              {
                label: 'Online streaming',
                selected: false,
              },
              {
                label: 'Traditional TV Broadcast',
                value: 1,
                selected: false,
              },
              {
                label: 'Theatrical',
                value: 2,
                selected: false,
              },
              {
                label: 'All media (Theatrical, TV, Online)',
                value: 3,
                selected: false,
              },
            ],
          },
          {
            slug: 'territory',
            title: 'Territory',
            description: 'Where will you broadcast this project?',
            progress: 95,
            options: [
              {
                label: 'Single country',
                selected: false,
              },
              {
                label: 'Single continent',
                selected: false,
              },
              {
                label: 'USA (Including Canada & Puerto Rico)',
                selected: false,
              },
              {
                label: 'Worldwide',
                selected: false,
              },
            ],
          },
        ],
      },
      {
        id: 'internal',
        label: 'Internal/Industrial',
        selected: false,
        steps: [
          {
            slug: 'media',
            title: 'Media',
            description: 'In what medium will the video be shown?',
            progress: 30,
            options: [
              {
                label: 'Slideshow',
                selected: false,
              },
              {
                label: 'Single-use internal video presentation',
                selected: false,
              },
              {
                label: 'Company-wide internal video presentation',
                selected: false,
              },
              {
                label: 'External presentation (including case-studies, contests, award shows)',
                selected: false,
              },
            ],
          },
          {
            slug: 'term',
            title: 'Term',
            description: 'How long will you broadcast this video?',
            progress: 60,
            options: [
              {
                label: 'Single-use',
                selected: false,
              },
              {
                label: '1 Month',
                value: 1,
                selected: false,
              },
              {
                label: '1 Year',
                selected: false,
              },
              {
                label: 'Perpetuity',
                selected: false,
              },
            ],
          },
          {
            slug: 'duration',
            title: 'Duration',
            description: 'How long will the song be playing in the project?',
            progress: 90,
            options: [
              {
                label: '30 Seconds',
                selected: false,
              },
              {
                label: '1 Minute',
                selected: false,
              },
              {
                label: 'Entire song',
                selected: false,
              },
            ],
          },
        ],
      },
      {
        slug: 'custom',
        label: 'Other',
        selected: false,
        steps: [],
      },
    ],
  };
}
