<ng-template #createIdentityModal>
  <div class="modal-body" id="createIdentityModal">
    <div class="row">
      <div class="col-md-12">
        <button type="button" class="close" (click)="hideModal()"><span class="sr-only">Close</span><span
            class="ss-icon-close"></span></button>
        <h2 class="modal-title" id="loginModal">Invite User</h2>
        <p>Add a specific person to your existing company</p>
      </div>
    </div>
    <div class="row text-left mt30">
      <div class="col-md-12">
        <form [formGroup]="identityForm">
          <div class="form-group">
            <label for="identityFormEmail">Email *</label>
            <input data-hj-allow  type="text" formControlName="email" class="form-control" id="identityFormEmail"
                   placeholder="Enter email">
            <app-inline-errors *ngIf="identityForm.controls['email'].errors && identityForm.controls['email'].touched "
                               [errors]="['This field is required, must be a valid email.']"></app-inline-errors>
          </div>
          <div class="form-group">
            <label for="identityFormName">First Name *</label>
            <input data-hj-allow  type="text" formControlName="firstName" class="form-control" id="identityFormName"
                   placeholder="Enter first name">
            <app-inline-errors *ngIf="identityForm.controls['firstName'].errors && identityForm.controls['firstName'].touched "
                               [errors]="['This field is required.']"></app-inline-errors>
          </div>
          <div class="form-group">
            <label for="identityFormLastName">Last Name *</label>
            <input data-hj-allow  type="text" formControlName="lastName" class="form-control" id="identityFormLastName"
                   placeholder="Enter ingestion name">
            <app-inline-errors *ngIf="identityForm.controls['lastName'].errors && identityForm.controls['lastName'].touched "
                               [errors]="['This field is required.']"></app-inline-errors>
          </div>
          <div class="form-group">
            <div *ngIf="group">Company: {{group.name}}</div>
            <ng-container *ngIf="commonService.isAllowed(['CreateCompany', 'CreateCompanie', '*']) && !group">
              <label for="identityFormLastName">Company</label>
              <p>Select a company to link the user to</p>
              <angular2-multiselect formControlName="groups" [data]="companiesList" 
                [settings]="settings" 
                (onSelect)="onCompanySelect($event)"
                (onDeSelect)="onCompanyDeselect($event)"
                (onOpen)="loadCompanies()"
                (keyup)="onCompanyInputChange($event.target.value)">
              </angular2-multiselect>
            </ng-container>
          </div>
          <div *ngIf="warning && identityForm.value.groups[0]">
            <p>This company doesn't have any permissions, go to
              <a [routerLink]="'/companies/edit/' + identityForm.value.groups[0].data._id"
               (click)="modalRef.hide()">{{identityForm.value.groups[0].itemName}}</a>
                and add one or change the company
            </p>
          </div>
          <div class="form-group" *ngIf="!warning">
            <label for="identityFormLastName">Permissions*</label>
            <angular2-multiselect formControlName="policies" [data]="permissionsList"
              [settings]="permissionsSettings"
              (onSelect)="onPermissionSelect($event)"
              (onDeSelect)="onPermissionDeselect($event)">
              <c-item>
                <ng-template let-item="item">
                  <label style="color: #333;" class="mr10">{{item.itemName}}</label>
                  <span style="display: block;">{{item.description}}</span>
                </ng-template>
              </c-item>
            </angular2-multiselect>
          </div>
          <div class="row mt20">
            <p class="error-color mb20 pl15">{{serverError}}</p>
            <div class="col-md-12 text-center">
              <button id="signUpBtn" type="submit" (click)="createIdentity()"
                      class="btn primary-btn mb0"
                      [disabled]="identityForm.invalid ||
                      identityForm.untouched ||
                      loading">
                {{loading ? 'Inviting' : 'Invite'}}
                <i class="spin medium loader-margin" *ngIf="loading"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
