<ng-template #createPlaylistModal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <button type="button" class="close" (click)="hideModal()">
            <span class="sr-only">Close</span>
            <span class="ss-icon-close"></span>
          </button>
          <h4 class="modal-title">New Playlist</h4>
        </div>
      </div>
      <div class="row text-left mt0">
        <div class="col-md-12 forms-bottom-line">
          <form [formGroup]="playlistForm">
            <div
              class="form-group mt20"
              [ngClass]="{ notEmpty: playlistForm.controls['name'].value }"
            >
              <label for="inputPlaylistName">Name:</label>
              <input
                data-hj-allow
                type="text"
                class="form-control"
                formControlName="name"
                id="inputPlaylistName"
                placeholder="Name (Required)"
                (keydown)="onKeydown($event)"
              />
            </div>
            <div
              class="form-group mt20"
              [ngClass]="{ notEmpty: playlistForm.controls['description'].value }"
            >
              <label for="inputPlaylistDescription">Description:</label>
              <textarea
                class="form-control"
                formControlName="description"
                id="inputPlaylistDescription"
                rows="3"
                (keydown)="onKeydown($event)"
                placeholder="Description"
              ></textarea>
            </div>
            <div
              *ngIf="displayPrivacy"
              class="form-group"
              [ngClass]="{ notEmpty: playlistForm.controls['privacy'].value }"
            >
              <label for="privacy">Privacy </label>
              <select id="privacy" name="privacy" formControlName="privacy" class="form-control">
                <option value="OnlyMe">Only me</option>
                <option value="TeamView">Team View (Read-Only)</option>
                <option value="TeamEdit">Team Edit (Edit by Team)</option>
              </select>
            </div>
            <div class="row mt20">
              <div class="col-md-12 text-right">
                <button
                  id="createPlaylistBtn"
                  class="btn primary-btn mb0"
                  [disabled]="loading || playlistForm.invalid"
                  (click)="createPlaylist()"
                >
                  Create
                  <i class="spin medium loader-margin" *ngIf="loading"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>
