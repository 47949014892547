import { Component, TemplateRef, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { UserService } from 'sostereo-services';
import { TrackingService } from '../../../../shared/services/tracking.service';
import { finalize } from 'rxjs/operators';
import { transition, trigger, useAnimation } from '@angular/animations';
import { inOutAnimation, outInAnimation } from '../../../../shared/animations/in-out.animation';
import { AlertComponent } from 'ngx-bootstrap/alert';
import { CommonModule, Location } from '@angular/common';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SharedModule } from 'src/app/angular/shared/shared.module';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, SharedModule],
  selector: 'app-account-request-modal',
  templateUrl: './account-request-modal.component.html',
  styleUrls: ['./account-request-modal.component.scss'],
  animations: [
    trigger('inOutAnimation', [transition(':enter', useAnimation(inOutAnimation))]),
    trigger('outInAnimation', [transition(':leave', useAnimation(outInAnimation))]),
  ],
})
export class AccountRequestModalComponent {
  @ViewChild('accountRequestModal', { static: true }) modalTemplate: TemplateRef<any>;
  public modalRef: MatDialogRef<any, any>;
  public accountRequestForm: FormGroup;
  public loadingRequest = false;
  public requestSuccess: boolean;
  public alerts: any[] = [];
  public showMusicForm = false;

  constructor(
    private modalService: MatDialog,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private trackingService: TrackingService,
    private location: Location,
    private router: Router,
  ) {
    this.createForm();
  }

  private createForm() {
    this.accountRequestForm = this.formBuilder.group({
      firstName: new FormControl(null, Validators.required),
      lastName: new FormControl(null, Validators.required),
      artistName: new FormControl(null, Validators.required),
      email: new FormControl(null, [
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ]),
      musicGenre: new FormControl(null, Validators.required),
      musicLink: new FormControl(null, Validators.required),
      labelAgreement: new FormControl(false),
      proMember: new FormControl(false),
      companyAgreement: new FormControl(false),
      role: new FormControl(null),
      referrer: new FormControl(null, Validators.required),
      message: new FormControl(null),
    });
  }

  sendForm() {
    this.loadingRequest = true;
    const accountRequest = this.validateAccountRequestProperties(this.accountRequestForm.value);
    this.userService
      .accountRequest(accountRequest)
      .pipe(
        finalize(() => {
          this.loadingRequest = false;
        }),
      )
      .subscribe(
        (res) => {
          this.location.go('/submit-music-success');
          this.trackingService.track(
            'Account Request Modal Success',
            {
              action: 'Modal Success',
              kind: 'Account Request Success',
              data: res,
            },
            {
              event_action: 'Account request modal sent',
              event_type: 'Form Submission',
              event_user_email: res.data.submissionResponse.email,
              element_id: res.data.submissionResponse._id,
            },
          );
          this.requestSuccess = true;
        },
        (err) => {
          this.trackingService.track('Account Request Modal Error', {
            action: 'Modal Error',
            kind: 'Account Request Error',
            data: err,
          });
          this.addAlert({
            type: 'danger',
            message: "We couldn't send your request, please try again!",
          });
        },
      );
  }

  private addAlert(alert: any): void {
    this.alerts.push({
      type: alert.type,
      message: alert.message,
      timeout: 5000,
    });
  }

  public onAlertClosed(dismissedAlert: AlertComponent): void {
    this.alerts = this.alerts.filter((alert) => alert !== dismissedAlert);
  }

  public validateAccountRequestProperties(formValue) {
    formValue.labelAgreement = formValue.labelAgreement ? 'Yes' : 'No';
    formValue.proMember = formValue.proMember ? 'Yes' : 'No';
    formValue.companyAgreement = formValue.companyAgreement ? 'Yes' : 'No';
    return formValue;
  }

  public showModal() {
    this.location.go('/submit-music');
    this.modalRef = this.modalService.open(this.modalTemplate, {
      panelClass: ['modal', 'material'],
      disableClose: true,
      width: 'min(90vw, 600px)',
      maxWidth: '90vw',
      role: 'dialog',
      closeOnNavigation: true,
      maxHeight: '95svh',
      ariaModal: true,
    });
    this.trackingService.track(
      'Account Request Modal Open',
      {
        action: 'Modal Open',
        kind: 'Account Request modal',
      },
      {
        event_action: 'Account request modal opened',
        event_type: 'Modal Opened',
        event_value: 'Are you an artist?',
      },
    );
  }

  public hideModal() {
    const url = this.router.url.split('/')[0];
    this.location.go(url || '');
    this.modalRef.close('closed');
    this.accountRequestForm.reset();
    setTimeout(() => {
      this.requestSuccess = false;
    }, 1000);
    this.trackingService.track(
      'Account Request Modal Close',
      {
        action: 'Modal Close',
        kind: 'Account Request modal',
      },
      {
        event_action: 'Account request modal closed',
        event_type: 'Modal Closed',
        event_value: 'Are you an artist?',
      },
    );
  }
}
