import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  Inject,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactUsService, NotificationService } from 'sostereo-services';
import { TrackingService } from '../../../../shared/services/tracking.service';
import { CommonService } from '../../../../shared/services/common.service';
import { clone } from 'lodash-es';

@Component({
  selector: 'app-request-demo-modal',
  templateUrl: './request-demo-modal.component.html',
  styleUrls: ['./request-demo-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestDemoModalComponent implements OnInit {
  @ViewChild('requestDemoModal', { static: true }) modalTemplate: TemplateRef<any>;
  public modalRef: BsModalRef;
  contactUsForm: FormGroup;
  disableBtn: boolean;
  requestSuccess: boolean;
  requestError: boolean;

  constructor(
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private contactUsService: ContactUsService,
    private trackingService: TrackingService,
    private notificationService: NotificationService,
    private commonService: CommonService,
    @Inject('environment') private environment,
  ) {
    this.disableBtn = false;
    this.createForm();
  }

  ngOnInit() {}

  private createForm() {
    this.contactUsForm = this.formBuilder.group({
      fullName: new FormControl(null, { validators: Validators.required }),
      companyName: new FormControl(null, { validators: Validators.required }),
      contactEmail: new FormControl(null, {
        validators: [Validators.pattern(this.commonService.emailRegex), Validators.required],
      }),
      message: new FormControl(null),
    });
  }

  sendEmail() {
    this.disableBtn = true;
    const form = clone(this.contactUsForm.value);
    const notification = {
      email: 'salo@sostereo.com',
      subject: 'Stereo Brands Demo Requested!',
      title: 'Stereo Brands Demo Requested!',
      body: `<div><p>Our team  will contact you as soon as possible to talk about Stereo Brands.
      Feel free to call us at <b>+1 (305) 702-0180</b> or send us an email to <b>hello@sostereo.com</b></p>
      <p>Cheers!</p><br>
      <p><b>Your Contact Email: </b>${form.contactEmail}</p>
      <p><b>Full Name: </b>${form.fullName}</p>
      <p><b>Brand/ company: </b>${form.companyName}</p>
      <p><b>Message: </b>${form.message}</p>
        </div>`,
      bodyTitle: `Thank you for requesting a Stereo Brands demo!`,
    };

    this.notificationService.post(notification).subscribe({
      next: () => {
        this.trackingService.track(
          'Submission Success',
          { email: form?.contactEmail },
          {
            event_action: 'Request demo submission success',
            event_type: 'Form Submission',
            event_user_email: form?.contactEmail,
          },
        );
        this.disableBtn = false;
        this.requestSuccess = true;
        setTimeout(() => {
          this.hideModal();
          this.requestSuccess = false;
        }, 3000);
      },
      error: () => {
        this.requestError = true;
        this.disableBtn = false;
        this.requestSuccess = false;
      },
    });
  }

  public showModal() {
    this.modalRef = this.modalService.show(this.modalTemplate, { class: 'modal-md' });
    this.trackingService.track(
      'Stereo Brands Demo',
      {
        action: 'Open',
      },
      {
        event_action: 'Stereo brands demo modal opened',
        event_type: 'Modal Opened',
      },
    );
  }

  public hideModal() {
    this.contactUsForm.reset();
    this.modalRef.hide();
    this.trackingService.track(
      'Stereo Brands Demo',
      {
        action: 'Closed',
      },
      {
        event_action: 'Stereo brands demo modal closed',
        event_type: 'Modal Closed',
      },
    );
  }
}
