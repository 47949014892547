<ng-template #songTagsUpdateModal>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="clearfix text-center">
            <h5>Update "{{songData.name}}" ({{songData.songVersion}})
              <span>{{songData.songVersion !== 'instrumental' && songData.hasOwnProperty('vocals') && !songData.vocals ?
                '(Inst)' : ''}}</span>
              By {{songData.songArtists}}</h5>
          </div>
          <a href="/rating/song/{{songData.soId}}" target="_blank" style="text-decoration: underline;" >Go to song rating</a>
        </div>
      </div>
    </div>
    <div class="modal-body white-form song-tags-modal">
      <div class="row">
        <div class="col-md-6">
          <p><b>{{currentTags.length}} Tags</b></p>
          <p><b>{{tagsAdded.length}} added / {{tagsRemoved.length}} removed</b></p>
          <angular2-multiselect [data]="tagsList"
            [(ngModel)]="selectedTags"
            [settings]="settings"
            (onSelect)="onTagSelect($event)"
            (onDeSelect)="onTagDeSelect($event)"
            (keyup)="onTagsInputChange($event.target.value)">
          <c-badge>
            <ng-template let-item="item">
              <label class="custom-label">Select</label>
            </ng-template>
          </c-badge>
        </angular2-multiselect>
        <div class="pt15">
          <div *ngIf="tagsAdded.length > 0">
            <p><b>Added Tags</b></p>
            <ul class="pl20" style="max-height:100px; overflow-y:auto; overflow-x: hidden;">
              <li *ngFor="let tagAdded of tagsAdded">{{tagAdded}}
                <i aria-hidden="true" style="font-size: 10px; vertical-align: middle;margin-left: 1px; cursor: pointer"
                   class="fa fa-times" (click)="removeTag(tagAdded)"></i>
              </li>

            </ul>
          </div>
          <div *ngIf="tagsRemoved.length > 0">
            <p><b>Removed Tags</b></p>
            <ul class="pl20" style="max-height:100px; overflow-y:auto; overflow-x: hidden;">
              <li *ngFor="let tagRemoved of tagsRemoved">{{tagRemoved}}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <p class="pb5"><b>Selected Tags</b></p>
        <ul class="pl20" style="max-height:200px; overflow-y:auto; overflow-x: hidden;">
          <li *ngFor="let tag of currentTags; let i = index">{{tag}}</li>
        </ul>
      </div>
    </div>
    <div class="row text-center">
      <div class="col-md-12">
        <button type="submit" [disabled]="tagsLoading"
                class="btn mr10 cancel-btn mt20 mb20"
                (click)="hideModal()">
          Cancel
        </button>
        <button type="submit"
                class="btn primary-btn mt20 mb20"
                [disabled]="tagsAdded.length === 0 && tagsRemoved.length === 0 || tagsLoading"
                (click)="updateSongTags()">
          <i class="spin medium" *ngIf="tagsLoading"></i>
          Save Tags
        </button>
      </div>
    </div>
  </div>
</ng-template>
