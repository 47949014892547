import { isEmpty } from 'lodash-es';

export const configStatusModal = (song) => {
  return [
    {
      googleIcon: 'do_disturb_on',
      title: 'Restrictions',
      show: song.restriction || (song.restrictions && !isEmpty(song.restrictions || [])),
      field: 'restrictions',
    },
    {
      googleIcon: 'description',
      title: 'Internal Notes',
      show: !!song.notes,
      field: 'notes',
    },
    {
      googleIcon: 'price_check',
      title: song.tierName?.toLowerCase().includes('tier')
        ? song.tierName
        : `Tier ${song.tierName}`,
      show: song.tierHasWarning,
    },
    {
      googleIcon: 'vpn_lock',
      title: 'Private',
      show: song.publicAccess === false,
    },
    {
      googleIcon: 'traffic',
      title: 'Not One Stop',
      show: song.isOneStop == false,
    },
    {
      googleIcon: 'today',
      title: 'Unreleased',
      show: song.releaseStatus === 'unreleased',
    },
    {
      googleIcon: 'tune',
      title: 'Not Customizable',
      show: song.customizable === 'no',
    },
    {
      googleIcon: 'water',
      title: 'No Stems Available',
      show: song.stemsAvailable === 'no',
    },
    {
      googleIcon: 'support_agent',
      title: song.internalManager?.email || song.internalManager,
      show: !!(song.internalManager?.email || song.internalManager),
      textTransform: 'none',
    },
    {
      googleIcon: 'warning',
      title: 'Copyright Warning:\nPossible Cover',
      show: song.copyrightWarning?.includes('cover'),
    },
    {
      googleIcon: 'warning',
      title: 'Copyright Warning:\n Major Label',
      show: song.copyrightWarning?.includes('major-label'),
    },
  ];
};
