import { Component, OnInit, ViewChild, TemplateRef, Output, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TrackingService } from '../../../../shared/services/tracking.service';
import { TagsService, SongsService } from 'sostereo-services';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { clone, reduce, remove, uniq } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-song-tags-update-modal',
  templateUrl: './song-tags-update-modal.component.html',
  styleUrls: ['./song-tags-update-modal.component.scss'],
})
export class SongTagsUpdateModalComponent implements OnInit {
  @ViewChild('songTagsUpdateModal', { static: true }) modalTemplate: TemplateRef<any>;
  @Output() modalClosed = new EventEmitter();

  public modalRef: BsModalRef;
  public songData: any = {};
  public currentTags = [];
  public tagsDropdown = [];
  public tagsAdded = [];
  public tagsRemoved = [];
  public tagsLoading = false;
  public settings = {
    singleSelection: false,
    text: 'Select',
    enableSearchFilter: true,
    enableFilterSelectAll: false,
    searchPlaceholderText: 'Search',
    badgeShowLimit: 0,
  };

  public tagsList = [];
  public selectedTags = [];
  private tagsTypingTimeout = null;
  private originalTags = [];
  private tagTyped = '';

  constructor(
    private modalService: BsModalService,
    private trackingService: TrackingService,
    private tagsService: TagsService,
    private songsService: SongsService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  public loadTags(query?: string) {
    const tagQuery: any = { limit: 15, status: 'published', sort: 'slug asc' };
    if (query) {
      tagQuery.slug = query;
    }
    this.tagsService.query(tagQuery).subscribe((tRes) => {
      this.selectedTags = [];
      this.tagsList = reduce(
        tRes.data.items,
        (acc, d) => {
          if (this.currentTags.indexOf(d.slug) !== -1) {
            this.selectedTags.push({
              id: d.slug,
              itemName: d.slug,
            });
          }
          acc.push({
            id: d.slug,
            itemName: d.slug,
          });
          return acc;
        },
        [],
      );
    });
  }

  public onTagsInputChange(input) {
    clearTimeout(this.tagsTypingTimeout);
    this.tagsTypingTimeout = setTimeout(() => {
      this.loadTags(new RegExp(input).toString());
    }, 200);
  }

  tagOnSelect(e: TypeaheadMatch): void {
    this.tagTyped = '';
    if (this.currentTags.indexOf(e.value) === -1) {
      this.currentTags.push(e.value);
      this.tagsAdded.push(e.value);
    }
    if (this.tagsRemoved.indexOf(e.value) !== -1) {
      this.tagsRemoved.splice(this.tagsRemoved.indexOf(e.value), 1);
    }
  }

  ngOnInit() {}

  public showModal(song) {
    this.songData = song;
    this.currentTags = clone(this.songData.tags).sort();
    this.originalTags = clone(this.songData.tags);
    this.loadTags();
    this.modalRef = this.modalService.show(this.modalTemplate, {
      class: 'modal-md',
      ignoreBackdropClick: true,
    });
  }

  public hideModal() {
    this.tagsAdded = [];
    this.tagsRemoved = [];
    this.modalRef.hide();
  }

  public removeTag(tag) {
    console.log(tag);
    let tagsToRemove = [tag];
    this.tagsService.query({ limit: 100, 'ancestors.slug': tag }).subscribe((res) => {
      const childTags = res.data.items;
      if (childTags && childTags.length > 0) {
        tagsToRemove = [...tagsToRemove, ...childTags.map((a) => a.slug)];
      }
      console.log(tagsToRemove);
      tagsToRemove.forEach((ttr) => {
        if (this.selectedTags.map((st) => st.itemName).includes(ttr)) {
          this.selectedTags = this.selectedTags.filter((t) => t.itemName !== ttr);
        }
        if (this.currentTags.includes(ttr)) {
          this.currentTags = this.currentTags.filter((t) => t !== ttr);
        }
        if (this.tagsAdded.includes(ttr)) {
          this.tagsAdded = this.tagsAdded.filter((t) => t !== ttr);
        }
        if (this.songData.tags.includes(ttr)) {
          this.tagsRemoved.push(ttr);
          this.tagsRemoved = uniq(this.tagsRemoved);
        }
      });
    });
  }

  public onTagSelect(tag) {
    let tagsToAdd = [tag.itemName];
    this.tagsService.query({ limit: 1, slug: tag.itemName }).subscribe((res) => {
      const queryTag = res.data.items[0];
      if (queryTag && queryTag.ancestors && queryTag.ancestors.length > 0) {
        tagsToAdd = [...tagsToAdd, ...queryTag.ancestors.map((a) => a.slug)];
      }
      console.log(tagsToAdd);
      tagsToAdd.forEach((tta) => {
        this.selectedTags.push({
          id: tta,
          itemName: tta,
        });
        if (!this.originalTags.includes(tta)) {
          this.tagsAdded.push(tta);
          this.tagsAdded = uniq(this.tagsAdded);
        }
        if (!this.currentTags.includes(tta)) {
          this.currentTags.push(tta);
          this.currentTags = uniq(this.currentTags);
          this.currentTags.sort();
        }
        if (this.tagsRemoved.includes(tta)) {
          this.tagsRemoved = this.tagsRemoved.filter((t) => t !== tta);
        }
      });
    });
  }

  public onTagDeSelect(tag) {
    let tagsToRemove = [tag.itemName];
    this.tagsService.query({ limit: 100, 'ancestors.slug': tag.itemName }).subscribe((res) => {
      const childTags = res.data.items;
      if (childTags && childTags.length > 0) {
        tagsToRemove = [...tagsToRemove, ...childTags.map((a) => a.slug)];
      }
      console.log(tagsToRemove);
      tagsToRemove.forEach((ttr) => {
        if (this.selectedTags.map((st) => st.itemName).includes(ttr)) {
          this.selectedTags = this.selectedTags.filter((t) => t.itemName !== ttr);
        }
        if (this.currentTags.includes(ttr)) {
          this.currentTags = this.currentTags.filter((t) => t !== ttr);
        }
        if (this.tagsAdded.includes(ttr)) {
          this.tagsAdded = this.tagsAdded.filter((t) => t !== ttr);
        }
        if (this.songData.tags.includes(ttr)) {
          this.tagsRemoved.push(ttr);
          this.tagsRemoved = uniq(this.tagsRemoved);
        }
      });
    });
  }

  public updateSongTags() {
    this.tagsLoading = true;
    const songTags: any = { tags: this.originalTags.concat(this.tagsAdded) };
    remove(songTags.tags, (t) => this.tagsRemoved.includes(t));
    this.songsService.update(this.songData._id, songTags).subscribe(
      () => {
        this.hideModal();
        setTimeout(() => {
          this.router.navigate([], {
            queryParamsHandling: 'merge',
            skipLocationChange: true,
          });
          this.modalClosed.emit();
        }, 3000);
      },
      (err) => {
        this.tagsLoading = false;
        this.trackingService.track('Error updating song tags', {
          error: err,
        });
        this.toastr.error(err?.error?.message, 'There was an error during song tags update');
      },
    );
  }
}
