export const selectableCompanyPolicies = [
  {
    slug: 'contract-owner',
    name: 'Catalog Owner',
    value: 'srn:iam:sostereo:policies:{{slugSrn}}',
    description: 'This permission gives the company access to certain artist in the platform.',
  },
  {
    slug: 'projects-collaborator',
    name: 'Projects Collaborator',
    value: 'srn:iam:sostereo:policies:{{slugSrn}}:spaces',
    description: 'This permission gives the company access to the projects module in the platform.',
  },
];
export const agreementCompanyPolicy = {
  name: 'Brand agreement',
  value: ':policies:agreements:',
  description: 'All users of this company will have a custom search based on the agreement.',
};

export function mapCompanyPolicies(companyPolicies) {
  companyPolicies.forEach((cp) => {
    if (cp.srn.includes(agreementCompanyPolicy.value)) {
      cp.description = agreementCompanyPolicy.description;
      cp.label = agreementCompanyPolicy.name;
    }
    const groupPolicy = selectableCompanyPolicies.find(
      (p) => cp.label.toLowerCase() === p.name.toLowerCase(),
    );
    if (groupPolicy) {
      cp.description = groupPolicy.description;
    }
  });
  return companyPolicies;
}
