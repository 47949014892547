import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SearchConfigService {
  getSorterCategories() {
    return [
      { label: 'Playlists', id: 'playlists', items: [], paging: {} },
      { label: 'Genre', id: 'genre', items: [], paging: {} },
      { label: 'Mood', id: 'mood', items: [], paging: {} },
      { label: 'Vocal', id: 'vocal', items: [], paging: {} },
      { label: 'Vocal Sonority', id: 'vocal-sonority', items: [], paging: {} },
      { label: 'lyrics Subject', id: 'lyrics-subject', items: [], paging: {} },
      { label: 'BPM', id: 'bpm', items: [], paging: {} },
      { label: 'Instrumentation', id: 'instrumentation', items: [], paging: {} },
      { label: 'Rhythm', id: 'rhythm', items: [], paging: {} },
      { label: 'Harmony', id: 'harmony', items: [], paging: {} },
      { label: 'Themes Uses', id: 'themes-uses', items: [], paging: {} },
      { label: 'Artists', id: 'artists', items: [], paging: {} },
      { label: 'Songs', id: 'songs', items: [], paging: {} },
      { label: 'Production Style', id: 'productionstyle', items: [], paging: {} },
      { label: 'Language', id: 'language', items: [], paging: {} },
      { label: 'Lyrics', id: 'lyric', items: [], paging: {} },
      { label: 'Lyrics strict', id: 'lyric-strict', items: [], paging: {} },
      {
        label: 'Recording Location',
        id: 'recording-location',
        items: [],
        aggregations: [],
        paging: {},
      },
      {
        label: 'Available Markets',
        id: 'available-markets',
        items: [],
        aggregations: [],
        paging: {},
      },
    ];
  }

  getTagsHeights() {
    return {
      general: 29,
      songs: 40,
      lyrics: 118,
      playlists: 70,
    };
  }

  getHeightLimits() {
    return {
      general: { value: 0, margin: 100 },
      songs: { value: 0, margin: 120 },
      lyrics: { value: 0, margin: 180 },
      playlists: { value: 0, margin: 20 },
    };
  }

  getCategoriesWithoutNegative() {
    return [
      'artists',
      'type',
      'stats',
      'budget',
      'youtube-ref',
      'advanced-search',
      'explicit',
      'ref-song',
      'query',
      'playlist',
    ];
  }
}
