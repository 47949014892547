<ng-template #sendGiftModal>
  <div class="modal-body" *ngIf="!requestSuccess">
    <div class="row">
      <div class="col-md-12">
        <button type="button" class="close" (click)="cancel()"><span class="sr-only">Close</span><span
            class="ss-icon-close"></span></button>
        <div class="clearfix mb20 text-center">
          <h4 class="modal-title" style="text-align: center">
            So, it looks you didn't find <br>licensable music with a few clicks
          </h4>
          <p>Please give us your company address to send a box of donuts!</p>
        </div>
      </div>
    </div>
    <form [formGroup]="sendGiftForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="name">Name *</label>
        <input data-hj-allow  type="text" formControlName="name" class="form-control" id="name">
        <div *ngIf="sendGiftForm.controls['name'].touched && sendGiftForm.controls['name'].errors">
          <app-inline-errors [errors]="['this field is required']"></app-inline-errors>
        </div>
      </div>
      <div class="form-group">
        <label for="email">Email *</label>
        <input type="email" formControlName="email" class="form-control" id="email">
        <div *ngIf="sendGiftForm.controls['email'].touched && sendGiftForm.controls['email'].errors">
          <app-inline-errors [errors]="['this field is required, must be a valid email']"></app-inline-errors>
        </div>
      </div>
      <div class="form-group">
        <label for="agencyName">Agency Name *</label>
        <input data-hj-allow  type="text" formControlName="agencyName" class="form-control" id="agencyName">
        <div *ngIf="sendGiftForm.controls['agencyName'].touched && sendGiftForm.controls['agencyName'].errors">
          <app-inline-errors [errors]="['this field is required']"></app-inline-errors>
        </div>
      </div>
      <div class="form-group">
        <label for="agencyAddress">Agency address *</label>
        <input data-hj-allow  type="text" formControlName="agencyAddress" class="form-control" id="agencyAddress">
        <div *ngIf="sendGiftForm.controls['agencyAddress'].touched && sendGiftForm.controls['agencyAddress'].errors">
          <app-inline-errors [errors]="['this field is required']"></app-inline-errors>
        </div>
      </div>
      <div class="clearfix">
        <div class="form-group col-sm-12">
          <button [disabled]="loadingRequest || sendGiftForm.invalid" type="submit"
                  class="btn primary-btn col-sm-4 mt20 pull-right">
            Send
            <i class="spin medium" *ngIf="loadingRequest"></i>
          </button>
        </div>
      </div>
      <div *ngFor="let alert of alerts">
        <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout"
               (onClosed)="onAlertClosed(alert)">{{ alert.message }}</alert>
      </div>
    </form>
  </div>
  <div class="modal-body" *ngIf="requestSuccess" [@inOutAnimation]>
    <div class="row text-center">
      <div class="col-md-12">
        <h4 class="modal-title text-center">
          <span style="font-size: 70px;" class="glyphicon glyphicon-ok-circle font-20x"></span>
        </h4>
        <h2>Message sent!</h2>
      </div>
    </div>
    <div class="row text-center">
      <div class="col-md-12">
        <p>Thanks for trying the challenge! As we promise we will send a box of donuts and get in touch to see what we missed!</p>
        <br/><br/>
      </div>
    </div>
    <div class="row text-center">
      <div class="col-md-12">
        <button id="discoverBtn" type="submit"
                class="btn primary-btn mt20 mb20"
                (click)="hideModal()">
          Ok
        </button>
      </div>
    </div>
    <img *ngIf="pixelRequest" height="1" width="1" style="border-style:none;" alt=""
      src="//insight.adsrvr.org/track/pxl/?adv=875l4a9&ct=0:61xxvmh&fmt=3" />
  </div>
</ng-template>
