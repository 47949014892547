<ng-template #downloadPlaylistConfirmationModal>
  <div class="modal-body" [ngClass]="{'pitch-style': isPitch}">
    <div class="row">
      <div class="col-md-12">
        <button type="button" class="close" (click)="cancel()" *ngIf="!isPitch"><span class="sr-only">Close</span><span
            class="ss-icon-close"></span></button>
        <button type="button" class="close-btn" (click)="hideModal()" *ngIf="isPitch"
        style="right: 10px; bottom: 10px; padding: 0px 10px 0px 10px;">
          &times;
        </button>
        <h4 class="modal-title">Download Playlist</h4>
      </div>
    </div>
    <div class="row text-left mt30">
      <div class="col-md-12">
        <p>Do you really want to download this playlist?</p>
        <h5 class="secondary-color fw400">{{playlistData.playlistName}}</h5>
        <p>We will include all playlist songs in a single zip file. Check your browser downloads to see the process
          status.</p>
      </div>
    </div>
    <div class="row mt20">
      <div class="col-md-12 text-right">
        <p type="button" class="secondary-link pr20 mb0 display-i" (click)="cancel()" style="cursor: pointer;">Cancel</p>
        <button type="button" class="btn primary-btn mb0" (click)="downloadPlaylist()">Yes, I'm sure
        </button>
      </div>
    </div>
  </div>
</ng-template>