import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AuthenticationService,
  UserService,
  SignUpService,
  PlaylistsStereoSpacesService,
} from 'sostereo-services';
import { TrackingService } from '../../../../../../shared/services/tracking.service';
import { DownloadService } from '../../../../../../shared/services/download.service';
import { CommonService } from '../../../../../../shared/services/common.service';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-complete-account-modal',
  templateUrl: './complete-account-modal.component.html',
})
export class CompleteAccountModalComponent {
  @ViewChild('completeAccountModal', { static: true }) modalTemplate: TemplateRef<any>;
  private modalRef: BsModalRef;
  private completeAccountForm: FormGroup;
  private userData;
  private playlistData;
  public loadingForm = false;
  @Input() downloadPlaylist;
  @Output() processFinished = new EventEmitter();
  private isDownloading = false;
  public generalError = '';
  public pixelRequest = false;
  public isPitch = this.router.url.includes('pitch/');

  constructor(
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private signUpService: SignUpService,
    private trackingService: TrackingService,
    private downloadService: DownloadService,
    private playlistsStereoSpacesService: PlaylistsStereoSpacesService,
    private commonService: CommonService,
    private router: Router,
    @Inject('environment') private environment,
  ) {
    this.createForm();
  }

  private createForm() {
    this.completeAccountForm = this.formBuilder.group({
      firstName: new FormControl(null, { validators: Validators.required, updateOn: 'change' }),
      lastName: new FormControl(null, { validators: Validators.required, updateOn: 'change' }),
      password: new FormControl(null, { validators: Validators.required, updateOn: 'change' }),
    });
  }

  public onCompleteAccountSubmit() {
    this.generalError = '';
    this.loadingForm = true;
    const completedAccount = this.completeAccountForm.value;
    completedAccount.status = 'active';
    let trackBody = {};
    let trackMessage = 'Account Completed';
    if (this.downloadPlaylist) {
      trackMessage = 'Download Playlist Account Completed';
      trackBody = {
        userId: this.userData?.id,
        userEmail: this.userData?.email,
        playlistId: this.playlistData?.playlistId,
        playlistName: this.playlistData?.playlistName,
      };
    }
    this.userService.update(this.userData.id || this.userData._id, completedAccount).subscribe(
      () => {
        this.trackingService.track(trackMessage, trackBody, {
          event_action: 'Download playlist account completed',
          event_type: 'Playlist Download',
          event_user_email: this.userData?.email,
          element_type: 'Playlist',
          element_value: this.playlistData?.playlistName,
          element_id: this.playlistData?.playlistId,
        });
        this.loadingForm = false;
        this.hideModal();
      },
      (err) => {
        console.log('Error when updating user at quick sign up >>> ', err);
        this.generalError = err.error.message;
        this.trackingService.track(trackMessage + ' Error', trackBody);
        this.loadingForm = false;
      },
    );
  }

  public showModal(data) {
    this.pixelRequest = true;
    this.userData = data.user;
    if (this.downloadPlaylist) {
      this.playlistData = data.playlistData;
      this.downloadZip();
    }
    this.modalRef = this.modalService.show(this.modalTemplate, {
      class: 'modal-md',
      ignoreBackdropClick: true,
    });
    this.trackingService.track(
      'MS Modal Open',
      {
        action: 'Modal Open',
        kind: 'MS modal',
      },
      {
        event_action: 'MS Modal opened',
        event_type: 'Modal Opened',
      },
    );
  }

  downloadZip() {
    this.isDownloading = true;
    this.downloadService.addLeaveWarning();
    this.playlistData.targetId = this.playlistData.playlistId;
    this.trackingService.track('Playlist zip start downloading', this.playlistData);
    this.playlistsStereoSpacesService
      .downloadZip(this.playlistData.playlistId)
      .pipe(
        finalize(() => {
          this.downloadService.killLeaveWarning();
          this.isDownloading = false;
          this.trackingService.trackDownloadView();
          this.processFinished.emit();
        }),
      )
      .subscribe(
        (res) => {
          this.trackingService.track(
            'Playlist zip finnished downloading',
            {
              playlistId: this.playlistData?.playlistId,
              playlistName: this.playlistData?.playlistName,
              targetId: this.playlistData?.targetId,
            },
            {
              event_action: 'Playlist zip download finished',
              event_type: 'Playlist Download',
              element_type: 'Playlist',
              element_value: this.playlistData?.playlistName,
              element_id: this.playlistData?.playlistId,
            },
          );
          this.loadingForm = false;
          this.downloadService.saveFile(
            res,
            `${
              this.commonService.tenantId.charAt(0).toUpperCase() +
              this.commonService.tenantId.slice(1)
            } - ${this.playlistData.playlistName}.zip`,
          );
        },
        (err) => {
          this.trackingService.track('Error with Playlist download', {
            playlistId: this.playlistData?.playlistId,
            playlistName: this.playlistData?.playlistName,
            targetId: this.playlistData?.targetId,
            errors: err,
          });
        },
      );
  }

  public inviteMs() {
    this.generalError = '';
    delete this.userData.skipEmail;
    let trackBody = {};
    let trackMessage = '';
    if (this.downloadPlaylist) {
      trackMessage = 'Download Playlist';
      trackBody = {
        userId: this.userData.id,
        userEmail: this.userData.email,
        playlistId: this.playlistData.playlistId,
        playlistName: this.playlistData.playlistName,
      };
    }
    this.signUpService.inviteMs(this.userData).subscribe(
      () => {
        this.trackingService.track(trackMessage + 'Invite Signup', trackBody, {
          event_action: 'Playlist Downloaded',
          event_type: 'Playlist Download',
          event_user_email: this.userData?.email,
          element_type: 'Playlist',
          element_value: this.playlistData?.playlistName,
          element_id: this.playlistData?.playlistId,
        });
        this.modalRef.hide();
      },
      (err) => {
        console.log('Error at inviting MS', err);
        this.trackingService.track(trackMessage + 'Error Invite Signup', trackBody);
        this.modalRef.hide();
      },
    );
  }

  public hideModal() {
    if (!this.isDownloading) {
      this.processFinished.emit();
    }
    this.completeAccountForm.reset();
    this.modalRef.hide();
    this.trackingService.track(
      'MS Modal Closed',
      {
        action: 'Modal Closed',
        kind: 'MS modal',
      },
      {
        event_action: 'MS Modal hidden',
        event_type: 'Modal Hidden',
      },
    );
  }

  public cancel() {
    if (!this.isDownloading && this.downloadPlaylist) {
      this.processFinished.emit();
    }
    this.modalRef.hide();
    this.trackingService.track(
      'MS Modal Closed',
      {
        action: 'Modal Closed',
        kind: 'MS modal',
      },
      {
        event_action: 'MS Modal closed',
        event_type: 'Modal Closed',
      },
    );
  }
}
