<ng-template #resetPasswordModal>
  <div class="modal-body" *ngIf="!requestSuccess" [@inOutAnimation]>
    <div class="row">
      <div class="col-md-12">
        <button type="button" class="close" (click)="hideModal()"><span class="sr-only">Close</span><span
            class="ss-icon-close"></span></button>
        <h4 class="modal-title" id="myModalLabel">Reset Password</h4>
      </div>
    </div>
    <form [formGroup]="resetPasswordForm">
      <div class="row text-left">
        <div class="col-md-12">
          <div class="form-group mt20">
            <label for="resetPasswordFormPassword">Password:</label>
            <input type="password" class="form-control" id="resetPasswordFormPassword" formControlName="newPassword"
                   placeholder="Enter password"
                   (keyup)="validatePasswords()">
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group mt10">
            <label for="resetPasswordFormPassword">Confirm password:</label>
            <input type="password" class="form-control" id="resetPasswordFormConfirmPassword"
                   formControlName="repeatPassword"
                   placeholder="confirm password"
                   (keyup)="validatePasswords()">
            <app-inline-errors *ngIf="!passwordMatch &&  resetPasswordForm?.get('repeatPassword').dirty"
                               [errors]="['Passwords don\'t match']"></app-inline-errors>
          </div>
        </div>
      </div>
    </form>
    <div class="row mt10">
      <div class="col-md-12 text-center">
        <button type="submit" class="btn primary-btn" (click)="resetPassword()"
                [disabled]="!passwordMatch|| resetPasswordForm.untouched || loadingRequest">
          Submit
          <i class="spin medium" *ngIf="loadingRequest"></i>
        </button>
      </div>
      <div class="col-md-12 mt10">
        <div *ngFor="let alert of alerts">
          <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout"
                 (onClosed)="onAlertClosed(alert)">{{ alert.message }}</alert>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-body" *ngIf="requestSuccess" [@inOutAnimation]>
    <div class="row text-center">
      <div class="col-md-12">
        <h4 class="modal-title text-center">
          <span style="font-size: 70px;" class="glyphicon glyphicon-ok-circle font-20x"></span>
        </h4>
        <h2>Password Changed!</h2>
      </div>
    </div>
    <div class="row text-center">
      <div class="col-md-12">
        <p>Your password has been successfully changed.</p>
        <br/><br/>
      </div>
    </div>
    <div class="row text-center">
      <div class="col-md-12">
        <button id="discoverBtn" type="submit"
                class="btn primary-btn mt20 mb20"
                (click)="hideModal()">
          Close
        </button>
      </div>
    </div>
  </div>
</ng-template>
