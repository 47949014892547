import { Component, OnInit, TemplateRef, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'sostereo-services';
import { Location, TitleCasePipe } from '@angular/common';
import { TrackingService } from '../../../../shared/services/tracking.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { CommonService } from '../../../../shared/services/common.service';
import { AlertComponent } from 'ngx-bootstrap/alert';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-brief-submission-modal',
  templateUrl: './brief-submission-modal.component.html',
  styleUrls: ['./brief-submission-modal.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.5s ease-out', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class BriefSubmissionModalComponent implements OnInit {
  @ViewChild('briefSubmissionModal', { static: true }) modalTemplate: TemplateRef<any>;
  public modalDescription =
    'Need help with an upcoming project? <br><br> Submit your email and our creative team will reach out to help and get you a playlist asap!';

  public modalRef: MatDialogRef<any, any>;
  public briefSubmissionForm: FormGroup;
  public loadingRequest: boolean;
  public alerts: any[] = [];
  public pixelRequest = false;

  constructor(
    private modalService: MatDialog,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private location: Location,
    private trackingService: TrackingService,
    private titleCasePipe: TitleCasePipe,
    private commonService: CommonService,
    @Inject('environment') public environment,
  ) {
    this.createForm();
    this.loadingRequest = false;
  }

  ngOnInit() {}

  private resetComponent() {
    this.briefSubmissionForm.reset();
    this.loadingRequest = false;
  }

  private createForm() {
    this.briefSubmissionForm = this.formBuilder.group({
      email: new FormControl(null, {
        validators: [Validators.pattern(this.commonService.emailRegex), Validators.required],
        updateOn: 'change',
      }),
      brief: new FormControl(null, { validators: Validators.required, updateOn: 'change' }),
    });
  }

  onSubmit() {
    this.loadingRequest = true;
    if (this.briefSubmissionForm.valid) {
      const form = this.briefSubmissionForm.value;
      const notification = {
        email: form.email,
        subject: 'SoStereo Brief Submission',
        title: 'SoStereo Brief Submission',
        body: `<div><p>We'll send you an initial pull with relevant options shortly. In the meantime feel free to call us if you want to chat with our creative team.</p></b>
      <p><b>Brief: </b>${form.brief}</p>
      <p><b>Email: </b>${form.email}</p>
      </div>`,
        bodyTitle: 'Thank you for sharing your brief with us!',
      };
      const subdomain = this.commonService.getSubdomain();
      this.commonService.getCompany(subdomain, (company) => {
        if (company) {
          const companyName = this.titleCasePipe.transform(company.name);
          notification.subject = `${companyName} Brief Submission`;
          notification.title = `${companyName} Brief Submission`;
        }
        this.sendNotification(notification);
      });
    }
  }

  private sendNotification(notification) {
    const form = this.briefSubmissionForm.value;
    this.notificationService.post(notification).subscribe({
      next: () => {
        this.loadingRequest = false;
        this.briefSubmissionForm.reset();
        this.location.go('/submit-brief-success');
        this.addAlert({ type: 'success', message: 'Your brief has been submitted!' });
        this.trackingService.track(
          'Brief Submission Success',
          {
            email: form?.email,
            brief: form?.brief,
          },
          {
            event_action: 'Brief submission sent',
            event_type: 'Form Submission',
            element_type: 'Form',
            event_value: form?.brief,
            event_user_email: form?.email,
          },
        );
        this.trackingService.trackingAds({
          gTag: { id: 'aAroCJzE9eYYEO75s58p' },
          linkedIn: { id: '14169202' },
        });
        this.pixelRequest = true;
      },
      error: (err) => {
        this.loadingRequest = false;
        this.briefSubmissionForm.reset();
        this.trackingService.track('Brief Submission Error', {
          error: err,
        });
        this.addAlert({
          type: 'danger',
          message: "We couln't send your request, please try again!",
        });
      },
    });
  }

  private addAlert(alert: any): void {
    this.alerts.push({
      type: alert.type,
      message: alert.message,
      timeout: 5000,
    });
  }

  public onAlertClosed(dismissedAlert: AlertComponent): void {
    this.alerts = this.alerts.filter((alert) => alert !== dismissedAlert);
  }

  public showModal() {
    this.modalRef = this.modalService.open(this.modalTemplate, {
      panelClass: ['modal', 'material'],
      disableClose: true,
      maxWidth: 'min(90vw, 1200px)',
      role: 'dialog',
      closeOnNavigation: true,
      ariaModal: true,
      maxHeight: '95svh',
    });
    this.trackingService.track(
      'Brief Submission Start',
      {
        action: 'Modal Open',
        kind: 'Brief submission modal',
      },
      {
        event_action: 'Brief submission opened',
        event_type: 'Modal Opened',
      },
    );
  }

  public hideModal() {
    this.modalRef.close('closed');
    setTimeout(() => {
      this.resetComponent();
    }, 500);
    this.trackingService.track(
      'Brief Submission Hide',
      {},
      {
        event_action: 'Brief submission hidden',
        event_type: 'Modal Hidden',
      },
    );
  }

  public cancel() {
    this.modalRef.close('closed');
    setTimeout(() => {
      this.resetComponent();
    }, 500);
    this.trackingService.track(
      'Brief Submission Cancel',
      {},
      {
        event_action: 'Brief submission canceled',
        event_type: 'Modal Closed',
      },
    );
  }
}
