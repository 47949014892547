<ng-template #copyPlaylistModal>
  <div class="modal-body" *ngIf="!requestSuccess" [@inOutAnimation]>
    <div class="row">
      <div class="col-md-12">
        <button type="button" class="close" (click)="hideModal()">
          <span class="sr-only">Close</span><span class="ss-icon-close"></span>
        </button>
        <div class="clearfix mb20 text-center">
          <h2 class="modal-title mb0">{{ modalTitle }}</h2>
          <p class="mt20">Make a copy of this playlist to your library</p>
        </div>
      </div>
    </div>
    <form [formGroup]="copyPlaylistForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="email">Name </label>
        <input type="text" formControlName="name" class="form-control" id="email" />
        <div
          *ngIf="
            copyPlaylistForm.controls['name'].touched && copyPlaylistForm.controls['name'].errors
          "
        >
          <app-inline-errors [errors]="['this field is required']"></app-inline-errors>
        </div>
      </div>
      <div class="form-group" *ngIf="showCopyComments">
        <label>Copy Comments?</label>
        <div class="radio pb5">
          <label>
            <input type="radio" formControlName="copyComments" value="true" />
            <span class="radios-text" style="height: 20px"> Yes </span>
          </label>
          <label class="mt5">
            <input type="radio" formControlName="copyComments" value="false" />
            <span class="radios-text" style="height: 20px"> No </span>
          </label>
        </div>
      </div>
      <div class="clearfix">
        <div class="form-group col-sm-12" align="center">
          <button
            [disabled]="loadingRequest || copyPlaylistForm.invalid"
            type="submit"
            class="btn primary-btn mt20"
          >
            copy
            <i class="spin medium" *ngIf="loadingRequest"></i>
          </button>
        </div>
        <div *ngFor="let alert of alerts">
          <alert
            [type]="alert.type"
            [dismissOnTimeout]="alert.timeout"
            (onClosed)="onAlertClosed(alert)"
            >{{ alert.message }}</alert
          >
        </div>
      </div>
    </form>
  </div>
  <div class="modal-body" *ngIf="requestSuccess" [@inOutAnimation]>
    <div class="row text-center">
      <div class="col-md-12">
        <h4 class="modal-title text-center">
          <span style="font-size: 70px" class="glyphicon glyphicon-ok-circle font-20x"></span>
        </h4>
        <h2>Playlist copied successfully!</h2>
      </div>
    </div>
    <div class="row text-center">
      <div class="col-md-12">
        <p>Now you can see it in your playlists</p>
        <br /><br />
      </div>
    </div>
    <div class="row text-center">
      <div class="col-md-12">
        <button
          id="discoverBtn"
          type="submit"
          class="btn primary-btn mt20 mb20"
          (click)="hideModal()"
        >
          Ok
        </button>
      </div>
    </div>
  </div>
</ng-template>
