import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {
  PlaylistsStereoSpacesService,
  ProjectsService,
  SubmissionService,
  AuthenticationService,
} from 'sostereo-services';
import { ToastrService } from 'ngx-toastr';
import { uniqBy, map, cloneDeep } from 'lodash-es';
import { CreatePlaylistModalComponent } from '../create-playlist-modal/create-playlist-modal.component';
import { TrackingService } from '../../../../shared/services/tracking.service';
import { CommonService } from '../../../../shared/services/common.service';
import { forkJoin, Subscription, Subject, of, Observable } from 'rxjs';
import {
  finalize,
  switchMap,
  debounceTime,
  distinctUntilChanged,
  catchError,
  retry,
} from 'rxjs/operators';
import { PlayerService } from '../../../../shared/services/player.service';
import { PlaylistService } from '../../../../shared/services/playlist.service';
import { SongStatusModalComponent } from '../song-status-modal/song-status-modal.component';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-add-to-playlist-modal',
  templateUrl: './add-to-playlist-modal.component.html',
  styleUrls: ['./add-to-playlist-modal.component.scss'],
})
export class AddToPlaylistModalComponent implements OnInit, OnDestroy {
  @ViewChild('addToPlaylistModal', { static: true })
  modalTemplate: TemplateRef<any>;

  @ViewChild('createPlaylistModal', { static: true })
  createPlaylistModal: CreatePlaylistModalComponent;

  @ViewChild('songStatusModalComponent', { static: true })
  private songStatusModal: SongStatusModalComponent;

  @Input() tracksInput: any[];
  @Input() playlistDropDown;
  @Input() dropDownSettings: any = {};
  @Input() hidden = false; // When only the logic is relevant but not the actual modal
  @Input() isGeneric; // flag to know if the logic should be used here
  @Input() emitResults; // if isGeneric, this flag is used to emit every action (TrackAdded,PlaylistCreated,etc..)
  // If the isGeneric is false the @Output's can be used to make the get services from the view that is using the modal
  @Output() playlistSearch = new EventEmitter();
  @Output() addToPlaylistSuccess = new EventEmitter();
  @Output() addToSpaceSuccess = new EventEmitter();
  @Output() addToPlaylistError = new EventEmitter();
  @Output() createPlaylistSuccess = new EventEmitter();
  @Output() createPlaylistError = new EventEmitter();
  @Output() unmodified = new EventEmitter();

  public modalRef: BsModalRef;
  disableBtn: boolean;
  requestSuccess: boolean;
  requestError: boolean;
  public selectedPlaylist: any = {};
  public dropDownOpen = false;
  public playlistTyped = '';
  public dropdownLoading = false;
  public noResults = false;
  public loadingRequest = false;
  public autoSelectedPlaylist = true;
  private listSpaces = this.commonService.isAllowed(['ListSpaces']);
  private searchTypingTimeout = null;
  private accessData$: Subscription = new Subscription();
  private searchQuery;
  private playlistQuery;
  public playlistObserver$: Subject<any> = new Subject();
  private subscriptionHide$: Subscription;
  public tracks: any;
  private user = this.authenticationService.getCurrentUser();
  private inputPlaylistValue = null;
  private emptyPlaylist = false;

  constructor(
    private modalService: BsModalService,
    private toastr: ToastrService,
    private trackingService: TrackingService,
    private commonService: CommonService,
    private authenticationService: AuthenticationService,
    private playlistsStereoSpacesService: PlaylistsStereoSpacesService,
    private projectsService: ProjectsService,
    public playerService: PlayerService,
    public playlistService: PlaylistService,
    private submissionService: SubmissionService,
    public deviceDetectorService: DeviceDetectorService,
  ) {}

  ngOnInit() {
    this.clearItemsDropDown();
    this.accessData$ = this.authenticationService.accessData.subscribe(() => {
      this.listSpaces = this.commonService.isAllowed(['ListSpaces']);
      this.clearItemsDropDown();
      if (this.isGeneric) {
        this.loadDropDown({});
      }
    });

    this.setPlaylistObserver();
  }

  clearItemsDropDown() {
    if (this.isGeneric) {
      this.playlistDropDown = {
        playlists: { items: [], name: 'My Playlists' },
        playlistsTeam: { items: [], name: 'Team Playlists' },
        projects: { items: [], name: 'Projects' },
      };
    }
  }

  ngOnDestroy() {
    this.accessData$.unsubscribe();
    this.playlistObserver$.unsubscribe();
    this.subscriptionHide$?.unsubscribe();
  }

  public showModal() {
    this.tracks = cloneDeep(this.tracksInput);
    this.user = this.authenticationService.getCurrentUser();
    const statusSong = this.tracksInput.filter((song) => this.showStatusSong(song));
    if (statusSong.length > 0 && this.commonService.isAllowed(['*', 'ListTiers'])) {
      this.songStatusModal.tracks = [...statusSong];
      this.songStatusModal.showModal();
    } else {
      this.showModalPlaylist();
    }
  }

  public showModalPlaylist() {
    this.clearItemsDropDown();
    this.trackingService.track(
      'Add track from playlist modal open',
      {
        action: 'Add track from playlist modal open',
        kind: 'Modal Open',
        song: this.tracks.map((track) => track._id || track.trackId),
      },
      {
        event_action: 'Add track from playlist modal open',
        event_type: 'Modal Opened',
        event_value: this.tracks?.[0].trackId,
      },
    );
    this.autoSelectedPlaylist = true;
    if (!this.hidden) {
      this.modalRef = this.modalService.show(this.modalTemplate, {
        class: 'modal-md',
      });
    }

    if (!this.subscriptionHide$) {
      this.subscriptionHide$ = this.modalService.onHide.subscribe((reason: string) => {
        if (reason === 'backdrop-click') {
          this.selectedPlaylist = {};
          this.inputPlaylistValue = null;
          this.playlistTyped = '';
        }
      });
    }

    if (this.isGeneric) {
      this.getLastPlaylist();
    }
  }

  getLastPlaylist() {
    let playlistObs: Observable<any> = null;
    let fun = null;
    const searchQuery: any = {
      sort: 'updatedAt asc',
      limit: 1,
    };
    const playlist = this.playlistService.getLastPlaylist();
    if (playlist) {
      searchQuery._id = playlist.id;
    }

    switch (playlist?.type || 'my-playlist') {
      case 'project':
        searchQuery.category = 'all';
        playlistObs = this.getProjectListByQuery(searchQuery);
        fun = this.mapSpacesResponse.bind(this);
        break;
      case 'team':
        const teamQuery = cloneDeep(searchQuery);
        teamQuery.allowTrackAdditions = 'team';
        teamQuery.groups = this.user.groups.join(',');
        teamQuery.filter = `ne ownerUid ${this.user.uid}`;
        teamQuery.collaborative = false;
        playlistObs = this.getPlaylistsByQuery(teamQuery);
        fun = this.mapPlaylistTeamResponse.bind(this);
        break;
      default:
        if (this.user?.hasOwnProperty('uid')) {
          searchQuery.ownerUid = this.user.uid;
        }
        searchQuery.collaborative = false;
        playlistObs = this.getPlaylistsByQuery(searchQuery);
        fun = this.mapPlaylistResponse.bind(this);
        break;
    }

    playlistObs.subscribe({
      next: (res) => {
        fun(res);
      },
    });
  }

  public hideModal() {
    this.selectedPlaylist = {};
    this.playlistTyped = '';
    this.inputPlaylistValue = null;
    this.modalRef?.hide();
  }

  public showCreatePlaylistModal() {
    this.hideModal();
    this.createPlaylistModal.showModal();
  }

  private trackSongAdded(event, song: any) {
    this.trackingService.track(
      'Track added successfully to playlist',
      {
        song: song.name || song.songName || song.fileName,
        artists:
          song.artists && song.artists.length > 0
            ? map(song.artists, 'artistName').join(', ')
            : song.artistName,
        playlistId: event?.data?._id ? event?.data?._id : event?.data,
        playlistName: event?.data?.name ? event?.data?.name : event?.data,
      },
      {
        event_action: 'Track added to playlist',
        event_type: 'Song',
        event_value: song.name || song.songName || song.fileName,
        element_type: 'Playlist',
        element_value: event?.data?.name,
        element_id: event?.data?._id,
      },
    );
  }

  getBodyTracks() {
    this.tracks.forEach((track) => {
      if (track.soId && track._id && !track.hasOwnProperty('uri')) {
        const tenantId = this.user.tenantId;
        track.uri = `${tenantId}:core:songs:${track.soId}/${track._id}`;
      }
    });

    const body = this.tracks.every((track) => track.hasOwnProperty('uri') && track.uri)
      ? { uris: [...this.tracks.map((track) => track.uri)] }
      : { trackPayload: true, tracks: [...this.tracks] };

    return body;
  }

  onCreatePlaylistSuccess(event) {
    // let isFirstPlaylist = !this.hasPlaylists && !this.hasSpaces;
    if (this.isGeneric) {
      const body = this.getBodyTracks();
      this.playlistService.setLastPlaylist({ id: event.data._id, type: 'my-playlist' });
      this.playlistsStereoSpacesService.addTracks(event.data._id, body).subscribe({
        next: () => {
          this.toastr.success(`Track added successfully to ${event.data.name}`, '');
          this.tracks.forEach((track) => {
            this.trackSongAdded(event, track);
          });

          if (this.emitResults) {
            this.createPlaylistSuccess.emit();
            this.playerService.hideSearchTab.next(true);
          }
          // Opens new PL detail to the left side
          if (!(this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet())) {
            this.playerService.showPlaylistMenu.next(true);
            this.playlistService.playlistMenuContainer.next({
              display: true,
              playlist: event.data,
            });
          }

          // Triggers event to update left side playlists
          this.playlistService.updatePlaylist.next({
            ...event.data,
            totalSongs: 1,
            justCreated: true,
          });

          this.createPlaylistModal.hideModal();
        },
        error: (error) => {
          this.toastr.error(
            error?.error?.message,
            'There was an error adding your song to the playlist',
          );
          this.trackingService.track('Error adding the song to the playlist', {
            songs: body,
            playlistId: event?.data?._id ? event?.data?._id : event?.data,
            playlistName: event?.data?.name ? event?.data?.name : event?.data?.name,
            error: error,
          });
          if (this.emitResults) {
            this.createPlaylistError.emit();
            this.playerService.hideSearchTab.next(true);
          }
        },
      });
    } else {
      this.createPlaylistSuccess.emit(event);
    }
  }

  onCreatePlaylistError(event) {
    if (this.isGeneric) {
      this.toastr.error(event, 'There was an error adding your song to the playlist');
      if (this.emitResults) {
        this.createPlaylistError.emit();
      }
    } else {
      this.createPlaylistError.emit(event);
    }
  }

  public addSongToPlaylist() {
    const pl = this.selectedPlaylist;
    this.inputPlaylistValue = null;
    this.playlistService.setLastPlaylist({ id: this.selectedPlaylist.id, type: pl.category });
    if (pl.category === 'project') {
      this.addSongToSpace(pl);
    } else {
      const body = this.getBodyTracks();
      this.loadingRequest = true;
      this.playlistsStereoSpacesService
        .addTracks(this.selectedPlaylist.id, body)
        .pipe(
          finalize(() => {
            this.loadingRequest = false;
          }),
        )
        .subscribe({
          next: (res) => {
            if (res.data.nModified === 1 || res.data.modifiedCount === 1) {
              if (this.isGeneric) {
                this.hideModal();
                this.toastr.success('Track added successfully', '');
                if (this.emitResults) {
                  this.addToPlaylistSuccess.emit({
                    playlist: pl,
                    playlistId: pl.id,
                    tracks: this.tracks,
                  });
                }
              } else {
                this.addToPlaylistSuccess.emit({
                  playlistId: this.selectedPlaylist.id,
                });
              }

              this.tracks.forEach((track) => {
                this.trackSongAdded({ data: { id: pl?.id, name: pl?.itemName } }, track);
              });

              this.playlistService.updatePlaylist.next({ ...pl, _id: pl.id });
            } else {
              if (this.isGeneric) {
                this.toastr.warning('Track already in playlist', '');
                if (this.emitResults) {
                  this.unmodified.emit();
                }
              } else {
                this.unmodified.emit({ playlistId: this.selectedPlaylist.id });
              }
            }
          },
          error: (err) => {
            if (this.isGeneric) {
              this.toastr.error(
                err?.error?.message,
                'There was an error adding your song to the playlist',
              );
            } else {
              this.addToPlaylistError.emit(err);
            }
            this.trackingService.track('Error adding the song to the playlist', {
              song: body,
              playlistId: pl.id ? pl.id : pl,
              playlistName: pl.itemName ? pl.itemName : pl,
              error: err,
            });
          },
        });
    }
  }

  private createBodySong(loggedUser: any, space: any, track: any): any {
    return {
      message: 'SoStereo',
      senderCompany: `${loggedUser.firstName} ${loggedUser.lastName}`, // companyName,
      senderEmail: loggedUser.email,
      senderName: `${loggedUser.firstName} ${loggedUser.lastName}`,
      spaceId: space.id,
      targetId: space.id,
      targetType: 'spaces',
      fileName: `${track.songName || track.name || track.fileName} by ${
        track.artists ? track.artists.map((a) => a.artistName).join(', ') : track.artistName
      }`,
    };
  }

  addSongToSpace(space) {
    const loggedUser = this.user;
    this.tracks.forEach((track) => {
      const body = this.createBodySong(loggedUser, space, track);
      if (track.soId && track._id && !track.hasOwnProperty('uri')) {
        const tenantId = this.user.tenantId;
        track.uri = `${tenantId}:core:songs:${track.soId}/${track._id}`;
      }
      if (track.uri) {
        body.uris = [track.uri];
      } else {
        body.tracks = [track];
      }
      this.loadingRequest = true;
      this.submissionService
        .post(body)
        .pipe(
          finalize(() => {
            this.loadingRequest = false;
          }),
        )
        .subscribe({
          next: () => {
            this.hideModal();
            this.toastr.success('Track added successfully', '');
            this.addToSpaceSuccess.emit({ spaceId: body.spaceId });
          },
          error: (err) => {
            if (this.isGeneric) {
              this.toastr.error(
                err?.error?.message,
                'There was an error adding your song to the playlist',
              );
              if (this.emitResults) {
                this.addToPlaylistError.emit();
              }
            } else {
              this.addToPlaylistError.emit(err);
            }
            this.trackingService.track('Error adding the song to the playlist', {
              song: track,
              spaceId: space?.id,
              spaceName: space?.name,
              error: err,
            });
          },
        });
    });
  }

  onSearch(event) {
    if (event.keyCode == 37 || event.keyCode == 38 || event.keyCode == 39 || event.keyCode == 40) {
      return;
    }
    clearTimeout(this.searchTypingTimeout);
    this.searchTypingTimeout = setTimeout(() => {
      if (this.isGeneric) {
        if (event.target.value !== '') {
          this.loadDropDown({
            name: new RegExp(this.commonService.regExpEscape(event.target.value), ''),
          });
        } else {
          this.loadDropDown({});
        }
      } else {
        this.playlistSearch.emit({ searchText: event.target.value });
      }
    }, 200);
  }

  openOptions(event) {
    if (this.inputPlaylistValue !== event.target.value) {
      this.inputPlaylistValue = event.target.value;
      if (event.target.value !== '') {
        this.loadDropDown({
          name: new RegExp(this.commonService.regExpEscape(event.target.value), ''),
        });
      } else {
        this.loadDropDown({});
      }
    }
  }

  private getPlaylistsByQuery(playlistQuery) {
    return this.playlistsStereoSpacesService.query(playlistQuery).pipe(
      retry(2),
      catchError((err) => {
        this.toastr.error(err?.error?.message, 'There was an error loading playlist');
        this.trackingService.track('playlistsStereoSpacesService error', {
          error: err,
          searchQuery: this.searchQuery,
        });
        return of({});
      }),
    );
  }

  private getProjectListByQuery(projectsQuery) {
    return this.projectsService.query(projectsQuery).pipe(
      retry(2),
      catchError((err) => {
        this.toastr.error(err?.error?.message, 'There was an error loading projects');
        this.trackingService.track('projectService error', {
          error: err,
          searchQuery: this.searchQuery,
        });
        return of({});
      }),
    );
  }

  private setSpacesRequest(searchQuery: any, servicesCall: Observable<any>[]) {
    if (this.listSpaces) {
      const projectsQuery = cloneDeep(searchQuery);
      delete projectsQuery.collaborative;
      if (projectsQuery.hasOwnProperty('ownerUid')) {
        delete projectsQuery.ownerUid;
      }
      if (projectsQuery.hasOwnProperty('name')) {
        projectsQuery.projectName = projectsQuery.name;
        delete projectsQuery.name;
      }
      projectsQuery.category = 'all';
      servicesCall.push(this.getProjectListByQuery(projectsQuery));
    }
  }

  private setTeamPlaylistRequest(searchQuery: any, servicesCall: Observable<any>[]) {
    if (this.user?.groups.length > 0 && this.user?.uid) {
      const teamQuery = cloneDeep(searchQuery);
      teamQuery.allowTrackAdditions = 'team';
      teamQuery.groups = this.user.groups.join(',');
      teamQuery.filter = `ne ownerUid ${this.user.uid}`;
      servicesCall.push(this.getPlaylistsByQuery(teamQuery));
    }
  }

  loadDropDown(searchQuery) {
    searchQuery.sort = 'updatedAt asc';
    searchQuery.collaborative = false;
    this.dropdownLoading = true;
    this.noResults = false;
    for (const category in this.playlistDropDown) {
      this.playlistDropDown[category].items = [];
    }
    const playlistQuery = cloneDeep(searchQuery);
    if (this.user?.hasOwnProperty('uid')) {
      playlistQuery.ownerUid = this.user.uid;
    }

    const servicesCall = [this.getPlaylistsByQuery(playlistQuery)];
    this.setSpacesRequest(searchQuery, servicesCall);
    this.setTeamPlaylistRequest(searchQuery, servicesCall);

    if (this.commonService.isAllowed(['*', 'ListPlaylists', 'ListMixtapes'])) {
      this.searchQuery = searchQuery;
      this.playlistQuery = playlistQuery;
      this.playlistObserver$.next(servicesCall);
    }
  }

  private setPlaylistObserver() {
    this.playlistObserver$
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        finalize(() => (this.dropdownLoading = false)),
        switchMap((servicesCall) => {
          return forkJoin(servicesCall);
        }),
      )
      .subscribe((responses: any[]) => {
        this.dropdownLoading = false;
        if (responses.every((response) => !response.data || response.data.items?.length === 0)) {
          this.noResults = true;
          this.emptyPlaylist = !this.playlistQuery?.hasOwnProperty('name');
        } else {
          this.mapPlaylistResponse(responses[0]);
          if (this.listSpaces) {
            this.mapSpacesResponse(responses[1]);
            this.mapPlaylistTeamResponse(responses?.[2] || {});
          } else {
            this.mapPlaylistTeamResponse(responses?.[1] || {});
          }
        }
      });
  }

  mapPlaylistResponse(playlistResponse) {
    if (playlistResponse.data?.items?.length > 0) {
      const list = playlistResponse.data.items?.map((p) => {
        return {
          itemName: p.name,
          slug: p.slug,
          id: p._id,
          totalSongs: p.totalSongs,
          category: 'my-playlist',
        };
      });
      this.playlistDropDown.playlists.items = uniqBy(list, 'id');
      if (!this.selectedPlaylist?.id) {
        this.selectedPlaylist = cloneDeep(list[0]);
      }
    }
  }

  mapPlaylistTeamResponse(playlistResponse) {
    if (playlistResponse.data?.items?.length > 0) {
      const list = playlistResponse.data.items?.map((p) => {
        return {
          itemName: p.name,
          slug: p.slug,
          id: p._id,
          totalSongs: p.totalSongs,
          category: 'team',
        };
      });
      this.playlistDropDown.playlistsTeam.items = uniqBy(list, 'id');
      if (!this.selectedPlaylist?.id) {
        this.selectedPlaylist = cloneDeep(list[0]);
      }
    }
  }

  private mapSpacesResponse(spacesResponse) {
    const list =
      spacesResponse?.data?.items?.map((space) => ({
        itemName: `${space.projectName} - ${space.name}`,
        id: space._id,
        category: 'project',
      })) || [];

    this.playlistDropDown.projects.items = list;
    if (!this.selectedPlaylist?.id) {
      this.selectedPlaylist = cloneDeep(list[0]);
    }
  }

  onKeydown(event) {
    event.stopPropagation();
  }

  public clickOutside(event) {
    const options = event.target.classList.contains('category-title');
    if (!this.playerService.isSmallSize && !options) {
      this.dropDownOpen = false;
    }
  }

  private showStatusSong(song): boolean {
    return (
      !!song.notes ||
      song.tierHasWarning ||
      song.publicAccess === false ||
      song.isOneStop == false ||
      song.releaseStatus === 'unreleased' ||
      song.customizable === 'no' ||
      song.stemsAvailable === 'no' ||
      song?.copyrightWarning?.length > 0
    );
  }
}
