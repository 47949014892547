import { Component, Inject, Input, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TrackingService } from '../../../../shared/services/tracking.service';
import { ClipboardService } from 'ngx-clipboard';
import { CommonService } from 'src/app/angular/shared/services/common.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-share-playlist-modal',
  templateUrl: './share-playlist-modal.component.html',
  styleUrls: ['./share-playlist-modal.component.scss'],
})
export class SharePlaylistModalComponent {
  @ViewChild('sharePlaylistModal', { static: true })
  private modalTemplate: TemplateRef<any>;

  @Input()
  public playlistData;

  public url;
  private modalRef: BsModalRef;
  public copied = false;
  public showCatalog = this.commonService.isAllowed(['UpdateAgreement']);
  public params = [
    {
      key: 'showLyrics',
      param: 'lyrics=open',
      show: false,
    },
    {
      key: 'catalog',
      param: 'catalog=sostereo',
      show: this.showCatalog,
    },
  ];

  constructor(
    @Inject('environment') private environment,
    private modalService: BsModalService,
    private renderer: Renderer2,
    private trackingService: TrackingService,
    private clipBoardService: ClipboardService,
    public commonService: CommonService,
    public deviceDetectorService: DeviceDetectorService,
  ) {}

  public showModal() {
    this.createUrl();
    this.modalRef = this.modalService.show(this.modalTemplate, {
      class: 'modal-md',
      ignoreBackdropClick: true,
    });
    this.trackingService.track(
      'Share Playlist Start',
      {},
      {
        event_action: 'Share playlist modal opened',
        event_type: 'Modal Opened',
      },
    );
  }

  public modifyUrl(key: string) {
    this.params.forEach((element) => {
      if (element.key == key) element.show = !element.show;
    });
    this.createUrl();
  }

  private createUrl() {
    const params = this.params
      .filter((element) => element.show)
      .map((element) => element.param)
      .join('&');
    this.url = `https://${window.location.host}/music-sync-licensing/playlist/${
      this.playlistData.slug
    }${params ? '?' + params : ''}`;
  }

  public copyUrlToClipboard() {
    this.copied = true;
    const btn = document.getElementById('playlistUrlCopyBtn');
    this.highlightButton(btn);
    setTimeout(() => {
      this.copied = false;
    }, 3000);
    this.clipBoardService.copy(this.url);
    this.trackingService.track(
      'Playlist link copied to clipboard (from right side)',
      { copiedLink: this.url },
      {
        event_action: 'Playlist link copied to clipboard (from right side)',
        event_type: 'Link Copied',
        event_value: this.url,
      },
    );
  }

  private highlightButton(btn) {
    this.renderer.addClass(btn, 'highlight');
    setTimeout(() => {
      this.renderer.addClass(btn, 'highlight-fade');
    }, 2000);
    setTimeout(() => {
      this.renderer.removeClass(btn, 'highlight');
      this.renderer.removeClass(btn, 'highlight-fade');
    }, 4000);
  }

  public cancel() {
    this.modalRef.hide();
    this.trackingService.track(
      'Share Playlist Closed',
      {},
      {
        event_action: 'Share playlist modal closed',
        event_type: 'Modal Closed',
      },
    );
    // The timeout is so the checkbox doesn't get selelect/unselected while closing the model but after
    setTimeout(() => {
      this.params = [
        {
          key: 'showLyrics',
          param: 'lyrics=open',
          show: false,
        },
        {
          key: 'catalog',
          param: 'catalog=sostereo',
          show: this.showCatalog,
        },
      ];
    }, 1000);
  }
}
