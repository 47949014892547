import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { ContactUsModalComponent } from './components/contact-us-modal/contact-us-modal.component';
import { CustomServicesModalComponent } from './components/custom-services-modal/custom-services-modal.component';
import { SessionExpiredModalComponent } from './components/session-expired-modal/session-expired-modal.component';
import { GenericModalComponent } from './components/generic-modal/generic-modal.component';
import { AddToPlaylistModalComponent } from './components/add-to-playlist-modal/add-to-playlist-modal.component';
import { CreatePlaylistModalComponent } from './components/create-playlist-modal/create-playlist-modal.component';
import { UserSignUpModalComponent } from './components/user-sign-up-modal/user-sign-up-modal.component';
import { BriefSubmissionModalComponent } from './components/brief-submission-modal/brief-submission-modal.component';
import { DownloadArtistModalComponent } from './components/download-artist-modal/download-artist-modal.component';
import { SendGiftModalComponent } from './components/send-gift-modal/send-gift-modal.component';
import { DownloadPlaylistConfirmationModalComponent } from './components/download-playlist-confirmation-modal/download-playlist-confirmation-modal.component';
import { CompleteAccountModalComponent } from './components/quick-signup-modal/components/complete-account-modal/complete-account-modal.component';
import { SongRightholdersInfoModalComponent } from './components/song-rightholders-info-modal/song-rightholders-info-modal.component';
import { ResetPasswordModalComponent } from './components/reset-password-modal/reset-password-modal.component';
import { SharePlaylistModalComponent } from './components/share-playlist-modal/share-playlist-modal.component';
import { LicenseRequestModalComponent } from './components/license-request-modal/license-request-modal.component';
import { CustomLicenseRequestModalComponent } from './components/custom-license-request-modal/custom-license-request-modal.component';
import { SongTagsUpdateModalComponent } from './components/song-tags-update-modal/song-tags-update-modal.component';
import { InvitePeopleModalComponent } from './components/invite-people-modal/invite-people-modal.component';
import { CopyPlaylistModalComponent } from './components/copy-playlist-modal/copy-playlist-modal.component';
import { CreatePolicyModalComponent } from './components/create-policy-modal/create-policy-modal.component';
import { CreateIdentityModalComponent } from './components/create-identity-modal/create-identity-modal.component';
import { RouterModule } from '@angular/router';
import { TooltipConfig, TooltipModule } from 'ngx-bootstrap/tooltip';
import { AlertConfig, AlertModule } from 'ngx-bootstrap/alert';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RequestDemoModalComponent } from './components/request-demo-modal/request-demo-modal.component';
import { TermsModalComponent } from './components/terms-modal/terms-modal.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { SongInternalNotesModalComponent } from './components/song-internal-notes-modal/song-internal-notes-modal.component';
import { ClipboardModule } from 'ngx-clipboard';
import { MagicLinkModalComponent } from './components/magic-link-modal/magic-link-modal.component';
import { SongStatusModalComponent } from './components/song-status-modal/song-status-modal.component';
import { EditPlaylistModalComponent } from './components/edit-playlist-modal/edit-playlist-modal.component';
import { AccountRequestModalComponent } from './components/account-request-modal/account-request-modal.component';
import { LicenseAssignationModalComponent } from './components/license-assignation-modal/license-assignation-modal.component';
import { RequestSonicDemoModalComponent } from './components/request-sonic-demo-modal/request-sonic-demo-modal.component';
import { SongRestrictionModalComponent } from './components/song-restriction-modal/song-restriction-modal.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown-ivy';
import { InlineErrorsComponent } from '../../shared/components/inline-errors/inline-errors.component';
import { MsToTimeFormatPipe } from '../../shared/pipes/ms-to-time.pipe';
@NgModule({
  declarations: [
    ContactUsModalComponent,
    CustomServicesModalComponent,
    SessionExpiredModalComponent,
    GenericModalComponent,
    AddToPlaylistModalComponent,
    CreatePlaylistModalComponent,
    UserSignUpModalComponent,
    BriefSubmissionModalComponent,
    DownloadArtistModalComponent,
    SendGiftModalComponent,
    DownloadPlaylistConfirmationModalComponent,
    CompleteAccountModalComponent,
    SongRightholdersInfoModalComponent,
    ResetPasswordModalComponent,
    SharePlaylistModalComponent,
    LicenseRequestModalComponent,
    CustomLicenseRequestModalComponent,
    SongTagsUpdateModalComponent,
    InvitePeopleModalComponent,
    CopyPlaylistModalComponent,
    CreatePolicyModalComponent,
    CreateIdentityModalComponent,
    RequestDemoModalComponent,
    RequestSonicDemoModalComponent,
    TermsModalComponent,
    SongInternalNotesModalComponent,
    MagicLinkModalComponent,
    SongStatusModalComponent,
    EditPlaylistModalComponent,
    MsToTimeFormatPipe,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    TooltipModule,
    FormsModule,
    AlertModule,
    ProgressbarModule,
    RouterModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    ClipboardModule,
    AccountRequestModalComponent,
    LicenseAssignationModalComponent,
    SongRestrictionModalComponent,
    AngularMultiSelectModule,
    InlineErrorsComponent,
  ],
  exports: [
    ContactUsModalComponent,
    CustomServicesModalComponent,
    SessionExpiredModalComponent,
    GenericModalComponent,
    AddToPlaylistModalComponent,
    CreatePlaylistModalComponent,
    UserSignUpModalComponent,
    BriefSubmissionModalComponent,
    DownloadArtistModalComponent,
    DownloadPlaylistConfirmationModalComponent,
    SharePlaylistModalComponent,
    LicenseRequestModalComponent,
    CustomLicenseRequestModalComponent,
    SongRightholdersInfoModalComponent,
    ResetPasswordModalComponent,
    SongTagsUpdateModalComponent,
    InvitePeopleModalComponent,
    CompleteAccountModalComponent,
    CopyPlaylistModalComponent,
    CreatePolicyModalComponent,
    CreateIdentityModalComponent,
    RequestDemoModalComponent,
    RequestSonicDemoModalComponent,
    TermsModalComponent,
    SongInternalNotesModalComponent,
    MagicLinkModalComponent,
    SongStatusModalComponent,
    EditPlaylistModalComponent,
  ],
  providers: [TooltipConfig, AlertConfig, TitleCasePipe],
})
export class ModalsModule {}
