<ng-template #downloadArtistModal>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <button type="button" class="close" (click)="hideModal()"><span class="sr-only">Close</span><span
            class="ss-icon-close"></span></button>
        <div class="clearfix mb40 text-center">
          <h2 class="modal-title">Artist Submission</h2>
          <p>Get a download link to your inbox </p>
        </div>
      </div>
    </div>
    <form [formGroup]="downloadArtistForm">
      <div class="form-group">
        <label for="inputEmail">Email*</label>
        <input type="email" formControlName="email" class="form-control" id="inputEmail">
        <app-inline-errors [errors]="['this field is required, must be a valid email']"
                           *ngIf="downloadArtistForm.controls['email'].errors &&
                            !downloadArtistForm.controls['email'].pristine"></app-inline-errors>
      </div>
    </form>
    <div class="clearfix">
      <label class="mt30 center-flex" *ngIf="disableBtn">Sending your request...</label>
      <div class="form-group col-sm-12 center-flex">
        <button type="submit" class="btn primary-btn col-sm-4 mt20" (click)="sendEmail()"
          [disabled]="disableBtn || downloadArtistForm.invalid || requestSuccess">Submit
        </button>
      </div>
      <p [hidden]="disableBtn || requestSuccess" class="mt10" style="text-align: center;">Or</p>
      <p style="text-align: center;">
        <a (click)="sendBrief()" class="mt10" [hidden]="disableBtn || requestSuccess">
          Send us your brief for a custom playlist within an hour</a>
      </p>
      <div class="form-group mt15 pl0 pr0 col-sm-12 col-xs-12" style="display: inline-block;">
        <div class="alert alert-success alert-dismissable" [hidden]="!requestSuccess">
          <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
          <strong>Your request has been sent!</strong>
        </div>
        <div class="alert alert-danger" [hidden]="!requestError">
          <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
          <strong>We couln't send your request, please try again!</strong>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<app-brief-submission-modal #briefSubmissionModalComponent></app-brief-submission-modal>
