<ng-template #userSignUpModal>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <button type="button" class="close" (click)="hideModal()"><span class="sr-only">Close</span><span
            class="ss-icon-close"></span></button>
        <h4 class="modal-title" id="signUpModal">Create an Account</h4>
      </div>
    </div>
    <div class="row text-left mt30">
      <p class="modal-subtitle pl15 pr15">Fill out the form below to add your email and password</p>
      <div class="col-md-12">
        <form [formGroup]="signUpForm" class="mt-0">
          <div class="form-group">
            <label for="inputEmail1">Email:</label>
            <input data-hj-allow type="email" formControlName="email" class="form-control" id="inputEmail1"
                   placeholder="Enter your email" [ngClass]="{'disabled':signUpLoading}">
            <app-inline-errors [errors]="errors?.email" [hidden]="!errors?.email"></app-inline-errors>
          </div>
          <div class="form-group">
            <label for="inputPassword1">Password:</label>
            <input type="password" formControlName="password" class="form-control" id="inputPassword1"
                   placeholder="Enter a password" [ngClass]="{'disabled':signUpLoading}">
            <app-inline-errors [errors]="errors?.password" [hidden]="!errors?.password"></app-inline-errors>
          </div>
          <div class="form-group">
            <label for="inputPassword2">Password Confirmation:</label>
            <input type="password" formControlName="confirmation" class="form-control" id="inputPassword2"
                   placeholder="Password confirmation" [ngClass]="{'disabled':signUpLoading}">
            <app-inline-errors [errors]="errors?.confirmation" [hidden]="!errors?.confirmation"></app-inline-errors>
          </div>
        </form>
        <p class="error-color">{{errors?.token}}</p>
        <p class="error-color" *ngIf="generalError !== ''">{{generalError}}</p>
      </div>
    </div>
    <div class="row mt20 form-group">
      <div class="col-md-12 text-center">
        <p>By submitting the form, you agree to our
          <a href="/terms-of-service" target="_blank">Terms of Service</a>,
          <a href="/privacy-policy" target="_blank">Privacy Policy</a> and
          <a href="/cookies-policy" target="_blank">Cookies Policy</a>.
        </p>
        <br>
        <button id="signUpBtn" type="submit" (click)="signUp()" [ngClass]="{'disabled': signUpLoading}"
                class="btn primary-btn mb0">
          {{signUpLoading ? 'Submitting' : 'Submit'}}
          <i class="spin medium loader-margin" *ngIf="signUpLoading"></i>
        </button>
      </div>
    </div>
  </div>
</ng-template>
