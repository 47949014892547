import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TrackingService } from '../../../../shared/services/tracking.service';
import { NotificationService } from 'sostereo-services';
import { Location } from '@angular/common';
import { animate, style, transition, trigger } from '@angular/animations';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AlertComponent } from 'ngx-bootstrap/alert';
import { CommonService } from '../../../../shared/services/common.service';

@Component({
  selector: 'app-send-gift-modal',
  templateUrl: './send-gift-modal.component.html',
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.5s ease-out', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class SendGiftModalComponent implements OnInit {
  @ViewChild('sendGiftModal', { static: true }) modalTemplate: TemplateRef<any>;
  public modalRef: BsModalRef;
  public sendGiftForm: FormGroup;
  public loadingRequest: boolean;
  public requestSuccess: boolean;
  public alerts: any[] = [];
  public pixelRequest = false;

  constructor(
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private location: Location,
    private trackingService: TrackingService,
    private commonService: CommonService,
    @Inject('environment') private environment,
  ) {
    this.createForm();
    this.loadingRequest = false;
    this.requestSuccess = false;
  }

  ngOnInit() {}

  private resetComponent() {
    this.sendGiftForm.reset();
    this.loadingRequest = false;
    this.requestSuccess = false;
  }

  private createForm() {
    this.sendGiftForm = this.formBuilder.group({
      name: new FormControl(null, { validators: Validators.required, updateOn: 'change' }),
      email: new FormControl(null, {
        validators: [Validators.pattern(this.commonService.emailRegex), Validators.required],
        updateOn: 'change',
      }),
      agencyName: new FormControl(null, { validators: Validators.required, updateOn: 'change' }),
      agencyAddress: new FormControl(null, { validators: Validators.required, updateOn: 'change' }),
    });
  }

  private onSubmit() {
    this.loadingRequest = true;
    if (this.sendGiftForm.valid) {
      const form = this.sendGiftForm.value;
      const notification = {
        email: form.email,
        subject: '3-Click Challenge Donut Box Request',
        title: 'Thanks for trying our challenge!',
        body: `<div>
          <p>As we promise we will send a box of donuts and get in touch to see what we missed! </p>
          <br>
          <p><b>Name: </b>${form.name}</p>
          <p><b>Email: </b>${form.email}</p>
          <p><b>Company: </b>${form.agencyName}</p>
          <p><b>Company Address: </b>${form.agencyAddress}</p>
          </div>`,
        bodyTitle: '',
      };
      this.notificationService.post(notification).subscribe(
        () => {
          this.loadingRequest = false;
          this.requestSuccess = true;
          this.trackingService.track('Send Gift Modal Success', {
            email: form?.email,
            agencyAddress: form?.agencyAddress,
          });
          this.pixelRequest = true;
        },
        (err) => {
          this.loadingRequest = false;
          this.trackingService.track('Send Gift Modal Error', {
            error: err,
          });
          this.addAlert({
            type: 'error',
            message: "We couln't send your request, please try again!",
          });
        },
      );
    }
  }

  private addAlert(alert: any): void {
    this.alerts.push({
      type: alert.type,
      message: alert.message,
      timeout: 5000,
    });
  }

  private onAlertClosed(dismissedAlert: AlertComponent): void {
    this.alerts = this.alerts.filter((alert) => alert !== dismissedAlert);
  }

  public showModal() {
    this.modalRef = this.modalService.show(this.modalTemplate, {
      class: 'modal-md',
      ignoreBackdropClick: true,
    });
    this.trackingService.track('Send Gift Modal Start', {});
    const modalContainers = document.getElementsByTagName('modal-container');
    for (let i = 0; i < modalContainers.length; i++) {
      modalContainers[i].className = 'modal fade in';
    }
  }

  public hideModal() {
    this.modalRef.hide();
    setTimeout(() => {
      this.resetComponent();
    }, 500);
    this.trackingService.track('Send Gift Modal Hide', {});
  }

  public cancel() {
    this.modalRef.hide();
    setTimeout(() => {
      this.resetComponent();
    }, 500);
    this.trackingService.track('Send Gift Modal Close', {});
  }
}
