import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  Inject,
  Output,
  EventEmitter,
} from '@angular/core';
import { transition, trigger, useAnimation } from '@angular/animations';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService, PolicyService, SongsService } from 'sostereo-services';
import { Location } from '@angular/common';
import { TrackingService } from '../../../../shared/services/tracking.service';
import { inOutAnimation } from '../../../../shared/animations/in-out.animation';
import { CommonService } from '../../../../shared/services/common.service';
import { uniqBy } from 'lodash-es';
import { selectableCompanyPolicies } from '../../../../shared/configs/policies-templates.config';
import { finalize } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AlertComponent } from 'ngx-bootstrap/alert';

@Component({
  selector: 'app-create-policy-modal',
  templateUrl: './create-policy-modal.component.html',
  styleUrls: ['./create-policy-modal.component.scss'],
  animations: [trigger('inOutAnimation', [transition(':enter', useAnimation(inOutAnimation))])],
})
export class CreatePolicyModalComponent implements OnInit {
  @ViewChild('createPolicyModal', { static: true }) modalTemplate: TemplateRef<any>;
  @Output() policyCreated = new EventEmitter();
  public groups = [];
  public companySlug = '';
  public modalRef: BsModalRef;
  public policyForm: FormGroup;
  public loadingRequest: boolean;
  public requestSuccess: boolean;
  public alerts = [];
  public totalOwnersArray = [];
  public ownersDropdown = [];
  public pixelRequest = false;
  public policiesDropdownData = selectableCompanyPolicies;
  public dropDownSettings = {
    text: 'Select Owners',
    enableSearchFilter: true,
    enableFilterSelectAll: false,
    enableCheckAll: false,
  };

  constructor(
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private location: Location,
    private trackingService: TrackingService,
    private policyService: PolicyService,
    private songService: SongsService,
    private commonService: CommonService,
    @Inject('environment') private environment,
  ) {
    this.createForm();
    this.loadingRequest = false;
    this.requestSuccess = false;
  }

  ngOnInit() {}

  private resetComponent() {
    this.policyForm.reset();
    this.loadingRequest = false;
    this.requestSuccess = false;
  }

  private createForm() {
    this.policyForm = this.formBuilder.group({
      name: new FormControl(null, Validators.required),
      templateObj: new FormControl(null),
      selectedOwners: new FormControl([]),
    });
  }

  public assignTemplateObject(templateValue) {
    const templateObj = this.policiesDropdownData.find((pt) => pt.value === templateValue);
    this.policyForm.get('templateObj').setValue(templateObj);
    if (templateObj.slug === 'contract-owner') {
      this.loadOwnersDropdown({});
    }
  }

  public onSubmit() {
    this.loadingRequest = true;
    const policyForm = this.policyForm.value;
    const tempPolicy = policyForm.templateObj;
    const policy: any = {
      name: tempPolicy.value,
      groups: this.groups,
      slugSrn: this.companySlug,
      variables: { label: tempPolicy.name },
      description: tempPolicy.description,
    };
    if (tempPolicy.slug === 'contract-owner') {
      policy.slugSrn = `${policy.slugSrn}:${this.companySlug}-${tempPolicy.slug}`;
      policy.groups = this.groups.length > 0 ? [`${this.groups[0]}-resources`] : this.groups;
      if (policyForm.selectedOwners.length === 0) {
        this.addAlert({ type: 'warning', message: 'You need to select at least 1 contract owner' });
        this.loadingRequest = false;
        return;
      }
      const tenantId = this.commonService.tenantId;
      const artistResources = policyForm.selectedOwners.map(
        (so) => `srn:core:${tenantId}:artists:owners/${so.itemName}`,
      );
      const songResources = policyForm.selectedOwners.map(
        (so) => `srn:core:${tenantId}:songs:contractOwner.email/${so.itemName}`,
      );
      const inquiriesResources = [
        `srn:notifications:${tenantId}:inquiries:ownerUid/$uid`,
        `srn:notifications:${tenantId}:inquiries:groups/${this.groups[0]}-resources`,
      ];
      policy.variables.artistResources = artistResources;
      policy.variables.songResources = songResources;
      policy.variables.inquiriesResources = inquiriesResources;
    }
    this.policyService
      .postTemplate(policy)
      .pipe(finalize(() => (this.loadingRequest = false)))
      .subscribe(
        () => {
          this.requestSuccess = true;
          this.policyCreated.emit();
        },
        (err) => {
          this.trackingService.track('Policy template creation Error', {
            error: err,
            policy: policy,
          });
          this.addAlert({
            type: 'danger',
            message: "We couldn't send your request, please try again!",
          });
        },
      );
  }

  private addAlert(alert: any): void {
    this.alerts.push({
      type: alert.type,
      message: alert.message,
      timeout: 5000,
    });
  }

  public onAlertClosed(dismissedAlert: AlertComponent): void {
    this.alerts = this.alerts.filter((alert) => alert !== dismissedAlert);
  }

  public showModal() {
    this.modalRef = this.modalService.show(this.modalTemplate, {
      class: 'modal-md',
      ignoreBackdropClick: true,
    });
    this.trackingService.track('Brief Submission Start', {});
    const modalContainers = document.getElementsByTagName('modal-container');
    for (let i = 0; i < modalContainers.length; i++) {
      modalContainers[i].className = 'modal fade in';
    }
  }

  private loadOwnersDropdown(searchQuery) {
    searchQuery.distinct = 'contractOwner';
    this.songService.query(searchQuery).subscribe((res) => {
      const owners = uniqBy(
        res.data.items.map((o, index) => ({ id: index, itemName: o.email })),
        'itemName',
      );
      this.totalOwnersArray = owners;
      this.ownersDropdown = owners;
    });
  }

  public onOwnersSearch(event) {
    const query = event.target.value;
    this.ownersDropdown = this.totalOwnersArray.filter((o) => o.itemName.includes(query));
  }

  public cancel() {
    this.modalRef.hide();
    setTimeout(() => {
      this.resetComponent();
    }, 500);
    this.trackingService.track('Brief Submission Cancel', {});
  }
}
