import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ArtistService,
  AuthenticationService,
  IdentityService,
  PolicyService,
  ProjectsService,
  UserService,
} from 'sostereo-services';
import { ToastrService } from 'ngx-toastr';
import { TrackingService } from '../../../../shared/services/tracking.service';
import { CommonService } from '../../../../shared/services/common.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-invite-people-modal',
  templateUrl: './invite-people-modal.component.html',
  styleUrls: ['./invite-people-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvitePeopleModalComponent implements OnInit {
  @ViewChild('invitePeopleModal', { static: true }) modalTemplate: TemplateRef<any>;
  @Input() policies: any = [];
  @Input() groups: any = [];
  @Input() title;
  @Input() message = 'Add a specific person to your existing company';
  @Input() verifyPolicies = true;
  @Input() projectInvitation;
  @Input() invitationId;
  @Input() simpleInvitation;
  @Input() invitationType;
  public modalRef: BsModalRef;
  public userForm: FormGroup;
  public loading = false;
  public serverError = '';
  public projectPermissionTypes = [
    { id: 'owner', label: 'Owner', icon: 'fa-user' },
    { id: 'edit', label: 'Can edit', icon: 'fa-pencil' },
    { id: 'view', label: 'Can view', icon: 'fa-eye' },
    { id: 'comment', label: 'Can comment', icon: 'fa-comment' },
  ];

  constructor(
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private identityService: IdentityService,
    private toastr: ToastrService,
    private policyService: PolicyService,
    private trackingService: TrackingService,
    private userService: UserService,
    private projectsService: ProjectsService,
    private artistsService: ArtistService,
    private authenticationService: AuthenticationService,
    private commonService: CommonService,
  ) {}

  ngOnInit() {}

  public showModal() {
    this.createForm();
    this.modalRef = this.modalService.show(this.modalTemplate, {
      class: 'modal-md',
      ignoreBackdropClick: true,
    });
  }

  private createForm() {
    let form = {
      email: new FormControl(null, [Validators.required]),
    };
    if (!this.simpleInvitation) {
      form = {
        ...form,
        ...{
          firstName: new FormControl(null, Validators.required),
          lastName: new FormControl(null, Validators.required),
        },
      };
    } else if (this.projectInvitation) {
      form = {
        ...form,
        ...{ permissionType: new FormControl(this.projectPermissionTypes[0], Validators.required) },
      };
    }
    this.userForm = this.formBuilder.group(form);
  }

  inviteUser() {
    this.loading = true;
    this.serverError = '';
    this.policies.forEach((p) => {
      if (this.verifyPolicies) {
        this.policyService.query({ srn: p.srn }).subscribe({
          next: (policies) => {
            if (policies.data.length === 0) {
              this.createNewPolicy(p);
            } else {
              this.createIdentity();
            }
          },
          error: (err) => {
            console.error('Error getting policy >>>> ', err);
            this.loading = false;
            this.serverError = err.error.error ? err.error.error.data.message : err.error.message;
          },
        });
      } else {
        this.createIdentity();
      }
    });
  }

  dynamicInvitation() {
    this[this.invitationType]();
  }

  inviteToProject() {
    this.loading = true;
    this.serverError = '';
    const body = {
      permissionType: this.userForm.value.permissionType.id,
      emails: [this.userForm.value.email],
    };
    this.projectsService.inviteCollaborator(this.invitationId, body).subscribe({
      next: () => {
        console.log('Response >>> ', body);
        this.loading = false;
        this.hideModal();
        this.toastr.success('The user ' + body.emails[0] + ' has been invited', 'Success');
      },
      error: (err) => {
        this.trackingService.track('Error inviting collaborator to project', {
          errorStatus: err?.statusText + ' - ' + err?.status,
          errorMessage: err?.message,
          projectId: this.invitationId,
          email: body?.emails,
        });
        this.serverError = err?.error ? err?.error.message : err?.message;
        this.loading = false;
      },
    });
  }

  projectType(permission) {
    this.userForm.controls.permissionType.setValue(permission);
  }

  inviteArtist() {
    this.loading = true;
    this.serverError = '';
    const body = this.userForm.value;
    if (
      this.authenticationService.getCurrentUser() &&
      !this.commonService.isAllowed(['UpdateArtistAll', '*'])
    ) {
      body.groups = this.authenticationService
        .getCurrentUser()
        .groups.filter((gr) => !gr.includes('resources'));
    }
    this.artistsService
      .invite(this.invitationId, body)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: () => {
          this.hideModal();
          this.toastr.success('The user ' + body.email + ' has been invited', 'Success');
        },
        error: (err) => {
          this.trackingService.track('Error inviting artist', {
            errorStatus: err?.statusText + ' - ' + err?.status,
            errorMessage: err?.message,
            artistId: this.invitationId,
            email: body?.email,
          });
          this.serverError = err?.error ? err?.error.message || err?.message : err?.message;
        },
      });
  }

  createNewPolicy(policy) {
    console.log(policy);
    this.policyService.post(policy).subscribe({
      next: () => {
        this.createIdentity();
      },
      error: (err) => {
        console.error('Error creating policy >>>> ', err);
        this.loading = false;
        this.serverError = err.error.error
          ? err.error.error.data.message || err.error.error.userMessage
          : err.error.message;
        this.trackingService.track('Error creating policy', {
          policy: policy,
          errorStatus: err?.statusText + ' - ' + err?.status,
          errorMessage: err?.message,
        });
      },
    });
  }

  createIdentity() {
    this.loading = true;
    const user = {
      email: this.userForm.value.email,
      firstName: this.userForm.value.firstName,
      lastName: this.userForm.value.lastName,
      policies: this.policies.map((p) => p.srn),
      groups: this.groups,
    };
    this.identityService.query({ email: user.email }).subscribe({
      next: (data) => {
        if (data.paging.totalItems === 0) {
          this.userService
            .post(user)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe({
              next: () => {
                this.hideModal();
                this.toastr.success(
                  'The user ' + user.firstName + ' ' + user.lastName + ' has been invited',
                  'Success',
                );

                this.trackingService.track(
                  'Invite team member success',
                  {
                    invitedEmail: user.email,
                    action: 'Indentity creation',
                    policies: this.policies,
                  },
                  {
                    event_action: 'Team member invited',
                    event_type: 'Invite',
                    event_user_email: user?.email,
                  },
                );
              },
              error: (err) => {
                console.error('Error to create user >>>> ', err);
                this.serverError = err.error.error
                  ? err.error.error.data.message
                  : err.error.message;
                this.trackingService.track('Invite team member error', {
                  invitedEmail: user?.email,
                  serverError: err?.error.error
                    ? err?.error?.error?.data?.message
                    : err?.error?.message,
                  errorStatus: err?.statusText + ' - ' + err?.status,
                  action: 'Indentity creation',
                  policies: this.policies,
                });
              },
            });
        } else {
          this.identityService
            .addPolicyToUser(data.data[0]._id, {
              id: data.data[0]._id,
              policies: this.policies.map((p) => p.srn),
            })
            .pipe(finalize(() => (this.loading = false)))
            .subscribe({
              next: () => {
                this.hideModal();
                this.toastr.success(
                  'The user ' + user.firstName + ' ' + user.lastName + ' has been invited',
                  'Success',
                );
                this.trackingService.track(
                  'Invite team member success',
                  {
                    invitedEmail: user?.email,
                    action: 'Add policy to user',
                    policies: this.policies,
                  },
                  {
                    event_action: 'Team member invited',
                    event_type: 'Invite',
                    event_user_email: user?.email,
                  },
                );
              },
              error: (err) => {
                console.error('Error to create user >>>> ', err);
                this.serverError = err.error.error
                  ? err.error.error.data.message
                  : err.error.message;
                this.trackingService.track('Invite team member error', {
                  invitedEmail: user.email,
                  serverError: err?.error.error
                    ? err?.error?.error?.data?.message
                    : err?.error?.message,
                  errorStatus: err?.statusText + ' - ' + err?.status,
                  action: 'Add policy to user',
                  policies: this.policies,
                });
              },
            });
        }
      },
      error: (err) => {
        console.error('Error to create user >>>> ', err);
        this.loading = false;
        this.serverError = err.error.error ? err.error.error.data.message : err.error.message;
        this.trackingService.track('Invite team member query error', {
          invitedEmail: user?.email,
          serverError: err?.error?.error ? err?.error?.error?.data?.message : err?.error?.message,
          errorStatus: err?.statusText + ' - ' + err.status,
          policies: user?.policies,
        });
      },
    });
  }

  public hideModal() {
    this.loading = false;
    this.serverError = '';
    this.userForm.reset();
    this.modalRef.hide();
  }
}
