import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  Inject,
  Output,
  EventEmitter,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'sostereo-services';
import { Location, TitleCasePipe } from '@angular/common';
import { TrackingService } from '../../../../shared/services/tracking.service';
import { CommonService } from '../../../../shared/services/common.service';

@Component({
  selector: 'app-download-artist-modal',
  templateUrl: './download-artist-modal.component.html',
  styleUrls: ['./download-artist-modal.component.scss'],
})
export class DownloadArtistModalComponent implements OnInit {
  @ViewChild('downloadArtistModal', { static: true }) modalTemplate: TemplateRef<any>;
  @ViewChild('briefSubmissionModalComponent', { static: true }) briefSubmissionModalComponent;
  @Output() getBrief = new EventEmitter();
  public modalRef: BsModalRef;
  downloadArtistForm: FormGroup;
  disableBtn: boolean;
  requestSuccess: boolean;
  requestError: boolean;

  constructor(
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private trackingService: TrackingService,
    private location: Location,
    private titleCasePipe: TitleCasePipe,
    private commonService: CommonService,
    @Inject('environment') private environment,
  ) {
    this.disableBtn = false;
    this.createForm();
  }

  ngOnInit() {}

  private createForm() {
    this.downloadArtistForm = this.formBuilder.group({
      email: new FormControl(null, {
        validators: [Validators.pattern(this.commonService.emailRegex), Validators.required],
      }),
    });
  }

  sendEmail() {
    this.disableBtn = true;
    const artistName = this.location.path().split('/').pop().replace('-', ' ');
    console.log(artistName);
    const form = this.downloadArtistForm.value;
    const notification = {
      email: form.email,
      subject: 'Download artist submission',
      title: 'Download artist submission',
      body: `<div>
        <p>
        We'll send you an initial pull with relevant options shortly. <br>
        In the meantime feel free to call us at <b>+1 (305) 702-0180</b> or send us an email to hello@sostereo.com if you want to contact our creative team.
        </p>
        <p><b>Email: </b>${form.email}</p>
        <p><b>Artist Link: </b><a href=\"${window.location.hostname + '/' + this.location.path()}\">
        ${this.titleCasePipe.transform(artistName)}</p>
      </div>`,
      bodyTitle: 'Thank you for reaching out!',
    };
    this.notificationService.post(notification).subscribe(
      () => {
        this.trackingService.track(
          'Request Artist Playlist Success',
          {
            email: form?.email,
          },
          {
            event_action: 'Artist playlist request success',
            event_type: 'Playlist',
            event_user_email: form?.email,
          },
        );
        this.disableBtn = false;
        this.requestSuccess = true;
        setTimeout(() => {
          this.hideModal();
        }, 3000);
      },
      (err) => {
        this.requestError = true;
        this.disableBtn = false;
        this.trackingService.track('Submit artist playlist Error', {
          error: err,
        });
      },
    );
  }

  public showModal() {
    this.modalRef = this.modalService.show(this.modalTemplate, { class: 'modal-md' });
    this.trackingService.track(
      'Open artist playlist modal',
      {},
      {
        event_action: 'Artist playlist modal opened',
        event_type: 'Modal Opened',
      },
    );
  }

  public hideModal() {
    this.downloadArtistForm.reset();
    this.modalRef.hide();
    this.trackingService.track(
      'Close artist playlist modal',
      {},
      {
        event_action: 'Artist playlist modal closed',
        event_type: 'Modal Closed',
      },
    );
  }

  sendBrief() {
    this.hideModal();
    setTimeout(() => {
      this.briefSubmissionModalComponent.showModal();
      document.getElementsByTagName('modal-container')[0].setAttribute('class', 'modal fade in');
    }, 1000);
  }
}
