<ng-template #createPolicyModal>
  <div class="modal-body" *ngIf="!requestSuccess">
    <div class="row">
      <div class="col-md-12">
        <button type="button" class="close" (click)="cancel()"><span class="sr-only">Close</span><span
            class="ss-icon-close"></span></button>
        <div class="clearfix mb40 text-center">
          <h2 class="mb0">Add Company Permission</h2>
        </div>
      </div>
    </div>

    <form [formGroup]="policyForm" (ngSubmit)="onSubmit()">
      <p class="mt5 mb15">Select a permission type to see his description and following steps</p>
      <div class="form-group">
        <label>Permission Type *</label>
        <select formControlName="name" style="height: 35px;width: 100%"
                (change)="assignTemplateObject($event.target.value)">
          <option value="{{option.value}}"
                  *ngFor="let option of policiesDropdownData">
            {{option.name}}
          </option>
        </select>
        <app-inline-errors
            *ngIf="policyForm?.get('name').errors && policyForm?.get('name').touched "
            [errors]="['This field is required.']"></app-inline-errors>
      </div>
      <p>{{policyForm.get('templateObj')?.value?.description}}</p>
      <div class="form-group mt20" *ngIf="policyForm.get('templateObj')?.value?.slug === 'contract-owner'">
        <label>Select Owners *</label>
        <angular2-multiselect [data]="ownersDropdown"
                              [settings]="dropDownSettings"
                              formControlName="selectedOwners">
          <c-search>
            <ng-template>
              <input type="text" (keyup)="onOwnersSearch($event)" placeholder="Search owner"/>
            </ng-template>
          </c-search>
        </angular2-multiselect>
      </div>
      <div class="clearfix">
        <div class="form-group col-sm-12">
          <button [disabled]="loadingRequest || policyForm.invalid" type="submit"
                  class="btn primary-btn col-sm-4 mt20 pull-right">
            add
            <i class="spin medium" *ngIf="loadingRequest"></i>
          </button>
        </div>
      </div>
      <div *ngFor="let alert of alerts">
        <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout"
               (onClosed)="onAlertClosed(alert)">{{ alert.message }}</alert>
      </div>
    </form>
  </div>
  <div class="modal-body" *ngIf="requestSuccess" [@inOutAnimation]>
    <div class="row text-center">
      <div class="col-md-12">
        <h4 class="modal-title text-center">
          <span style="font-size: 70px;" class="glyphicon glyphicon-ok-circle font-20x"></span>
        </h4>
        <h2>Policy created successfully!</h2>
      </div>
    </div>
    <div class="row text-center">
      <div class="col-md-12">
        <button id="discoverBtn" type="submit"
                class="btn primary-btn mt20 mb20"
                (click)="cancel()">
          Ok
        </button>
      </div>
    </div>
  </div>
</ng-template>
