import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactUsService } from 'sostereo-services';
import { TrackingService } from '../../../../shared/services/tracking.service';
import { CommonService } from '../../../../shared/services/common.service';

@Component({
  selector: 'app-custom-services-modal',
  templateUrl: './custom-services-modal.component.html',
  styleUrls: ['./custom-services-modal.component.scss'],
})
export class CustomServicesModalComponent implements OnInit {
  @ViewChild('customServicesModal', { static: true }) modalTemplate: TemplateRef<any>;
  public modalRef: BsModalRef;
  customServicesForm: FormGroup;
  disableBtn: boolean;
  requestSuccess: boolean;
  requestError: boolean;

  constructor(
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private contactUsService: ContactUsService,
    private commonService: CommonService,
    private trackingService: TrackingService,
  ) {
    this.disableBtn = false;
    this.createForm();
  }

  ngOnInit() {}

  private createForm() {
    this.customServicesForm = this.formBuilder.group({
      name: new FormControl(null, { validators: Validators.required }),
      companyName: new FormControl(null, { validators: Validators.required }),
      phone: new FormControl(null, { validators: Validators.required }),
      email: new FormControl(null, {
        validators: [Validators.pattern(this.commonService.emailRegex), Validators.required],
      }),
      description: new FormControl(null),
    });
  }

  sendEmail() {
    this.disableBtn = true;
    this.contactUsService.customMusicRequest(this.customServicesForm.value).subscribe(
      () => {
        this.disableBtn = false;
        this.requestSuccess = true;
        setTimeout(() => {
          this.hideModal();
        }, 3000);
      },
      () => {
        this.requestError = true;
        this.disableBtn = false;
      },
    );
  }

  public showModal() {
    this.modalRef = this.modalService.show(this.modalTemplate, { class: 'modal-md' });
    this.trackingService.track(
      'Custom Music Service',
      {
        action: 'Open',
      },
      {
        event_action: 'Custom music service modal opened',
        event_type: 'Modal Opened',
      },
    );
  }

  public hideModal() {
    this.customServicesForm.reset();
    this.modalRef.hide();
    this.trackingService.track(
      'Custom Music Service',
      {
        action: 'Closed',
      },
      {
        event_action: 'Custom music service modal closed',
        event_type: 'Modal Closed',
      },
    );
  }
}
