import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { isArray, remove } from 'lodash-es';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from 'src/app/angular/shared/services/common.service';
import { configStatusModal } from './config-status-modal';

@Component({
  selector: 'app-song-status-modal',
  templateUrl: './song-status-modal.component.html',
  styleUrls: ['./song-status-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SongStatusModalComponent implements OnChanges {
  @ViewChild('songStatusModal', { static: true }) modalTemplate: TemplateRef<any>;
  private modalRef: BsModalRef;
  @Input() public tracks;
  @Input() public data;
  @Output() public restrictionsAgreed = new EventEmitter();
  @Output() public cancelStatusModal = new EventEmitter();
  public elementsStatus = [];

  constructor(private modalService: BsModalService, private commonService: CommonService) {}

  ngOnChanges(): void {
    this.elementsStatus = [];
    this.tracks?.forEach((track) => {
      this.elementsStatus.push(configStatusModal(track || {}));
    });
  }

  public showModal() {
    console.log(this.tracks);
    this.tracks?.forEach((track) => {
      if (track.restrictions && !isArray(track.restrictions)) {
        track.restrictions = this.commonService.mapRestrictionsToArray(track?.restrictions);
        if (!this.commonService.isAllowed(['CreateRestriction', '*'])) {
          remove(track.restrictions, (r: any) => r.hasOwnProperty('visible') && !r.visible);
        }
      }
    });

    this.modalRef = this.modalService.show(this.modalTemplate, {
      class: 'modal-lg',
      ignoreBackdropClick: true,
    });
    this.elementsStatus = [];
    this.tracks?.forEach((track) => {
      this.elementsStatus.push(configStatusModal(track || {}));
    });
  }

  public agreeRestrictions() {
    this.modalRef.onHidden.subscribe(() => {
      this.restrictionsAgreed.emit(this.data || {});
    });
    this.modalRef.hide();
  }

  public hideModal() {
    this.cancelStatusModal.emit();
    this.modalRef.hide();
  }
}
